import { Box, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, { FC } from "react";


const CustomSnackbar: FC<{results: any[], onClose: () => void}> = (props) => {

    return (
        <Snackbar open={props.results.length > 0} onClose={props.onClose}
            autoHideDuration={5000}>
            <Box>
                {props.results.map((item: any) => <Box mb={2}>
                    <Alert severity={item.result ? 'success' : 'error'}>
                        {item.msg}
                    </Alert>
                </Box>)}
            </Box>
        </Snackbar>
    );

}

export default CustomSnackbar;