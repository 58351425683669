import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { TonersService } from '../../services/TonerService';
import { DatatableProps } from './DatatableProps';
import EditableTableCell from './EditableTableCell/EditableTableCell';
import { format } from "date-fns"

/**
 * Tabela de solicitação de toners
 * @param DatatableProps
 * @returns
 */
const Datatable: React.FC<DatatableProps> = ({
    data,
    onRowClick,
    onChangeAnalista,
    analistas
}) => {

    const headers: Array<string> = [
        'ID', 'Recebido em', 'SAP', 'Empresa', 'Solicitante', 'Modelo', 'Série', 'Status', 'Analista'
    ]

    return (
        <TableContainer>
            <Table aria-label="orders table">
                <TableHead>
                    <TableRow key='firstrow'>
                        {headers.map((heading) =>
                            <TableCell key={heading}>{heading}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map(row =>
                        <TableRow key={row.id} onClick={(e: any) => onRowClick(e, row.id)}>
                            <TableCell component="th" scope="row">{row.id}</TableCell>
                            <TableCell>{row.created_at}</TableCell>
                            <TableCell>{row.cliente.cod_sap}</TableCell>
                            <TableCell>{row.cliente?.nome}</TableCell>
                            <TableCell>{row.solicitante?.nome || 'Não informado'}</TableCell>
                            <TableCell>{row.equipamento.modelo.nome}</TableCell>
                            <TableCell>{row.equipamento.serie}</TableCell>
                            <TableCell>{TonersService.getOrderStatus(row?.status)}</TableCell>
                            <EditableTableCell
                                analista={row?.responsavel}
                                onSelectAnalista={
                                    (responsevel) => onChangeAnalista(row.id, responsevel)
                                }
                                analistas={analistas}
                            >
                                {row?.responsavel ? row.responsavel.nome || row.responsavel.username : 'Não atribuido'}
                            </EditableTableCell>
                        </TableRow>)
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default Datatable;