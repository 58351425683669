import {
  Container,
  Box,
  Button,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React, { FC, useCallback, useEffect, useState } from 'react';
import SDHeader from '../../components/SDHeader';
import { DataGrid, GridColDef } from '@material-ui/data-grid';
import { fetchContratos } from './services';
import { useHistory } from 'react-router-dom';
import CustomSelect from '../../components/CustomSelect';
import {
  fetchFiliais,
  fetchStatusContratual,
  fetchTipoContrato,
} from '../../services/BaseService';

const OpcaoSemFiltro = { key: '', value: '--- sem filtro ---' };

const ContratoList: FC = (props) => {
  const [contratos, setContratos] = useState<any[] | null>(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [tiposContrato, setTiposContrato] = useState<any[] | null>(null);
  const [filiais, setFiliais] = useState<any[] | null>(null);
  const [statusContratualList, setStatusContratualList] = useState<
    any[] | null
  >(null);
  const [filter, setFilter] = useState<any>({});
  const history = useHistory();

  const columns: GridColDef[] = [
    {
      field: 'numero',
      headerName: 'Número',
      width: 110,
    },
    {
      field: 'tipo_atendimento',
      headerName: 'Atendimento',
      width: 200,
    },
    {
      field: 'tipo_contrato',
      headerName: 'Tipo do Contrato',
      width: 200,
    },
    {
      field: 'parcelas',
      headerName: 'Parcelas',
      width: 120,
    },
    {
      field: 'status_contratual',
      headerName: 'Status Contratual',
      width: 200,
    },
    {
      field: 'data_inicio',
      headerName: 'Início Contratual',
      width: 200,
    },
    {
      field: 'data_fim',
      headerName: 'Fim Contratual',
      width: 200,
    },
  ];

  const searchContrato = useCallback(() => {
    const params = {
      clientes__filial: filter?.filial?.id,
      tipo_contrato: filter?.tipo_contrato?.key,
      status_contratual: filter?.status_contratual?.key,
      search: filter?.search,
    };
    fetchContratos(
      params,
      page,
      (data: any) => {
        setContratos(data.results);
        setTotal(data.count);
      },
      (err: any) => {
        console.error(err);
      }
    );
  }, [filter, page]);

  useEffect(() => {
    searchContrato();
  }, [page, searchContrato]);

  /** Carrega a lista de status contratual */
  useEffect(() => {
    if (statusContratualList === null) {
      fetchStatusContratual(
        (data: any) => {
          data.push(OpcaoSemFiltro);
          setStatusContratualList(data);
        },
        (error: any) => {
          setStatusContratualList([]);
          console.error(error);
        }
      );
    }
  }, [statusContratualList]);

  const handleStatusContratual = (value: string) => {
    if (statusContratualList !== null) {
      const index = statusContratualList.findIndex(
        (item) => item.value === value
      );
      if (index > -1) {
        setFilter({
          ...filter,
          status_contratual: statusContratualList[index],
        });
      }
    }
  };

  const getStatusContratual = useCallback(
    (key: string) => {
      let tipo = undefined;
      if (statusContratualList) {
        const index = statusContratualList.findIndex(
          (item) => item.key === key
        );
        if (index > -1) {
          tipo = statusContratualList[index];
        }
      }
      return tipo;
    },
    [statusContratualList]
  );

  /** Carrega os tipos de contratos */
  useEffect(() => {
    if (tiposContrato === null) {
      fetchTipoContrato(
        (data: any) => {
          data.push(OpcaoSemFiltro);
          setTiposContrato(data);
        },
        (error: any) => {
          setTiposContrato([]);
          console.error(error);
        }
      );
    }
  }, [tiposContrato]);

  const getTipoContrato = useCallback(
    (key: string) => {
      let tipo = undefined;
      if (tiposContrato) {
        const index = tiposContrato.findIndex((item) => item.key === key);
        if (index > -1) {
          tipo = tiposContrato[index];
        }
      }
      return tipo;
    },
    [tiposContrato]
  );

  const handleTipoContrato = (value: string) => {
    if (tiposContrato !== null) {
      const index = tiposContrato.findIndex((item) => item.value === value);
      if (index > -1) {
        setFilter({ ...filter, tipo_contrato: tiposContrato[index] });
      }
    }
  };

  /** Lista filiais da Konica */
  useEffect(() => {
    if (filiais === null) {
      fetchFiliais(
        (filiais: any) => {
          filiais.push({ id: '', municipio: '--- sem filtro ---' });
          setFiliais([...filiais]);
        },
        (err: any) => {
          console.error(err);
        }
      );
    }
  }, [filiais]);

  const handleChangeFilial = (value: string) => {
    if (filiais) {
      const index = filiais.findIndex((item) => item.municipio === value);
      if (index > -1) {
        setFilter({ ...filter, filial: filiais[index] });
      }
    }
  };

  return (
    <Container>
      <Box display="flex">
        <SDHeader subtitle="Relatórios" title="Contratos Cadastrados" />
        <Box flexGrow={1} display="flex" justifyContent="flex-end" mt={3}>
          <Box width="60%">
            <TextField
              variant="outlined"
              placeholder="Buscar por Nome, Número, CNPJ ou SAP"
              margin="dense"
              fullWidth
              onChange={(e) => setFilter({ ...filter, search: e.target.value })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon color="disabled" />
                  </InputAdornment>
                ),
              }}
            />
            <Box display="flex" mt={1}>
              <CustomSelect
                label="Status Contratual"
                value={
                  typeof filter?.status_contratual === 'string'
                    ? getStatusContratual(filter?.status_contratual)?.value
                    : filter?.status_contratual?.value
                }
                options={statusContratualList?.map((item) => item.value) || []}
                onChange={handleStatusContratual}
              />
              &nbsp;
              <CustomSelect
                label="Filial"
                value={filter?.filial?.municipio}
                options={filiais?.map((item) => item.municipio) || []}
                onChange={handleChangeFilial}
              />
              &nbsp;
              <CustomSelect
                label="Tipo de Contrato"
                value={
                  typeof filter?.tipo_contrato === 'string'
                    ? getTipoContrato(filter?.tipo_contrato)?.value
                    : filter?.tipo_contrato?.value
                }
                options={tiposContrato?.map((item) => item.value) || []}
                onChange={handleTipoContrato}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box mb={3}>
        <Button
          onClick={() => history.push('/contrato/novo')}
          variant="contained"
          color="primary"
          style={{ textTransform: 'none' }}
        >
          Novo Contrato
        </Button>
      </Box>
      <Box height="80vh" marginTop={3}>
        <DataGrid
          rows={contratos || []}
          columns={columns}
          pageSize={15}
          onPageChange={(param) => setPage(param.page + 1)}
          rowCount={total}
          paginationMode="server"
          disableColumnMenu
          onRowClick={(row) => history.push(`/contrato/${row.id}`)}
        />
      </Box>
    </Container>
  );
};

export default ContratoList;
