import React, { useState } from 'react'
import PropTypes from 'prop-types';

import { Box, Chip, IconButton } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import { FilterChipProps } from './FilterChipProps'
import FilterInputText from './FilterInputText';
import FilterSelect from './FilterSelect';
import FilterDatepicker from './FilterDatepicker';
import { format } from 'date-fns/esm';
/**
 * Componente que representa um filtro usado para filtrar informações de uma lista
 * @param props
 */
const FilterChip: React.FC<FilterChipProps> = (props) => {
    const [visibility, setVisibility] = useState(props.visibility);
    const [isEditing, setIsEditing] = useState(false);
    const [value, setValue] = useState(props.value);


    /**
     * Trata o evento de ativação/desativação do filtro
     * @param e
     */
    const handleVisibility = (e: any) => {
        e.stopPropagation();
        changeVisibility(!visibility);
    }

    function changeVisibility(value: boolean) {
        if (props.onChangeVisibility) {
            props.onChangeVisibility(props.label, value);
            setVisibility(value);
            if (props.type === 'boolean') {
                handleChange(props.label, value);
            }
        }
    }

    /**
     * Verifica se props.handleDelete foi especificado e define a
     * função handleDelete do objeto, senão handleDelete recebe
     * undefined e desabilita o botão de deletar do Componente Chip
     */
    const handleDelete = props.onDelete ? () => {
        if (props.onDelete) {
            props.onDelete(props.label);
        }
    } : undefined;

    /**
     * Trata o evento de edição do filtro
     * @param e
     */
    const handleEdit = (e: any) => {
        if (props.type === 'boolean') {
            changeVisibility(!visibility);
        } else {
            setIsEditing(true);
        }
    }

    /**
     * Trata o evento de mudança do valor do filtro
     * @param label
     * @param newValue
     */
    const handleChange = (label: string, newValue: any) => {
        setValue(newValue);
        setIsEditing(false);
        if (props.onChangeValue) {
            props.onChangeValue(props.label, newValue);
        }
    }

    /**
     * Renderiza o componente FilterChip
     */
    const renderFilterChip = () => {
        if (!isEditing) {
            return renderChip();
        } else if (props.type === 'text' || props.type === 'number') {
            return <FilterInputText value={value} label={props.label}
                onChangeValue={handleChange} type={props.type} />
        } else if (props.type === 'select') {
            if (props.options) {
                return <FilterSelect
                    value={value}
                    label={props.label}
                    onChangeValue={handleChange} options={props.options}>
                        {props.children}
                    </FilterSelect>
            } else {
                throw new Error("A propriedade options dever ser preenchida quando type='select'");

            }
        } else if(props.type === 'date') {
            return <FilterDatepicker
                value={props.value}
                label={props.label}
                onChangeValue={handleChange} />
        }
    }

    /**
     * Trata o valor label do componente
     */
    function filterLabel(): string {
        let label = props.label;
        if (props.type !== 'boolean') {
            if (typeof value === 'string') {
                label = value;
            } else if (value instanceof Date) {
                label = format(value, 'dd/MM/yyyy');
            } else {
                label = value.label;
            }
            label = `${props.label}: ${label}`;
        }

        return label;
    }

     /**
     * Renderiza o componente chip
     */
    const renderChip = () => {
        return (
            <Chip
                color={visibility ? "primary": "default"}
                onDelete={handleDelete}
                onClick={handleEdit}
                label={filterLabel()}
                icon={ <IconButton size="small" style={{marginBottom: 0}}
                            onClick={handleVisibility}>
                        {visibility ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                    }
            />
        )
    }

    /**
     * Validação das propriedades do filtro
     */
    FilterChip.propTypes = {
        type: PropTypes.oneOf(['number', 'text', 'select', 'boolean', 'date']).isRequired,
    }

    return (
    <Box>
        {renderFilterChip()}
    </Box>
    )
}

export default FilterChip