import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { useEffect } from 'react';

const CustomRadioGroup: FC<{
    label?: string,
    value?: string,
    options: string[],
    disabled?: boolean,
    onChange: (value: string) => void
}> = ({ label, options, onChange, disabled, ...props }) => {

    const [value, setValue] = useState(props.value || '');

    useEffect(() => {
        setValue(props.value || '')
    }, [props.value])

    const handleChange = (e: any) => {
        setValue(e.target.value);
        onChange(e.target.value);
    }

    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup row aria-label={label} value={value} onChange={handleChange}>
                {options.map(option =>
                    <FormControlLabel value={option} control={<Radio color="primary" disabled={disabled}/>} 
                    key={option} label={option} />
                )}
            </RadioGroup>
        </FormControl>
    )
}

export default CustomRadioGroup;