import { Container } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Route, withRouter, Switch } from 'react-router-dom';
import Navbar, {
  NavbarMenuItem,
  NavbarAction,
  NavbarProfile,
} from '../../components/Navbar';
import { authService } from '../../services/Auth';
import Dashboard from '../Dashboard';
import Login from '../Login';
import SolicitacaoList from '../Toner/SolicitacaoList';
import SolicitacaoDetail from '../Toner/SolicitacaoDetail';
import useStyles from './style';
import Client from '../Client';
import Contract from '../Contract';
import ClientList from '../Client/ClientList';
import User from '../User/User';
import Veiculo from '../Veiculo/Veiculo';
import ContratoList from '../Contract/ContratoList';
import PrivateRoute from '../../components/PrivateRoute';
import Contadores from '../Contadores/Contadores';

import { LoadingContextProvider } from '../../contexts/loading';
import { NotificationContextProvider } from '../../contexts/notifications';

/**
 * Main domain component used to define the route of the app component
 */
const Main = withRouter(({ location }) => {
  const classes = useStyles();
  const history = useHistory();

  const Config = () => (
    <div>
      <h1>Configurações</h1>
    </div>
  );

  /**
   * Itens do menu da barra de navegação
   */
  const menuItens: Array<NavbarMenuItem> = [
    { label: 'Home', url: '/' },
    { label: 'Contratos', url: '/contratos' },
    { label: 'Clientes', url: '/clientes' },
    {
      label: 'Chamados',
      url: '/chamados/material',
      submenu: [
        { label: 'Solicitações de Material', url: '/chamados/material' },
        { label: 'Chamado Técnico', url: '/chamados/material' },
      ],
    },
    { label: 'Contadores', url: '/contadores' },
  ];

  /** Perfil do usuário */
  const userProfile: NavbarProfile = {
    userId: Number(localStorage.getItem('user_id')),
    username: '',
    avatar: '',
    userActions: [
      { label: 'Meu perfil', action: () => {} },
      {
        label: 'Sair',
        action: () => {
          authService.logout().then(function () {
            localStorage.removeItem('access_token');
            history.push('/login');
          });
        },
      },
    ],
  };

  /** Atalhos da barra de navegação */
  const shortcuts: Array<NavbarAction> = [
    {
      label: 'Cliente',
      action: () => {
        history.push('/cliente/novo');
      },
    },
    {
      label: 'Contrato',
      action: () => {
        history.push('/contrato/novo');
      },
    },
    {
      label: 'Usuário',
      action: () => {
        history.push('/usuario/novo');
      },
    },
    {
      label: 'Veículo',
      action: () => {
        history.push('/veiculo/novo');
      },
    },
    {
      label: 'Contadores',
      action: () => {
        history.push('/contadores');
      },
    },
  ];

  return (
    <div>
      <NotificationContextProvider>
        <LoadingContextProvider>
          {location.pathname !== '/login' && (
            <Navbar
              logoPath="/logo_km48.png"
              title="SMHUB"
              menuItems={menuItens}
              shortcuts={shortcuts}
              userProfile={userProfile}
              onClickSettings={() => {
                history.push('/configuracoes');
              }}
            />
          )}
          <Container className={classes.container}>
            <Switch>
              <Route path="/login" component={Login} />
              <PrivateRoute exact path="/" component={Dashboard} />
              <PrivateRoute
                exact
                path="/chamados/material"
                component={SolicitacaoList}
              />
              <PrivateRoute
                exact
                path="/chamados/material/:id"
                component={SolicitacaoDetail}
              />
              <PrivateRoute exact path="/clientes" component={ClientList} />
              <PrivateRoute exact path="/cliente/novo" component={Client} />
              <PrivateRoute exact path="/cliente/:id" component={Client} />
              <PrivateRoute exact path="/contratos" component={ContratoList} />
              <PrivateRoute exact path="/contrato/novo" component={Contract} />
              <PrivateRoute exact path="/contrato/:id" component={Contract} />
              <PrivateRoute exact path="/usuario/novo" component={User} />
              <PrivateRoute exact path="/veiculo/novo" component={Veiculo} />
              <PrivateRoute exact path="/contadores" component={Contadores} />
              <PrivateRoute exact path="/configuracoes" component={Config} />
            </Switch>
          </Container>
        </LoadingContextProvider>
      </NotificationContextProvider>
    </div>
  );
});

export default Main;
