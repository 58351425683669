import { Box, Button, Container, Divider, Grid, IconButton, Typography } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CustomRadioGroup from '../../components/CustomRadioGroup';
import CustomSelect from '../../components/CustomSelect';
import FormAddress from '../../components/FormAddress';
import FormCompany from '../../components/FormCompany';
import SDHeader from '../../components/SDHeader';
import SectionTitle from '../../components/SectionTitle';
import { fetchSegmentos, fetchFiliais, fetchRegioesAtendimento } from '../../services/BaseService';
import ContatoCard from './components/ContatoCard';
import { Cliente, Contato } from './models';
import { createClient, getClient, updateClient } from './services';

const newContato = {
    nome: '',
    tipo: 'Comercial',
    email: '',
    telefone: ''
}

const Client: FC = (props) => {

    const [data, setData] = useState<Cliente | any | null>(null);
    const [requestResult, setRequestResult] = useState<any | null>(null);
    const [segmentos, setSegmentos] = useState<any[] | null>(null);
    const [filiais, setFiliais] = useState<any[] | null>(null);
    const [regioes, setRegioes] = useState<any[] | null>(null);
    const [contatos, setContatos] = useState<Contato[]>([newContato]);
    const [validation, setValidation] = useState<any>();
    const { id } = useParams<{ id?: string }>();

    useEffect(() => {
        if (id) {
            getClient(Number(id),
                (data: any) => {
                    setData(data);
                    setContatos(data.contatos)
                },
                (error: any) => { console.error(error); })
        }
    }, [id]);

    const handleRemoveContato = (index: number) => {
        contatos.splice(index, 1);
        setContatos([...contatos]);
    }

    const handleCompanyDataChange = (company: any) => {
        setData({ ...data, ...company })
    }

    const handleAddressDataChange = (address: any) => {
        setData({ ...data, ...address })
    }

    /** Lista segmentos de canal de atendimento */
    useEffect(() => {
        fetchSegmentos(data?.canal_direto === false ? 'indireto' : 'direto',
            (segmentos: any) => {
                setSegmentos([...segmentos]);
            },
            (err: any) => {
                console.error(err);
            });
    }, [data?.canal_direto]);

    const handleChangeSegmento = (value: string) => {
        if (segmentos) {
            const index = segmentos.findIndex(item => item.value === value);
            if (index > -1) {
                setData({ ...data, tipo_segmento: segmentos[index] })
            }
        }
    }

    /** Lista filiais da Konica */
    useEffect(() => {
        if (filiais === null) {
            fetchFiliais(
                (filiais: any) => {
                    setFiliais([...filiais]);
                },
                (err: any) => {
                    console.error(err);
                });
        }
    }, [filiais]);

    const handleChangeFilial = (value: string) => {
        if (filiais) {
            const index = filiais.findIndex(item => item.municipio === value);
            if (index > -1) {
                setData({ ...data, filial: filiais[index], regiao: undefined })
            }
        }
    }

    /** Lista regiões de atendimento da filial */
    useEffect(() => {
        if (data?.filial) {
            fetchRegioesAtendimento(data?.filial?.id,
                (regioes: any) => {
                    setRegioes([...regioes]);
                },
                (err: any) => {
                    console.error(err);
                });
        }
    }, [data?.filial]);

    const handleChangeRegiao = (value: string) => {
        if (regioes) {
            const index = regioes.findIndex(item => item.nome === value);
            if (index > -1) {
                setData({ ...data, regiao: regioes[index] })
            }
        }
    }

    const addContato = (e: any) => {
        const list = [...contatos, newContato];
        setContatos(list);
        setData({ ...data, contatos: list })
    }

    function saveClient(e: any) {
        const contatoList = contatos.map(item => {
            return {
                ...item,
                tipo: item.tipo.toUpperCase()
            }
        })
        //parametros diferentes do modelo aceito no backend
        const params = {
            ...data,
            tipo_cliente: data?.tipo_cliente === 'Revenda' ? 'REVE' : 'CLIF',
            tipo_razao_social: data?.tipo_razao_social==='Matriz' ? 'MATR': 'FILI',
            estado: data?.estado.uf,
            municipio: data?.municipio.nome,
            filial: data?.filial?.id,
            regiao: data?.regiao?.id,
            contatos: contatoList,
            responsavel_comercial: data?.responsavel_comercial?.id,
            grupo_comercial: data?.grupo_comercial?.nome,
            tipo_segmento: data?.tipo_segmento?.key || data?.tipo_segmento,
        }

        const onSuccess = (response: any) => {
            setRequestResult({ status: 1, msg: 'Cadastro realizado com Sucesso!' });
        };

        const onFail = (error: any) => {
            const reason = { ...error?.response?.data };
            if (reason) {
                const errors: any = {};
                Object.entries(reason).forEach(([k, value]) => {
                    if (Array.isArray(value)) {
                        errors[k] = { error: true, helperText: value.join(', ') };
                    }
                })
                setValidation(errors)
            }
            setRequestResult({ status: 0, msg: 'Não foi possível realizar o Cadastro!' });
        };

        if (id) {
            updateClient(id, params, onSuccess, onFail);
        } else {
            createClient(params, onSuccess, onFail);

        }
    }

    /**
     * Retorna o valor do tipo do segmento
     * @returns tipo do segmento ou undefined
     */
    const tipoSegmento = (): string => {
        let tipo = data?.tipo_segmento;
        if (segmentos && (typeof (tipo) === 'string')) {
            tipo = segmentos.find(item => item.key === tipo);
        }
        return tipo?.value;
    }

    /**
     * Retorna o valor da regiao de atendimento
     * @returns regiao de atendimento 
     */
     const regiaoAtendimento = (): string => {
        let regiao = data?.regiao;
        if (regioes && (typeof (regiao) === 'number')) {
            regiao = regioes.find(item => item.id === regiao);
        }
        return regiao?.nome || '';
    }

    /**
     * Retorna o valor do municipio da filial
     * @returns nome do municipio da filial ou undefined
     */
    const filial = (): string => {
        let filial = data?.filial;
        if (filiais && (typeof (filial) === 'number')) {
            filial = filiais.find(item => item.id === filial);
            setData({ ...data, filial: filial });
        }
        return filial?.municipio;
    }

    //não há dados cadastrados para as regiões de atendimento para as filiais. O fetch retorna uma array vazia.
    return (
        <Container style={{ marginBottom: '96px' }}>
            <SDHeader title="Cadastro de Cliente" subtitle="Cadastros" />
            <Divider />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SectionTitle title="Dados Empresariais" />
                </Grid>
                <Grid xs={6} item container>
                    <FormCompany company={data}
                        onChange={handleCompanyDataChange}
                        validation={validation} />
                </Grid>
                <Grid xs={6} item container>
                    <FormAddress address={data}
                        onChange={handleAddressDataChange} />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SectionTitle title="Canal de Atendimento" />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <CustomRadioGroup label="Canal de Atendimento"
                        value={data?.canal_direto === false ? 'Indireto' : 'Direto'}
                        options={['Direto', 'Indireto']}
                        onChange={(value) => setData(
                            {
                                ...data,
                                canal_direto: value === 'Direto',
                                tipo_segmento: null
                            }
                        )} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <CustomSelect label="Tipo de Segmento" value={tipoSegmento()}
                        options={segmentos?.map(item => item.value) || []}
                        onChange={handleChangeSegmento} required
                        {...validation?.tipo_segmento} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <CustomSelect label="Filial" value={filial()}
                        options={filiais?.map(item => item.municipio) || []}
                        onChange={handleChangeFilial} required
                        {...validation?.filial} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <CustomSelect label="Região de atendimento" 
                        value={regiaoAtendimento()}
                        options={regioes?.map(item => item.nome) || []}
                        onChange={handleChangeRegiao} />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SectionTitle title="Dados de Contato" />
                </Grid>
                {contatos.map((contato: any, index: number) =>
                    <Grid item xs={12} sm={6} md={4} key={contato.nome + index}>
                        <ContatoCard contato={contato}
                            removeItem={() => handleRemoveContato(index)}
                            onChange={(data) => contatos[index] = data} />
                    </Grid>
                )}
                {contatos.length === 0 && (<Typography color="error">
                    É necessário cadastrar pelo menos um contato do cliente
                </Typography>)}
                <Grid item xs={2}>
                    <Box display="flex" alignItems="center" height="100%">
                        <IconButton onClick={addContato}>
                            <AddCircleOutline />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="right" color="red"
                py={4} alignItems="center">
                {requestResult?.status === 0 && (<Typography color="inherit">
                    As seções com itens em vermelho contem campos que
                    precisam ser preenchidos.&nbsp;&nbsp;&nbsp;
                </Typography>)}
                {requestResult?.status === 1 && (<Typography color="primary">
                    {requestResult?.msg}&nbsp;&nbsp;&nbsp;
                </Typography>)}
                <Button variant="contained" style={{ textTransform: 'none' }}
                    onClick={saveClient}>
                    Cadastrar
                </Button>
            </Box>
        </Container>
    );
}

export default Client;