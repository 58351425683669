import React, { useState } from 'react'
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { FilterDatepickerProps } from './FilterDatepickerProps';
import brLocale from "date-fns/locale/pt-BR";
import { parse } from 'date-fns';


const FilterDatepicker: React.FC<FilterDatepickerProps> = (props) => {
    let initDate = new Date();
    if (props.value instanceof Date) {
        initDate = props.value;
    } else if (typeof props.value === 'string') {
        initDate = parse(props.value,'dd/MM/yyyy', new Date())
    }
    const [value, setValue] = useState<Date | null>(initDate);

    const handleDateChange = (value: Date | null) => {
        setValue(value);
        endEdit(value);
    }

    function endEdit(value: Date | null) {
        if (value !== undefined) {
            props.onChangeValue(props.label, value);
        }
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
            <DatePicker
                open={true}
                format="dd/MM/yyyy"
                autoOk
                cancelLabel=""
                okLabel=""
                invalidDateMessage="Data inválida"
                value={value}
                onChange={handleDateChange}
            />
        </MuiPickersUtilsProvider>
    )
}

export default FilterDatepicker;