import {
  Dialog,
  DialogTitle,
  Button,
  Container,
  Box,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Select,
  TextField 
} from "@material-ui/core";
import { Dispatch, useContext, useEffect, useState } from "react";
import { LoadingContext } from "../../../../contexts/loading";
import {
  fetchCentroFornecimento,
  fetchCFOP,
  fetchMotivoOrdem,
  fetchSetorAtividade,
  fetchTipoOrdem,
  fetchTransportadoras,
  updatePreOrdem,
  TonersService,
} from "../../services/TonerService";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

type DlgProps = {
  open: boolean;
  ordem: any;
  onClose: () => void;
};

type ItemProps = {
  head: string;
  texto: string;
  callback?: Dispatch<any>;
};

const Item = ({ head, texto, callback }: ItemProps) => {
  return (
    <Grid container style={{ padding: "6px 0" }}>
      <Grid item xs={6}>
        <span
          style={{
            textAlign: "right",
            display: "block",
            paddingRight: "16px",
            fontWeight: 700,
            color: "#757575",
          }}
        >
          {head}
        </span>
      </Grid>
      <Grid item xs={4}>
        <span style={{ paddingLeft: "16px", color: "#757575" }}>{texto}</span>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root:{
      input: {
        fontSize: "8px"
      }
    }
  }),
);

const OrdemDetalhesDlg = ({ open, ordem, onClose }: DlgProps) => {
  const classes = useStyles()

  const [novaOrdem, setNovaOrdem] = useState<any>(ordem);
  const [tiposOrdem, setTiposOrdem] = useState<any[] | null>(null);

  const handleUpdate = () => {
    console.log('ordem para salvar', novaOrdem)
      console.log('Ordem', ordem)
    updatePreOrdem(
      ordem.id,
      novaOrdem,
      () => {
      },
      (e: any) => {
        console.log(e);
      }
    );
  };

  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    console.log('Atualiza dados da novaOrdem', ordem)
    setNovaOrdem(ordem)
    // if (novaOrdem === null){
    //   console.log('Set value to new order')
    // }
  }, []);

  useEffect(() => {
    if (tiposOrdem === null) {
      console.log('Carrega tipo ordens')
      setLoading(true);
      fetchTipoOrdem(
        (values: any) => {
          setLoading(false);
          setTiposOrdem(values);
        },
        (reason: any) => {
          setLoading(false);
          setTiposOrdem([]);
          console.error(reason.response.data);
        }
      );
    }
  }, [setLoading, tiposOrdem]);

  const [setorAtividades, setSetorAtividades] = useState<any[] | null>(null);

  useEffect(() => {
    if (setorAtividades === null) {
      setLoading(true);
      fetchSetorAtividade(
        (values: any) => {
          setLoading(false);
          setSetorAtividades(values);
        },
        (reason: any) => {
          setLoading(false);
          setSetorAtividades([]);
          console.error(reason.response.data);
        }
      );
    }
  }, [setLoading, setorAtividades]);

  const [motivosOrdem, setMotivosOrdem] = useState<any[] | null>(null);

  useEffect(() => {
    if (motivosOrdem === null) {
      setLoading(true);
      fetchMotivoOrdem(
        (values: any) => {
          setLoading(false);
          setMotivosOrdem(values);
        },
        (reason: any) => {
          setLoading(false);
          setMotivosOrdem([]);
          console.error(reason.response.data);
        }
      );
    }
  }, [motivosOrdem, setLoading]);

  const [centrosFornecimento, setCentrosFornecimento] = useState<any[] | null>(
    null
  );

  useEffect(() => {
    if (centrosFornecimento === null) {
      setLoading(true);
      fetchCentroFornecimento(
        (values: any) => {
          setLoading(false);
          setCentrosFornecimento(values);
        },
        (reason: any) => {
          setLoading(false);
          setCentrosFornecimento([]);
          console.error(reason.response.data);
        }
      );
    }
  }, [centrosFornecimento, setLoading]);

  const [CFOPs, setCFOPs] = useState<any[] | null>(null);

  useEffect(() => {
    if (CFOPs === null) {
      setLoading(true);
      fetchCFOP(
        (values: any) => {
          setLoading(false);
          setCFOPs(values);
        },
        (reason: any) => {
          setLoading(false);
          setCFOPs([]);
          console.error(reason.response.data);
        }
      );
    }
  }, [CFOPs, setLoading]);

  const [transportadoras, setTransportadoras] = useState<any[] | null>(null);

  useEffect(() => {
    if (transportadoras === null) {
      setLoading(true);
      fetchTransportadoras(
        (values: any) => {
          setLoading(false);
          console.log('>>>>>>>>>>> Transportadoras')
          console.log(values)
          setTransportadoras(values);
        },
        (reason: any) => {
          setLoading(false);
          setTransportadoras([]);
          console.error(reason.response.data);
        }
      );
    }
  }, [transportadoras, setLoading]);

  const headers = [
    "Item #",
    "Descrição",
    "Qtd",
    "Centro de Fornecimento",
    "Depósito",
  ];

  const printOrderLabels = () => {
    TonersService.printOrderLabels(
      ordem.id,
      (data: any) => {
        console.log("Imprime uma ordem específica");
      },
      (error: any) => {
        console.log("Erro na impressão de ordem");
      }
    );
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth onBackdropClick={onClose}>
      <DialogTitle style={{ borderBottom: "1px solid #d7d7d7" }}>
        <p style={{ fontSize: "24px", color: "#757575", margin: 0 }}>
          Ordem #{ordem?.id}
        </p>
      </DialogTitle>
      <Container>
        <Box py={4}>
          <p
            style={{
              fontSize: "16px",
              color: "#757575",
              fontWeight: 700,
              margin: 0,
              borderBottom: "1px solid #D3D3D3",
              paddingBottom: "8px",
            }}
          >
            Cabeçalho
          </p>
          <Grid
            container
            style={{ paddingTop: "16px" }}
            justifyContent="center"
          >
            <Grid item xs={6}>
              <Grid
                container
                style={{
                  padding: "6px 0",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Grid item xs={6}>
                  <span
                    style={{
                      textAlign: "right",
                      display: "block",
                      paddingRight: "16px",
                      fontWeight: 700,
                      color: "#757575",
                    }}
                  >
                    Tipo Ordem
                  </span>
                </Grid>
                <Grid item xs={4}>
                  <Select
                    label="Tipo Ordem"
                    value={novaOrdem?.tipo_ordem || ordem?.tipo_ordem}
                    onChange={(e) =>
                      setNovaOrdem({ ...novaOrdem, tipo_ordem: e.target.value })
                    }
                    disableUnderline
                    style={{
                      paddingLeft: "16px",
                      color: "#757575",
                      fontSize: "14px",
                    }}
                    margin="dense"
                    onBlur={() => handleUpdate()}
                  >
                    {tiposOrdem?.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.descricao}
                      </option>
                    ))}
                  </Select>
                </Grid>
              </Grid>

              <Grid
                container
                style={{
                  padding: "6px 0",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Grid item xs={6}>
                  <span
                    style={{
                      textAlign: "right",
                      display: "block",
                      paddingRight: "16px",
                      fontWeight: 700,
                      color: "#757575",
                    }}
                  >
                    Setor Atividade
                  </span>
                </Grid>
                <Grid item xs={4}>
                  <Select
                    label="Setor Atividade"
                    value={novaOrdem?.setor_atividade || ordem?.setor_atividade}
                    onChange={(e) =>
                      setNovaOrdem({
                        ...novaOrdem,
                        setor_atividade: e.target.value,
                      })
                    }
                    disableUnderline
                    style={{
                      paddingLeft: "16px",
                      color: "#757575",
                      fontSize: "14px",
                    }}
                    margin="dense"
                    onBlur={() => handleUpdate()}
                  >
                    {setorAtividades?.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.descricao}
                      </option>
                    ))}
                  </Select>
                </Grid>
                <Grid
                  container
                  style={{
                    padding: "6px 0",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Grid item xs={6}>
                    <span
                      style={{
                        textAlign: "right",
                        display: "block",
                        paddingRight: "16px",
                        fontWeight: 700,
                        color: "#757575",
                      }}
                    >
                      Motivo
                    </span>
                  </Grid>
                  <Select
                    label="Motivo da Ordem"
                    value={novaOrdem?.motivo_ordem || ordem?.motivo_ordem}
                    onChange={(e) =>
                      setNovaOrdem({
                        ...novaOrdem,
                        motivo_ordem: e.target.value,
                      })
                    }
                    disableUnderline
                    style={{
                      paddingLeft: "16px",
                      color: "#757575",
                      fontSize: "14px",
                    }}
                    margin="dense"
                    onBlur={() => handleUpdate()}
                  >
                    {motivosOrdem?.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.descricao}
                      </option>
                    ))}
                  </Select>
                </Grid>
                <Grid
                  container
                  style={{
                    padding: "6px 0",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Grid item xs={6}>
                    <span
                      style={{
                        textAlign: "right",
                        display: "block",
                        paddingRight: "16px",
                        fontWeight: 700,
                        color: "#757575",
                      }}
                    >
                      Centro de Fornecimento
                    </span>
                  </Grid>
                  <Select
                    label="Centro de Fornecimento"
                    value={novaOrdem?.centro_fornecimento || ordem?.centro_fornecimento}
                    onChange={(e) =>
                      setNovaOrdem({
                        ...novaOrdem,
                        centro_fornecimento: e.target.value,
                      })
                    }
                    disableUnderline
                    style={{
                      paddingLeft: "16px",
                      color: "#757575",
                      fontSize: "14px",
                    }}
                    margin="dense"
                    onBlur={() => handleUpdate()}
                  >
                    {centrosFornecimento?.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.descricao}
                      </option>
                    ))}
                  </Select>
                </Grid>
                <Grid
                  container
                  style={{
                    padding: "6px 0",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Grid item xs={6}>
                    <span
                      style={{
                        textAlign: "right",
                        display: "block",
                        paddingRight: "16px",
                        fontWeight: 700,
                        color: "#757575",
                      }}
                    >
                      CFOP
                    </span>
                  </Grid>
                  <Select
                    label="CFOP"
                    value={novaOrdem?.cfop || ordem?.cfop}
                    onChange={(e) =>
                      setNovaOrdem({ ...novaOrdem, cfop: e.target.value })
                    }
                    disableUnderline
                    style={{
                      paddingLeft: "16px",
                      color: "#757575",
                      fontSize: "14px",
                    }}
                    margin="dense"
                    onBlur={() => handleUpdate()}
                  >
                    {CFOPs?.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.descricao}
                      </option>
                    ))}
                  </Select>
                </Grid>
                <Grid
                  container
                  style={{
                    padding: "6px 0",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Grid item xs={6}>
                    <span
                      style={{
                        textAlign: "right",
                        display: "block",
                        paddingRight: "16px",
                        fontWeight: 700,
                        color: "#757575",
                      }}
                    >
                      Transportadora
                    </span>
                  </Grid>
                  <Select
                    label="Transporadora"
                    value={novaOrdem?.transportador || ordem?.transportador}
                    onChange={(e) =>
                      setNovaOrdem({ ...novaOrdem, transportador: e.target.value })
                    }
                    disableUnderline
                    style={{
                      paddingLeft: "16px",
                      color: "#757575",
                      fontSize: "14px",
                    }}
                    margin="dense"
                    onBlur={() => handleUpdate()}
                  >
                    {transportadoras?.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.nome}
                      </option>
                    ))}
                  </Select>
                </Grid>
                <Grid
                  container
                  style={{
                    padding: "6px 0",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Grid item xs={6}>
                    <span
                      style={{
                        textAlign: "right",
                        display: "block",
                        paddingRight: "16px",
                        fontWeight: 700,
                        color: "#757575",
                      }}
                    >
                      Dados adicionais NF
                    </span>
                  </Grid>
                  <TextField 
                    id="dados-adicionais-nf"
                    className={classes.root}
                    style={{
                      paddingLeft: "16px",
                      color: "#757575",
                      fontSize: "14px",
                    }}
                    multiline 
                    maxRows={4}
                    value={novaOrdem?.dados_adicionais_nf || ordem?.dados_adicionais_nf}
                    onChange={(e) =>
                      setNovaOrdem({ ...novaOrdem, dados_adicionais_nf: e.target.value })
                    }
                    onBlur={() => handleUpdate()}
                  />
                  
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Item head="SAP:" texto={ordem?.sap_code || "-"} />
              <Item
                head="Status:"
                texto={
                  TonersService.getOrderReleaseStatus(ordem?.status) || "-"
                }
              />
              <Item head="Fornecimento:" texto={ordem?.fornecimento || "-"} />
              <Item head="Nota Fiscal:" texto={ordem?.nota_fiscal || "-"} />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <p
            style={{
              fontSize: "16px",
              color: "#757575",
              fontWeight: 700,
              margin: 0,
              borderBottom: "1px solid #D3D3D3",
              paddingBottom: "8px",
            }}
          >
            Itens
          </p>
          <TableContainer>
            <Table aria-label="Itens solicitados">
              <TableHead>
                <TableRow>
                  {headers.map((heading, index) => (
                    <TableCell
                      style={{ color: "#757575" }}
                      key={heading}
                      width={`${index === 1 ? "40%" : "10%"}`}
                    >
                      <Box fontWeight="bold">{heading}</Box>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {ordem?.items.map((row: any, index: any) => (
                  <TableRow key={index}>
                    <TableCell style={{ color: "#757575" }}>
                      {row.material}
                    </TableCell>
                    <TableCell style={{ color: "#757575" }}>
                      {row.descricao || "-"}
                    </TableCell>
                    <TableCell style={{ color: "#757575" }}>
                      {row.quantidade}
                    </TableCell>
                    <TableCell style={{ color: "#757575" }}>
                      {row.centro_fornecimento}
                    </TableCell>
                    <TableCell style={{ color: "#757575" }}>
                      {row.store_location || "-"}
                    </TableCell>
                    {/* <TableCell style={{color: '#757575'}}><FaEdit color="#757575" size={24}/></TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box display="flex" justifyContent="right" py={4} gridColumnGap={16}>
          <Button
            size="small"
            color="inherit"
            variant="contained"
            onClick={printOrderLabels}
          >
            Imprimir Etiquetas
          </Button>
          <Button
            variant="contained"
            color="inherit"
            size="small"
            onClick={onClose}
          >
            <span style={{ padding: "0 56px" }}>Voltar</span>
          </Button>
        </Box>
      </Container>
    </Dialog>
  );
};

export default OrdemDetalhesDlg;
