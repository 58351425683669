/* 
 *   Mask functions para serem usadas nos MaskedInputs do react-text-mask
 *   As máscaras devem ser arrays com regex em cada dígito 
 */

export function phone(rawValue: string) {
    let mask: any[] = [];
    if (rawValue.length <= 10) {
      mask = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    } else {
      mask = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    }
    return mask;
  }
  
  export function cep(rawValue: string) {
    return [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
  }
  
  export function cnpj_cpf(rawValue: string) {
    let mask: any[] = [];
    if (rawValue.length <= 11) {
      mask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
    } else {
      mask = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
    }
    return mask;
  }