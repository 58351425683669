import axios from "axios";
import axiosInstance from "../../../services/AxiosConfig"


export const StatusColeta = new Map([
    ['PENDENTE', 'Pendente'],
    ['CONCLUIDO', 'Concluído']
]);

export const ModoColeta = new Map([
    ['EMAIL', 'Email'],
    ['TELEFONE', 'Telefone'],
    ['CSRC', 'CSRC']
])

/**
 * Retorna os contratos 
 * @param filter 
 * @param onSuccess 
 * @param onFail 
 */
export function fetchContratos(filter: any, onSuccess: any, onFail: any) {
    const fetch = async () => {
        return await axiosInstance.get('api/contratos', { params: filter });
    }

    const result = fetch();
    result.then((response) => {
        onSuccess(response.data)
    }).catch((reason) => {
        onFail(reason)
    })
}

const create = async (contador: any) => {
    return await axiosInstance.post(`api/contadores/`, contador);
}

const update = async (contador: any) => {
    return await axiosInstance.put(`api/contadores/${contador.id}/`, contador);
}

/**
 * Salva uma lista de contadores
 * @param contadores list de contadores
 * @param onSuccess callback de sucesso
 * @param onFail callback de falha
 */
export function saveContadores(contadores: any[], onSuccess: any, onFail: any) {

    const results: any[] = [];
    contadores.forEach(contador => {
        const { attachment, ...params } = contador;
        if (contador.id) {
            results.push(axiosInstance.put(`api/contadores/${contador.id}/`, params))
        } else {
            results.push(axiosInstance.post(`api/contadores/`, params));
        }
    });
    axios.all([...results]).then((responses) => {
        const datas = responses.map(response => response.data);
        onSuccess(datas)
    }).catch((erros) => onFail(erros));
}

/**
 * Cria um novo contador para o equipamento
 * @param contador dados do contador
 * @param onSuccess callback de sucesso
 * @param onFail callback de falha
 */
export function createContador(contador: any, onSuccess: any, onFail: any) {
    const result = create(contador);
    result.then((value) => onSuccess(value.data)).catch((reason) => onFail(reason));
}

/**
 * Atualiza um contador de um equipamento
 * @param contador dados do contador
 * @param onSuccess callback de sucesso
 * @param onFail callback de falha
 */
export function updateContador(contador: any, onSuccess: any, onFail: any) {
    const result = update(contador);
    result.then((value) => onSuccess(value.data)).catch((reason) => onFail(reason));
}

/**
 * Carrega os contadores de um equipamento
 * @param idEquipamento id do equipamento
 * @param onSuccess callback de sucesso
 * @param onFail callback de falha
 */
export function getContadorPorEquipamento(idEquipamento: number, onSuccess: any, onFail: any) {
    const get = async () => {
        return await axiosInstance.get(`api/contadores/equipamento=${idEquipamento}`);
    }

    get().then((value) => onSuccess(value.data)).catch((reason) => onFail(reason));

}

/**
 * Carrega os dados do contador do equipamento
 * @param idContador id do contador
 * @param onSuccess callback de sucesso
 * @param onFail callback de falha
 */
export function getContador(idContador: number, onSuccess: any, onFail: any) {
    const get = async () => {
        return await axiosInstance.get(`api/contadores/${idContador}/`);
    }

    get().then((value) => onSuccess(value.data)).catch((reason) => onFail(reason));

}

const uploadAttachement = async (contadorId: number, anexo: any, modo: string) => {
    const formData = new FormData();
    formData.append('attachment', anexo);
    formData.append('modo_coleta', modo);

    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }

    return await axiosInstance.put(`api/contadores/${contadorId}/`, formData, config);
}


/**
 * Salva um anexo ao contador
 * @param idContador id do contador
 * @param onSuccess callback de sucesso
 * @param onFail callback de falha
 */
export function addAnexo(contadorId: number, anexo: any, modo: string, onSuccess: any, onFail: any) {
    uploadAttachement(contadorId, anexo, modo).then((value) => onSuccess(value.data)).catch((reason) => onFail(reason));
}

/**
 * Salva os anexos dos contadores
 * @param contadores lista de contadores
 * @param onSuccess callback de sucesso
 * @param onFail callback de falha
 */
export function addAnexos(contadores: any[], onSuccess: any, onFail: any) {
    const results = contadores.map(contador => uploadAttachement(contador.id, contador.attachment, contador.modo_coleta));
    axios.all([...results]).then((responses) => {
        const datas = responses.map(response => response.data);
        onSuccess(datas)
    }).catch((erros) => onFail(erros));
}
