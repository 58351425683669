import { Box } from "@material-ui/core";
import { FC } from "react";

const SectionTitle: FC<{ title: string, subtitle?: boolean }> = ({ title, subtitle = false }) => {
    const color = '#33a0d8';
    return (
        <Box borderBottom="2px solid" color={color} borderColor={color}
            fontSize="h6.fontSize" marginTop={4} marginBottom={2}>
            { subtitle ? title : title.toUpperCase()}
        </Box>
    )
}

export default SectionTitle;