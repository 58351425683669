import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState, useContext } from 'react';
import CustomSelect from '../../../../components/CustomSelect';
import CustomSnackbar from '../../../../components/CustomSnackbar';
import EditableTableCell from '../../../../components/EditableTableCell/EditableTableCell';
import { TonersService } from '../../services/TonerService';
import EstoqueDlg from '../EstoqueDialog';

import { LoadingContext } from '../../../../contexts/loading';
import { isTemplateHead } from 'typescript';

type ItensTabProps = {
  itens: any[];
  id: string;
};

const ItensSolicitados: React.FC<ItensTabProps> = (props) => {
  const [itens, setItens] = useState(props.itens);
  const [requestResults, setRequestResults] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [material, setMaterial] = useState<any>();
  const [stockInfo, setStockInfo] = useState<any[]>([]);

  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    setItens(props.itens);
  }, [props.itens]);

  const handleUpdateQuantidadeAutorizada = (id: number, quantidade: number) => {
    TonersService.updateOrderItem(
      {
        id: id,
        quantidade_envio: quantidade,
      },
      (data: any) => {
        console.log(data);
      },
      (error: any) => {
        console.error(error);
      }
    );
  };

  function renderTable() {
    const headers: Array<string> = [
      'Item #',
      'Descrição',
      'Solicitado',
      'Estoque',
      'Cal. Cobertura',
      'Qtd. p/ Envio',
      'Centro de Fornecimento',
      'Depósito',
      'Situação',
      '',
    ];

    /**
     * Renderiza o Cabeçalho da tabela
     * @returns
     */
    function renderHeader() {
      return headers.map((heading, index) => (
        <TableCell key={heading} width={`${index === 1 ? '50%' : '10%'}`}>
          <Box fontWeight="bold">{heading}</Box>
        </TableCell>
      ));
    }

    const handleAutorizar = (item: any) => {
      TonersService.updateOrderItem(
        item,
        (data: any) => {
          requestResults.push({
            result: true,
            msg: 'Item atualizado com sucesso',
          });
        },
        (reason: any) => {
          requestResults.push({
            result: false,
            msg: 'Falha ao atualizar item',
          });
        }
      );
    };

    const handleClose = () => {
      TonersService.getTonerOrder(
        props.id,
        (data: any) => {
          setItens(data.materiais_solicitados);
          console.log(data);
        },
        (error: any) => {
          console.error(error);
        }
      );
      setOpen(false);
    };

    const handleConsultStock = (row: any) => {
      console.log(row);
      TonersService.getStockInfo(
        row.material,
        (data: any) => {
          setMaterial(row);
          setStockInfo(data);
          setOpen(true);
        },
        (reason: any) => {}
      );
    };

    /**
     * Renderiza as linhas da tabela
     * @returns
     */
    function renderRows() {
      return itens.map((row, index) => (
        <TableRow key={row.id}>
          <TableCell>{row.material}</TableCell>
          <TableCell>{row.descricao}</TableCell>
          <TableCell>{row.quantidade_solicitada}</TableCell>
          <TableCell>{row.quantidade_estoque}</TableCell>
          <TableCell>{row.calculo_cobertura || '0'}</TableCell>
          <EditableTableCell
            onChangeValue={(value) => {
              if (value !== '') {
                handleUpdateQuantidadeAutorizada(row.id, Number(value));
                row.quantidade_envio = value;
                let newItems = [...itens];
                newItems[index] = row;
                setItens(newItems);
              }
            }}
            value={row.quantidade_envio}
            type="number"
            width={80}
          >
            {row.quantidade_envio || '0'}
          </EditableTableCell>
          <TableCell>{row.centro_fornecimento || 'ND'}</TableCell>
          <TableCell>{row.deposito || 'ND'}</TableCell>
          <TableCell>
            <CustomSelect
              value={TonersService.getOrderItemStatus(row?.status)}
              options={Array.from(TonersService.OrderItemStatusMap.values())}
              onChange={(value) =>
                handleAutorizar({
                  ...row,
                  status: TonersService.getOrderItemStatusKey(value),
                })
              }
            />
          </TableCell>
          <TableCell>
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              onClick={() => {
                setLoading(true);
                handleConsultStock(row);
              }}
            >
              Selecionar Depósito
            </Button>
          </TableCell>
        </TableRow>
      ));
    }

    return (
      <TableContainer>
        <Table aria-label="Itens solicitados">
          <TableHead>
            <TableRow>{renderHeader()}</TableRow>
          </TableHead>
          <TableBody>{renderRows()}</TableBody>
        </Table>
        <EstoqueDlg
          open={open}
          material={material}
          stockInfo={stockInfo}
          onClose={handleClose}
        />
      </TableContainer>
    );
  }

  return (
    <>
      {renderTable()}
      <CustomSnackbar
        results={requestResults}
        onClose={() => setRequestResults([])}
      />
    </>
  );
};

export default ItensSolicitados;
