import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  logoBox: {
    display: 'flex',
    alignItems: 'center',
    borderRightStyle: 'solid',
    borderRight: '2px',
    borderRightColor: 'gray',
    paddingRight: 8,
    marginRight: 1,
    minWidth: 135,
  },
  barlogo: {
    display: 'inline-flex',
  },
  logo: {
    maxWidth: 48,
    paddingRight: 10,
    display: 'inline-flex',
  },
  mainMenu: {
    textAlign: 'left',
  },
  shortcutsBar: {
    flexGrow: 1,
  },
  offset: theme.mixins.toolbar,
  appBar: {
    flexGrow: 1,
    backgroundColor: 'white',
    color: 'black',
  },
}));

export default useStyles;
