import { Redirect, Route, RouteProps } from "react-router-dom";
import { authService } from "../services/Auth";


const PrivateRoute: React.FC<RouteProps> = (props) => {
    return (
        authService.isAuthenticated() ? (
            <Route {...props} />
        ) : (
            <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        )
    );
};

export default PrivateRoute;
