import Pusher from "pusher-js";
import { createContext, ReactNode, useEffect } from "react";
import { useSnackbar } from "notistack";

type ContextProps = {
  pusher: Pusher;
};

export const NotificationContext = createContext({} as ContextProps);

type ProviderProps = {
  children: ReactNode;
};

type PusherEvent = {
  short_message: string;
  long_message: string;
};

export function NotificationContextProvider({ children }: ProviderProps) {
  const { enqueueSnackbar } = useSnackbar();

  const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY || "", {
    cluster: "sa1",
  });

  useEffect(() => {
    const channel = pusher.subscribe(
      `user-${localStorage.getItem("logged_user")}`
    );
    channel.bind("error_event", function (data: PusherEvent) {
      enqueueSnackbar(data.short_message, {
        variant: "error",
      });
    });
    channel.bind("success_event", function (data: PusherEvent) {
      enqueueSnackbar(data.short_message, {
        variant: "success",
      });
    });
    channel.bind("info_event", function (data: PusherEvent) {
      enqueueSnackbar(data.short_message, {
        variant: "info",
      });
    });

    return () => {
      pusher.unsubscribe(`user-${localStorage.getItem("logged_user")}`);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const providerValue = {
    pusher,
  };

  return (
    <NotificationContext.Provider value={providerValue}>
      {children}
    </NotificationContext.Provider>
  );
}
