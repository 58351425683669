import {
  Box,
  Card,
  CardContent,
  Snackbar,
  TablePagination,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TONER_ORDER_STATUSES } from '../../../components/Constants';
import { FilterChipProps } from '../../../components/FilterChip';
import { FilterChipOption } from '../../../components/FilterChip/FilterSelect';
import FilterList from '../../../components/FilterList';
import SDHeader from '../../../components/SDHeader';
import { LoadingContext } from '../../../contexts/loading';
import { fetchFiliais } from '../../../services/BaseService';
import { fetchColaboradores } from '../../User/services/UserService';
import { TonersService } from '../services/TonerService';
import Datatable from './Datatable/Datatable';
import { UserProps, OrderRequestProps } from './SolicitacaoListProps';
import useStyles from './style';

const SolicitacaoList: React.FC = () => {
  enum FilterLabelEnum {
    SAP = 'SAP',
    STATUS = 'Status',
    FILIAL = 'Filial',
    ATRIBUIDOS_A_MIM = 'Atribuídos a mim',
    MODELO = 'Modelo',
    NUM_SERIE = 'Número de Série',
    ANALISTA = 'Analista',
    CLIENT = 'Cliente',
    CONTATO = 'Contato',
    DATA = 'Data',
    PERIODO = 'Período',
  }

  const classes = useStyles();
  const history = useHistory();

  const [orders, setOrders] = useState<OrderRequestProps[]>([]);
  const { setLoading } = useContext(LoadingContext);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState('');
  const [error, setError] = useState(false);
  const [sapID, setSapID] = useState(0);
  const [orderStatusID, setOrderStatusID] = useState(0);
  const [filialID, setFilialID] = useState(0);
  const [analistaID, setAnalistaID] = useState(0);
  const [date, setDate] = useState<Date | null>(new Date());
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [rowCount, setRowCount] = useState<number>(0);
  const [analistas, setAnalistas] = useState<UserProps[] | null>(null);
  const [filiais, setFiliais] = useState<any[] | null>(null);

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      let filtersParams: Array<number> = [
        page,
        rowsPerPage,
        sapID,
        orderStatusID,
        filialID,
        analistaID,
      ];
      const res = await TonersService.getTonerOrders(...filtersParams);
      setOrders(res.data.results);
      setRowCount(res.data.count);
      setLoading(false);
    };

    fetchOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, sapID, orderStatusID, filialID, analistaID]);

  const handleRowClick = (e: any, id: number) => {
    history.push(`/chamados/material/${id}`);
  };

  function getFiliaisOptions(): Array<FilterChipOption> {
    const options: Array<FilterChipOption> = [{ label: 'Todas', value: 0 }];
    filiais?.forEach((filial) =>
      options.push({ label: filial.nome, value: filial.id })
    );
    return options;
  }

  /** Carrega filiais */
  useEffect(() => {
    if (filiais === null) {
      fetchFiliais(
        (filiais: any) => {
          setFiliais([...filiais]);
        },
        (err: any) => {
          console.error(err);
        }
      );
    }
  }, [filiais]);

  function getStatusOptions(): Array<FilterChipOption> {
    const options: Array<FilterChipOption> = [{ label: 'Todos', value: 0 }];
    TONER_ORDER_STATUSES.forEach((status) =>
      options.push({ label: status.nome, value: status.id })
    );
    return options;
  }

  function getModelsOptions(): Array<FilterChipOption> {
    const options: Array<FilterChipOption> = [{ label: 'Todos', value: 0 }];
    options.push({ label: 'Modelo A', value: 1 });
    options.push({ label: 'Modelo B', value: 2 });
    options.push({ label: 'Modelo C', value: 3 });
    return options;
  }

  /**
   * Trata o callback de mudança de alguma propriedade dos filtros
   * @param filters
   */
  const handleChangeFilterValue = (filters: Array<FilterChipProps>) => {
    filters.forEach((filter) => {
      switch (filter.label) {
        case FilterLabelEnum.SAP:
          setSapID(filter.visibility ? filter.value : 0);
          break;
        case FilterLabelEnum.FILIAL:
          setFilialID(filter.visibility ? filter.value.value : 0);
          break;
        case FilterLabelEnum.STATUS:
          setOrderStatusID(filter.visibility ? filter.value.value : 0);
          break;
        case FilterLabelEnum.ATRIBUIDOS_A_MIM:
          setAnalistaID(
            filter.visibility ? Number(localStorage.getItem('logged_user')) : 0
          );
          break;
        case FilterLabelEnum.DATA:
          setDate(filter.visibility ? filter.value.value : null);
          break;
        default:
          break;
      }
    });
    /** Retorna a pagina inicial ao alterar um filtro */
    setPage(1);
  };

  const selectedFilters: Array<FilterChipProps> = [
    {
      label: FilterLabelEnum.SAP,
      visibility: true,
      value: '',
      type: 'text',
    },
    {
      label: FilterLabelEnum.FILIAL,
      visibility: true,
      value: getFiliaisOptions()[0],
      options: getFiliaisOptions(),
      type: 'select',
    },
    {
      label: FilterLabelEnum.STATUS,
      visibility: true,
      value: getStatusOptions()[0],
      options: getStatusOptions(),
      type: 'select',
    },
    {
      label: FilterLabelEnum.ATRIBUIDOS_A_MIM,
      visibility: false,
      value: false,
      type: 'boolean',
    },
  ];

  const allFilters: Map<string, Array<FilterChipProps>> = new Map();

  allFilters.set('Referência', [
    {
      label: FilterLabelEnum.NUM_SERIE,
      visibility: true,
      value: '',
      type: 'text',
      onDelete: () => {},
      category: 'Referência',
    },
    {
      label: FilterLabelEnum.MODELO,
      visibility: true,
      value: getModelsOptions()[0],
      options: getModelsOptions(),
      type: 'select',
      onDelete: () => {},
      category: 'Referência',
    },
  ]);
  allFilters.set('Social', [
    {
      label: FilterLabelEnum.ANALISTA,
      visibility: true,
      value: '',
      type: 'text',
      onDelete: () => {},
      category: 'Social',
    },
    {
      label: FilterLabelEnum.CLIENT,
      visibility: true,
      value: '',
      type: 'text',
      onDelete: () => {},
      category: 'Social',
    },
    {
      label: FilterLabelEnum.CONTATO,
      visibility: true,
      value: '',
      type: 'text',
      onDelete: () => {},
      category: 'Social',
    },
  ]);
  allFilters.set('Temporal', [
    {
      label: FilterLabelEnum.DATA,
      visibility: true,
      value: date,
      type: 'date',
      onDelete: () => {
        setDate(null);
      },
      category: 'Temporal',
    },
    {
      label: FilterLabelEnum.PERIODO,
      visibility: true,
      value: '',
      type: 'text',
      onDelete: () => {},
      category: 'Temporal',
    },
  ]);

  function handleChangeAnalista(orderId: number, analista: UserProps | null) {
    const newOrders: Array<OrderRequestProps> = Object.create(orders);
    const index = newOrders.findIndex((order) => order.id === orderId);
    newOrders[index].responsavel = analista;
    setOrders(newOrders);
    setLoading(true);
    TonersService.updateTonerOrder(
      orderId,
      { responsavel: analista?.id },
      (data: any) => {
        setLoading(false);
        setMsg('Responsavel salvo com sucesso!');
        setOpen(true);
      },
      (error: any) => {
        setLoading(false);
        setError(true);
        setMsg('Erro ao salvar analista');
        setOpen(true);
        console.log(error);
      }
    );
  }

  /** Carrega analistas responsaveis */
  useEffect(() => {
    if (analistas === null) {
      setLoading(true);
      fetchColaboradores(
        (data: any) => {
          setLoading(false);
          setAnalistas(data);
        },
        (error: any) => {
          console.error(error);
          setLoading(false);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analistas]);

  const handleClose = () => {
    setOpen(false);
    setMsg('');
    setError(false);
  };
  return (
    <div className={classes.root}>
      <SDHeader title="Solicitação de Materiais" subtitle="Chamados" />
      <Snackbar open={open} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          variant="standard"
          severity={error ? 'error' : 'success'}
        >
          {msg}
        </Alert>
      </Snackbar>

      <FilterList
        selectedFilters={selectedFilters}
        allFilters={allFilters}
        onChangeSelectedFilters={(filters) => handleChangeFilterValue(filters)}
      />
      <Box m={3}>
        <Card>
          <CardContent>
            <Box>
              <Datatable
                data={orders}
                onRowClick={handleRowClick}
                onChangeAnalista={handleChangeAnalista}
                analistas={analistas || []}
              />

              <TablePagination
                component="div"
                rowsPerPageOptions={[5, 10, 15]}
                count={rowCount}
                page={page - 1}
                onPageChange={(event: any, newPage: number) => {
                  setPage(newPage + 1);
                }}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(event: any) => {
                  setRowsPerPage(parseInt(event.target.value, 10));
                }}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default SolicitacaoList;
