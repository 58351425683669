import React from 'react'
import { Box, Typography } from '@material-ui/core';
import { SDHeaderProps } from '.'

const SDHeader: React.FC<SDHeaderProps> = ({title, subtitle}) => {
    return (
        <Box mt={3}>
            <Typography variant="subtitle1" color="textSecondary">{subtitle.toUpperCase()}</Typography>
            <Typography variant="h1" color="textSecondary">{title}</Typography>
        </Box>
    )
}

export default SDHeader