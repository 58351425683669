import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  loading: {
    position: 'fixed',
    zIndex: 999,
  },
}));

export default useStyles;
