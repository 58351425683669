import { Box } from '@material-ui/core';
import React, { FC } from 'react';
import { BoxTitleProps } from './BoxTitleProps';

const BoxTitle: FC<BoxTitleProps> = ({ title, boxProps, children }) => {

    return (
        <Box border="1px solid grey" borderRadius={8} p={2}
            mb={2} {...boxProps}>
            <Box fontSize="0.75rem" component="span"
                color="#757575" p={1} ml={-1}
                bgcolor="#fff" marginTop="-31px"
                display="flex" width="max-content" >
                {title}
            </Box>
            {children}
        </Box>
    );
}

export default BoxTitle;