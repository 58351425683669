import { Dialog, DialogTitle, Button, Container, Table, Box, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import {  } from '@material-ui/core';
import {useContext, useEffect} from 'react'
import { TonersService } from '../../services/TonerService'

import {LoadingContext} from '../../../../contexts/loading'


type DlgProps = {
  open: boolean
  material: any
  stockInfo: any
  onClose: () => void
}

const EstoqueDlg = ({open, material, stockInfo, onClose}: DlgProps) => {
  const {setLoading} = useContext(LoadingContext)

  const headers: Array<string> = [
    'Centro de Fornecimento',
    'Depósito',
    'Disponível',
    ''
  ]

  useEffect(() => {
    setLoading(false)
  })

  const handleSave = (row: any) => {
    TonersService.updateOrderItem({
      id: material.id,
      deposito: row.deposito,
      centro_fornecimento: row.centro
    }, (data: any) => {
      console.log(data)
      onClose()
    }, (error: any) => {
      console.error(error)
    })
  }

  return (
    <Dialog open={open} maxWidth="md" fullWidth onBackdropClick={onClose}>
      <DialogTitle style={{borderBottom: '1px solid #d7d7d7'}}>{material?.material}<strong> {material?.descricao}</strong></DialogTitle>
      <Container>
        <TableContainer>
                <Table aria-label="Itens solicitados">
                    <TableHead>
                        <TableRow>
                            {headers.map((heading, index) =><TableCell key={heading} width={`${index === 1 ? '50%' : '10%'}`}>
                    <Box fontWeight="bold">{heading}</Box>
                </TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {stockInfo.map((row:any, index:any) =>
                          <TableRow key={index}>
                            <TableCell>{row.centro}</TableCell>
                            <TableCell>{row.deposito}</TableCell>
                            <TableCell>{row.disponivel}</TableCell>
                            <TableCell><Button variant="text" color="primary" onClick={() => handleSave(row)}>Selecionar</Button></TableCell>
                          </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box display="flex" justifyContent="right" marginY={5}>
              <Button variant="contained" color="inherit" size="small" onClick={onClose}>Voltar</Button>
            </Box>
      </Container>
    </Dialog>
  )
}

export default EstoqueDlg
