import { Box } from '@material-ui/core'
import React from 'react'


const EquipmentAddressCard: React.FC<any> = ({ data }) => {
    const resolveAddress = (endereco: any) => {
        console.log(data)
        const logradouro = endereco?.ender_ins_logradouro || 'Logradouro não informado';
        const numero = endereco?.ender_ins_numero || 'S/N';
        const bairro = endereco?.ender_ins_bairro || 'Bairro não informado';
        const cidade = endereco?.ender_ins_municipio || 'Cidade não informada';
        const estado = endereco?.ender_ins_estado || 'Estado não informado';
        const cep = endereco?.ender_ins_cep || 'CEP não informado';
        return `${logradouro},${numero}, ${bairro}, ${cidade}-${estado}, ${cep}`;
    }
    return (
        <Box mr={3}>
            <b>Local de Instalação:</b><br />
            {resolveAddress(data)}<br />
            <b>Localização:</b><br />
            {data?.localizacao || ''}
        </Box>
    )
}

export default EquipmentAddressCard