import { Grid, TextField } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { Cliente } from '../domain/Client/models';
import { contextoEmpresarialMap, tipoClienteMap } from '../domain/Client/services';
import CustomRadioGroup from './CustomRadioGroup';
import MaskedInput from 'react-text-mask';
import * as mask from './Masks';
import { makeStyles } from '@material-ui/core/styles';

//Styles para remoção das arrows nos numbers inputs
const useStyles = makeStyles({
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
});

const FormCompany: FC<{
    company: any | Cliente,
    onChange: (values: any | Cliente) => void,
    validation?: any[]
}> = ({ company, onChange, validation }) => {

    const [data, setData] = useState<any | Cliente | null>(null);
    const [form, setForm] = useState<any>({});

    const classes = useStyles();

    useEffect(() => {
        setData(company);
    }, [company]);

    /**
     * Altera um valor dos dados e dispara callback de onChange
     * @param field campo de data modificado
     */
    const handleChangeData = (field: any) => {
        const values = { ...data, ...field };
        setData(values);
        onChange(values)
    }

    /** Valida os campos do formulário */
    useEffect(() => {
        setForm({
            cod_sap: {
                required: true,
                helperText: data?.cod_sap ? '' : 'Campo obrigatório',
                error: data?.cod_sap === '',
            },
            inscricao_municipal: {
                required: true,
                helperText: data?.inscricao_municipal ? '' : 'Campo obrigatório',
                error: data?.inscricao_municipal === '',
            },
            nome: {
                required: true,
                helperText: data?.nome ? '' : 'Campo obrigatório',
                error: data?.nome === '',
            }
        });
    }, [data])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { setForm({ ...form, ...validation }); }, [validation]);

    const tipoCliente = (): string => {
        let tipo = data?.tipo_cliente || 'Cliente Final';
        tipo = tipoClienteMap.has(tipo) ? tipoClienteMap.get(tipo) : tipo;
        return tipo;
    }

    const contextoEmpresarial = (): string => {
        let tipo = data?.tipo_razao_social || 'Matriz';
        tipo = contextoEmpresarialMap.has(tipo) ? contextoEmpresarialMap.get(tipo) : tipo;
        return tipo;
    }
    //shrink deve receber um bool, e estava recebendo uma string
    //inputs não estavam sendo controlados pelo estado, gerando conflito
    return (
        <Grid container spacing={2}>
            <Grid item sm={12}>
                <CustomRadioGroup label="Tipo de Cliente"
                    value={tipoCliente()}
                    options={Array.from(tipoClienteMap.values())}
                    onChange={(value) => handleChangeData({ tipo_cliente: value })} />
            </Grid>
            <Grid item sm={12}>
                <TextField label="Razão Social" value={data?.nome || ''} fullWidth
                    onChange={(e) => handleChangeData({ nome: e.target.value })}
                    {...form.nome} required
                    InputLabelProps={{ shrink: data?.nome ? true : false }} /> 
            </Grid>
            <Grid item sm={12}>
                <CustomRadioGroup label="Contexto Empresarial"
                    value={contextoEmpresarial()}
                    options={Array.from(contextoEmpresarialMap.values())}
                    onChange={(value) => handleChangeData({ tipo_razao_social: value })} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField label="Inscrição Estadual" value={data?.inscricao_estadual || ''} fullWidth
                    onChange={(e) => handleChangeData({ inscricao_estadual: e.target.value })}
                    InputLabelProps={{ shrink: data?.inscricao_estadual ? true : false }} InputProps={{ type: 'number' }}
                    className={classes.input}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField label="Inscrição Municipal"
                    fullWidth value={data?.inscricao_municipal || ''}
                    onChange={(e) => handleChangeData({ inscricao_municipal: e.target.value })}
                    required {...form.inscricao_municipal}
                    InputLabelProps={{ shrink: data?.inscricao_municipal ? true : false }} InputProps={{ type: 'number' }}
                    className={classes.input}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <MaskedInput
                    mask={mask.cnpj_cpf}
                    value={data?.cnpj_cpf || ''}
                    onChange={(e) => handleChangeData({ cnpj_cpf: e.target.value.replace(/\D+/g, '') })}
                    guide={false}
                    render={(ref, props) => (
                        <TextField inputRef={ref} {...props} label="CNPJ" fullWidth InputLabelProps={{ shrink: data?.cnpj_cpf ? true : false }} />
                    )}
                    />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField label="SAP" value={data?.cod_sap || ''} fullWidth
                    onChange={(e) => handleChangeData({ cod_sap: e.target.value })}
                    {...form.cod_sap} required
                    InputLabelProps={{ shrink: data?.cod_sap ? true : false }} InputProps={{ type: 'number' }}
                    className={classes.input}/>
            </Grid>
        </Grid>
    );
}

export default FormCompany;