import { Container, Divider } from '@material-ui/core';
import React, { FC } from 'react';
import SDHeader from '../../components/SDHeader';
import SectionTitle from '../../components/SectionTitle';
import { Veiculo as IVeiculo } from './models';
import FormVeiculo from './components/FormVeiculo';

const Veiculo: FC<{veiculo?: IVeiculo}> = (props) => {

    return (
        <Container>
            <SDHeader subtitle="Cadastros" title="Cadastro de Veiculo"/>
            <Divider />
            <SectionTitle title="Dados do Veículo"/>
            <FormVeiculo />
        </Container> 
    )
}

export default Veiculo;