/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dialog, DialogTitle, Grid, TextField, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, DialogContent, Box } from '@material-ui/core';
import { DataGrid, GridColDef, GridRowId } from '@material-ui/data-grid';
import { SyncAlt } from '@material-ui/icons';
import React, { FC, useState } from 'react';
import { useEffect } from 'react';
import { fetchEquipamentos } from '../../services/BaseService';
import BoxTitle from '../BoxTitle';
import { EquipmentSelectorProps } from './EquipmentSelectorProps';

const EquipmentSelector: FC<EquipmentSelectorProps> = ({
    open,
    onClose,
    ...props }) => {

    const [serialNumber, setSerialNumber] = useState('');
    const [equipments, setEquipments] = useState<any[] | null>(null);
    const [checkedEquipList, setCheckedEquipList] = useState<any>({ selected: [], unselected: [] });
    const [selectedEquipments, setSelectedEquipments] = useState<any[]>([])
    const [loading, setLoading] = useState(false);

    /**
     * Carrega os equipamentos cadastrados
     */
    const loadEquipamentos = (all: boolean) => {
        let filter = undefined;
        if (!all && serialNumber !== '') {
            filter = { serie: serialNumber }
        }
        setLoading(true);
        fetchEquipamentos(
            (data: any) => {
                const unselected = data.filter((item: any) => {
                    return selectedEquipments.find(
                        equip => equip.id === item.id
                    ) === undefined;
                });
                setEquipments(unselected);
                setLoading(false);
            },
            (error: any) => {
                setLoading(false);
                console.error(error);
            },
            filter
        )
    }

    useEffect(() => {
        setSelectedEquipments(props.selectedEquipments || []);
    }, [props.selectedEquipments]);


    function selectionTable(equipments: any[], title: string, type: 'unselected' | 'selected') {
        const columns: GridColDef[] = [
            { field: 'id', hide: false },
            { field: 'serie', headerName: 'Série' },
            { field: 'nome_modelo', headerName: 'Modelo', width: 250 }
        ]

        const handleSelection = (param: GridRowId[]) => {
            const newValue = { ...checkedEquipList };
            newValue[type] = [...param];
            setCheckedEquipList(newValue);
        }

        return (
            <BoxTitle title={title.toUpperCase()}>
                <DataGrid rows={equipments.map(item => {return {...item, nome_modelo: item.modelo.nome}})} columns={columns} checkboxSelection autoHeight
                    disableColumnMenu hideFooterSelectedRowCount pageSize={5} density="compact"
                    loading={loading} onSelectionModelChange={handleSelection} />
            </BoxTitle>
        );
    }

    const addSelectedEquip = () => {
        if (checkedEquipList?.unselected.length > 0) {
            const selected = checkedEquipList?.unselected.map((item: any) => {
                return equipments?.filter(e => e.id === Number(item))[0];
            });
            setSelectedEquipments([...selectedEquipments, ...selected]);
            const filtered = equipments?.filter(item => selected.indexOf(item) === -1) || [];
            setEquipments([...filtered]);

        }
    }

    const handleClose = (e: any, reason: any) => {
        if (onClose) {
            onClose(props.selectedEquipments || []);
        }
        setSelectedEquipments(props.selectedEquipments || []);
        setEquipments([]);
    }

    const applySelection = (e: any) => {
        if (onClose) {
            onClose(selectedEquipments);
        }
    }

    const removeSelected = (all: boolean) => {
        if ((checkedEquipList?.selected.length > 0 || all) && equipments) {
            const newEquipments = [...equipments];
            let newSelected: any[] = [];
            if (all) {
                newEquipments.push(...selectedEquipments);
            } else {
                newSelected = [...selectedEquipments];
                const toUnSelect: any[] = [];
                checkedEquipList?.selected.forEach((item: any) => {
                    const index = newSelected.findIndex(e => e.id === Number(item));
                    if (index > -1) {
                        toUnSelect.push(newSelected[index]);
                        newSelected.splice(index, 1);
                    }
                });
                newEquipments.push(...toUnSelect);
            }
            setSelectedEquipments([...newSelected]);
            newEquipments.sort((a, b) => a.serie.localeCompare(b.serie))
            setEquipments([...newEquipments]);
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="lg">
            <DialogTitle>Seleção de Equipamento(s)</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box display="flex" alignItems="center">
                            <TextField
                                label="Nº de Série"
                                variant="outlined" value={serialNumber}
                                onChange={(e) => setSerialNumber(e.target.value)}
                                margin="dense" />&nbsp;&nbsp;
                            <Button variant="outlined"
                                style={{ textTransform: 'none' }}
                                color="primary" onClick={() => loadEquipamentos(false)}>
                                Buscar
                            </Button>&nbsp;&nbsp;
                            <Button variant="outlined"
                                style={{ textTransform: 'none' }}
                                color="secondary" onClick={() => loadEquipamentos(true)}>
                                Listar Todos
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item sm={12} md={5}>
                        {selectionTable(equipments || [], 'Resultado da Busca', 'unselected')}
                    </Grid>
                    <Grid item md={2}>
                        <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                            <SyncAlt fontSize="large" />
                        </Box>
                    </Grid>
                    <Grid item sm={12} md={5}>
                        {selectionTable(selectedEquipments, 'Equipamentos Selecionados', 'selected')}
                    </Grid>
                    <Grid item sm={12} md={5}>
                        <Box textAlign="right">
                            <Button variant="outlined"
                                style={{ textTransform: 'none' }}
                                color="primary" onClick={() => addSelectedEquip()}>
                                Adicionar Marcado(s)
                            </Button>
                        </Box>
                    </Grid>
                    <Grid sm={2} item></Grid>
                    <Grid item sm={12} md={5}>
                        <Box textAlign="right" mb={3}>
                            <Button variant="outlined"
                                style={{ textTransform: 'none' }}
                                color="primary" onClick={() => removeSelected(false)}>
                                Remover Marcado(s)
                            </Button>&nbsp;&nbsp;
                            <Button variant="outlined"
                                style={{ textTransform: 'none' }}
                                color="primary" onClick={applySelection}>
                                Aplicar Seleção
                            </Button><br /><br />
                            <Button variant="outlined"
                                style={{ textTransform: 'none' }}
                                color="secondary" onClick={() => removeSelected(true)}>
                                Remover Todos
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default EquipmentSelector;