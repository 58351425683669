import axiosInstance from '../AxiosConfig';

interface ICredentialsData {
  username: string;
  password: string;
}

interface ITokenData {
  refresh: string;
  access: string;
  user_id: number;
  filial: number;
  roles: Array<string>;
  permissions: Array<String>;
}

const obtainToken = (data: ICredentialsData, onSuccess: any, onFail: any) => {
  return axiosInstance
    .post<ITokenData>('api/token/', {
      username: data.username,
      password: data.password,
    })
    .then((res) => {
      // let decoded_token = jwt_decode<JwtPayload>(res.data.access)
      axiosInstance.defaults.headers['Authorization'] =
        'Bearer ' + res.data.access;
      console.log(res.data);
      localStorage.setItem('logged_user', `${res.data.user_id}`);
      localStorage.setItem('filial', `${res.data.filial}`);
      localStorage.setItem('access_token', res.data.access);
      localStorage.setItem('refresh_token', res.data.refresh);
      onSuccess();
    })
    .catch((err) => {
      console.log(err);
      onFail(err);
    });
};

const logout = () => {
  return axiosInstance
    .post('users/logout/', {
      refresh_token: localStorage.getItem('refresh_token'),
    })
    .then((res) => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('filial');
      localStorage.removeItem('logged_user');
      axiosInstance.defaults.headers['Authorization'] = null;
    })
    .catch((err) => {
      console.log(err);
    });
};

const isAuthenticated = () => {
  const result = localStorage.getItem('access_token') !== null;
  return result;
};

export const authService = {
  obtainToken,
  logout,
  isAuthenticated,
};
