import React, { FC } from "react";
import { useState } from "react";
import { Box, Popover, Select, MenuItem } from "@material-ui/core";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridEditCellPropsParams,
} from "@material-ui/data-grid";

const EquipmentTable: FC<{
  tipo: "AVULSO" | "CONTRATO";
  equipments: any[];
  clientes: any[];
  onChangeValue?: (equipamento: any) => void;
}> = ({ tipo, equipments, onChangeValue, clientes }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedRow, setSelectedRow] = useState<any>("");

  const equipGrid = (rows: any[]) => {
    const columnsContrato: GridColDef[] = [
      {
        field: "index",
        width: 10,
        headerName: "#",
        sortable: false,
        disableColumnMenu: true,
      },
      { field: "id", hide: true },
      { field: "equipamento_serie", headerName: "Série", width: 180 },
      { field: "modelo_nome", headerName: "Modelo", width: 180 },
      {
        field: "cliente_nome",
        headerName: "Cliente",
        editable: true,
        width: 180,
      },
      { field: "cliente_id", hide: true },
      {
        field: "taxa_fixa",
        headerName: "Taxa-Fixa",
        editable: true,
        width: 190,
      },
      {
        field: "click_pb",
        headerName: "Clique PB",
        editable: true,
        width: 190,
      },
      {
        field: "click_color",
        headerName: "Clique CL",
        editable: true,
        width: 190,
      },
      {
        field: "status_financeiro",
        headerName: "Status Financeiro",
        editable: true,
        width: 190,
      },
    ];

    const columnsAvulso: GridColDef[] = [
      {
        field: "index",
        width: 10,
        headerName: "#",
        sortable: false,
        disableColumnMenu: true,
      },
      { field: "id", hide: true },
      { field: "equipamento_serie", headerName: "Série", width: 180 },
      { field: "modelo_nome", headerName: "Modelo", width: 180 },
    ];

    const handleValueChange = (params: GridEditCellPropsParams, event: any) => {
      if (onChangeValue && params.props) {
        const value: any = { equipamento: { id: selectedRow.equipamento.id } };
        switch (params.field) {
          case "taxa_fixa" || "click_pb" || "click_color":
            value[params.field] = Number(params.props.value).toFixed(4);
            break;
          default:
            value[params.field] = params.props.value;
            break;
        }
        onChangeValue(value);
      }
    };

    const handleClienteSelection = (params: GridCellParams, e: any) => {
      setSelectedRow(params.row);
      if (params.field === "cliente_nome") {
        setAnchorEl(e.currentTarget);
      }
    };

    const getCliente = (id: any) => {
      const cliente = clientes.find((item) => item.id === id);
      return cliente ? cliente.nome : "";
    };

    const handleSelectCliente = (clientId: any) => {
      const values = {
        cliente_id: clientId,
        cliente_nome: getCliente(clientId),
      };
      setSelectedRow({ ...selectedRow, ...values });
      if (onChangeValue) {
        onChangeValue({
          equipamento: { id: selectedRow.equipamento.id },
          ...values,
        });
      }
    };

    // function currency2string(value: any) {
    //   let result = "0,00";
    //   if (typeof value === "number") {
    //     result = String(value.toFixed(2)).replace(".", ",");
    //   } else if (typeof value === "string") {
    //     result = value.replace(".", ",");
    //   }
    //   return result;
    // }

    return (
      <Box style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={rows.map((item, index) => {
            return {
              ...item,
              index: index + 1,
              modelo_nome: item.equipamento.modelo.nome,
              equipamento_serie: item.equipamento.serie,
              taxa_fixa: Number(item.taxa_fixa).toFixed(4),
              click_pb: Number(item.click_pb).toFixed(4),
              click_color: Number(item.click_color).toFixed(4),
            };
          })}
          columns={tipo === "CONTRATO" ? columnsContrato : columnsAvulso}
          pageSize={5}
          onEditCellChange={handleValueChange}
          onCellClick={handleClienteSelection}
          onCellFocusOut={handleClienteSelection}
          onCellKeyDown={(p, e) => {
            if (e.key === "Enter") {
              handleClienteSelection(p, e);
            }
          }}
        />
        <Popover
          open={anchorEl !== null}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Select
            value={selectedRow.cliente}
            open={true}
            onChange={(e) => handleSelectCliente(e.target.value)}
            onClick={() => setAnchorEl(null)}
          >
            {clientes.map((item: any) => (
              <MenuItem value={item.id} key={item.id}>
                {item.nome}
              </MenuItem>
            ))}
          </Select>
        </Popover>
      </Box>
    );
  };

  return <Box>{equipGrid(equipments)}</Box>;
};

export default EquipmentTable;
