import { Box, Button, Grid, TextField } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import CustomRadioGroup from '../../../components/CustomRadioGroup';
import CustomSelect from '../../../components/CustomSelect';
import { fetchFiliais } from '../../../services/BaseService';
import { Veiculo } from '../models';

const FormVeiculo: FC<{ veiculo?: Veiculo, onSubmit?: () => void }> = (props) => {

    const [data, setData] = useState<null | Veiculo | any>(null);
    // const [form, setForm] = useState<null | any>(null);
    const [filiais, setFiliais] = useState<any[] | null>(null);

    /** Lista filiais da Konica */
    useEffect(() => {
        if (filiais === null) {
            fetchFiliais(
                (filiais: any) => {
                    setFiliais([...filiais]);
                },
                (err: any) => {
                    console.error(err);
                });
        }
    }, [filiais]);

    const handleChangeValue = (e: any) => {
        let newData = null;
        switch (e.target.name) {
            case 'modelo':
                newData = { ...data, modelo: e.target.value };
                break;
            case 'placa':
                newData = { ...data, placa: e.target.value };
                break;
            default:
                break;
        }
        setData(newData);
    }

    function getSelectedItem(list: any[] | null, value: string) {
        let item = null;
        if (list) {
            const index = list?.findIndex(item => item.nome === value);
            if (index > -1) {
                item = list[index];
            }
        }
        return item;
    }

    const handleChangeFilial = (value: string) => {
        const item = getSelectedItem(filiais, value)
        if (item) {
            setData({ ...data, filial: item })
        }
    }

    const handleSubmit = (e: any) => {
        if (props.onSubmit) {
            props.onSubmit();
        }
    }

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item md={4}>
                    <TextField label="Modelo" value={data?.nome} name="modelo" fullWidth
                        onChange={handleChangeValue} placeholder="VW Gol 1.0" />
                </Grid>
                <Grid item md={4}>
                    <TextField label="Placa" value={data?.nome} name="placa" fullWidth
                        onChange={handleChangeValue} placeholder="AOK-0000" />
                </Grid>
                <Grid item md={4}>
                    <CustomSelect label="Filial" value={data?.filial?.municipio}
                        options={filiais?.map(item => item.municipio) || []}
                        onChange={handleChangeFilial} />
                </Grid>
                <Grid item md={12}>
                    <CustomRadioGroup label="Ativo"
                        value={data?.ativo === 'false' ? 'Não' : 'Sim'}
                        options={['Sim', 'Não']}
                        onChange={(value) => setData({ ...data, ativo: value === 'Sim'})} />
                </Grid>
                <Grid item md={12}>
                    <TextField label="Motivo" value={data?.motivo} name="motivo" fullWidth
                        onChange={handleChangeValue} 
                        rows={5}
                        multiline
                        variant="outlined"
                        placeholder="Descrição do motivo uso do veículo" />
                </Grid>
            </Grid>
            <Box mt={3} textAlign="right">
                <Button variant="outlined" color="primary"
                    style={{textTransform: 'none'}}
                    onClick={handleSubmit}>Cadastrar</Button> 
            </Box>
        </Box>
    )
}

export default FormVeiculo;