

export const TONER_ORDER_STATUSES = [
    { id: 1, nome: 'Não liberado' },
    { id: 2, nome: 'Em processamento' },
    { id: 3, nome: 'Liberado' },
]

export const BRANCH_OFFICES = [
    { id: 6, nome: 'Manaus' },
    { id: 7, nome: 'São Paulo' },
    { id: 8, nome: 'Porto Alegre' },
    { id: 9, nome: 'Florianópolis' },
    { id: 10, nome: 'Recife' }
]
