import { Box, Select, TableCell, TextField } from '@material-ui/core';
import React, { useState } from 'react'
import { EditableTableCellProps } from './EditableTableCellProps';
import useStyles from './style';

const EditableTableCell: React.FC<EditableTableCellProps> = (props) => {
    const [isEditing, setIsEditing] = useState(false);
    const [options] = useState(props.options || [])
    const [value, setValue] = useState(props.value);
    const [open, setOpen] = useState(true);
    const [type] = useState(props.type || 'text');

    const classes = useStyles();

    const handleClick = (e: any) => {
        e.stopPropagation();
        setOpen(true);
        setIsEditing(true)
    }

    const handleOnChange = (e: any) => {
        setIsEditing(false);
        let value = null;
        const id: number = e.target.value as number;
        if (id >= 0) {
            const index = options.findIndex(a => a.value === id)
            value = options[index];
        }
        setValue(value);
        if (props.onChangeValue) {
            props.onChangeValue(value);
        }
    }

    function renderSelector() {
        return (
            <Select
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                value={value ? value.value : -1}
                onChange={handleOnChange}
                onClick={(e: any) => {
                    e.stopPropagation();
                    setIsEditing(false);
                }}
            >
                <option className={classes.options} aria-label="None" value="-1" />
                {options.map((option) => {
                    return <option className={classes.options}
                        key={option.value} value={option.value}
                    >
                        {option.label}
                    </option>
                })}
            </Select>
        )
    }

    function renderTextEditor() {
        const styleTextField = {
            width: props.width - 32,
        };
        const styleInput = {
            textAlign: props.tableCellProps?.align,
            fontSize: '0.875rem',
        }
        return (
            <TextField
                autoFocus
                inputProps={{ style: styleInput }}
                type={type}
                value={value}
                onChange={(e: any) => setValue(e.target.value)}
                style={styleTextField}
                onBlur={(e: any) => {
                    const value = e.target.value;
                    setValue(value);
                    if (props.onChangeValue) {
                        props.onChangeValue(value);
                    }
                    setIsEditing(false);
                }} />
        )
    }

    function renderField() {
        if (props.options) {
            return renderSelector();
        } else {
            return renderTextEditor();
        }
    }

    return (
        <TableCell onClick={handleClick}
            width={props.width}
            {...props.tableCellProps}>
            {!isEditing ? (
                <Box className={classes.tableCell}>
                    {props.children}
                </Box>) : renderField()
            }
        </TableCell>
    )
}

export default EditableTableCell;