import axios from 'axios'
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_URL = REACT_APP_BASE_URL ? REACT_APP_BASE_URL : 'http://localhost:8000';
const axiosInstance = axios.create({baseURL: BASE_URL});

// Request interceptor
axiosInstance.interceptors.request.use(
    config => {
        config.timeout = 5000
        const token = localStorage.getItem('access_token')
        
        if (token){
            config.headers['Authorization'] = `Bearer ${token}`
        }
        config.headers['Content-Type'] = 'application/json'
        return config
    },
    error => {
        Promise.reject(error)
    }
)

// Response interceptor
axiosInstance.interceptors.response.use((response) => {
    return response
}, function(error){
    const originalRequest = error.config

    if (error.message === 'Network Error'){
        return Promise.reject(error)
    }

    if (error.response.status === 401){
        console.log('Send user to login page')
        return Promise.reject(error)
    }

    if (error.response.status === 403 &&
        error.response.data.code === 'token_not_valid') {

        const refreshToken = localStorage.getItem('refresh_token')

        return axiosInstance
            .post('api/token/refresh/', {refresh: refreshToken})
            .then((response) => {
                if (response.status === 200){
                    localStorage.setItem('access_token', response.data.access)
                    localStorage.setItem('refresh_token', response.data.refresh)

                    axiosInstance.defaults.headers['Authorization'] = "Bearer " + response.data.access
                    originalRequest.headers['Authorization'] = "Bearer " + response.data.access
                    return axiosInstance(originalRequest)
                }else{
                    // Código 401 {"detail":"Token is blacklisted","code":"token_not_valid"}
                    console.log('Não foi possível atualizar o token de acesso')
                }
            })
            .catch((err) => {
                console.log(">>>>>>>>>>>>>>>>>>>>>>>", err)
                if(err.response?.status === 401){
                    localStorage.removeItem('access_token')
                    localStorage.removeItem('refresh_token')
                    window.location.href = '/login/'
                }
                console.log('Refresh token not avaliable.')
            })
    }
    return Promise.reject(error)
})


export default axiosInstance