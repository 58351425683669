import { Container, Divider } from '@material-ui/core';
import React, { FC } from 'react';
import SDHeader from '../../components/SDHeader';
import SectionTitle from '../../components/SectionTitle';
import FormContadores from './components/FormContadores';
import { Contadores as IVeiculo } from './models';

const Contadores: FC<{veiculo?: IVeiculo}> = (props) => {

    return (
        <Container>
            <SDHeader subtitle="Cadastros" title="Contadores"/>
            <Divider />
            <SectionTitle title="Seleção de Cliente/Contrato"/>
            <FormContadores />
        </Container> 
    )
}

export default Contadores;