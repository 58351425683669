import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { useState } from 'react';
import CustomSnackbar from '../../../../components/CustomSnackbar';
import { TonersService } from '../../services/TonerService';
import OrdemDetalhesDlg from '../OrdemDetalhesDlg';

type ItensTabProps = {
  itens: any[] | null;
  forceUpdate: () => void;
};

const OrdensDeVenda: React.FC<ItensTabProps> = (props) => {
  const { itens } = props;
  const [requestResults, setRequestResults] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [ordem, setOrdem] = useState<any>();

  function renderTable() {
    const headers: Array<string> = [
      'ID #',
      'SAP',
      'Tipo',
      'Setor Atividade',
      'Motivo',
      'Centro de Fornecimento',
      'CFOP',
      'Status',
      'Fornecimento',
      'Nota Fiscal',
    ];

    /**
     * Renderiza o Cabeçalho da tabela
     * @returns
     */
    function renderHeader() {
      return headers.map((heading, index) => (
        <TableCell key={heading} width={`${index === 8 ? '20%' : undefined}`}>
          <Box fontWeight="bold">{heading}</Box>
        </TableCell>
      ));
    }

    const handleClose = () => {
      setOpen(false);
      console.log('Call force update')
      props.forceUpdate();
    };

    /**
     * Renderiza as linhas da tabela
     * @returns
     */
    function renderRows() {
      const style = {
        color: '#757575',
      };
      return itens?.map((row, index) => (
        <TableRow key={row?.id}>
          <TableCell
            style={style}
            onClick={() => {
              setOrdem(row);
              setOpen(true);
            }}
          >
            {row?.id}
          </TableCell>
          <TableCell
            style={style}
            width="10%"
            onClick={() => {
              setOrdem(row);
              setOpen(true);
            }}
          >
            {row?.sap_code}
          </TableCell>
          <TableCell
            style={style}
            width="5%"
            onClick={() => {
              setOrdem(row);
              setOpen(true);
            }}
          >
            {row?.tipo_ordem}
          </TableCell>
          <TableCell
            style={style}
            width="5%"
            onClick={() => {
              setOrdem(row);
              setOpen(true);
            }}
          >
            {row?.setor_atividade}
          </TableCell>
          <TableCell
            style={style}
            width="5%"
            onClick={() => {
              setOrdem(row);
              setOpen(true);
            }}
          >
            {row?.motivo_ordem}
          </TableCell>
          <TableCell
            style={style}
            width="5%"
            onClick={() => {
              setOrdem(row);
              setOpen(true);
            }}
          >
            {row?.centro_fornecimento}
          </TableCell>
          <TableCell
            style={style}
            width="5%"
            onClick={() => {
              setOrdem(row);
              setOpen(true);
            }}
          >
            {row?.cfop}
          </TableCell>
          <TableCell
            style={style}
            onClick={() => {
              setOrdem(row);
              setOpen(true);
            }}
          >
            {TonersService.getOrderReleaseStatus(row?.status)}
          </TableCell>
          <TableCell
            style={style}
            onClick={() => {
              setOrdem(row);
              setOpen(true);
            }}
          >
            {row?.fornecimento}
          </TableCell>
          <TableCell
            style={style}
            onClick={() => {
              setOrdem(row);
              setOpen(true);
            }}
          >
            {row?.nota_fiscal}
          </TableCell>
        </TableRow>
      ));
    }

    return (
      <TableContainer>
        <Table aria-label="Ordens De Venda">
          <TableHead>
            <TableRow>{renderHeader()}</TableRow>
          </TableHead>
          <TableBody>{renderRows()}</TableBody>
        </Table>
        <OrdemDetalhesDlg open={open} ordem={ordem} onClose={handleClose} />
      </TableContainer>
    );
  }

  return (
    <>
      {renderTable()}
      <CustomSnackbar
        results={requestResults}
        onClose={() => setRequestResults([])}
      />
    </>
  );
};

export default OrdensDeVenda;
