import { Select, TableCell } from '@material-ui/core';
import React, { useState } from 'react'
import { UserProps } from '../../SolicitacaoListProps';
import { EditableTableCellProps } from './EditableTableCellProps';
import useStyles from './style';

const EditableTableCell: React.FC<EditableTableCellProps> = (props) => {
    const [isEditing, setIsEditing] = useState(false);
    const [analista, setAnalista] = useState<UserProps | null>(props.analista)
    const [open, setOpen] = useState(true);

    const classes = useStyles();

    const handleClick = (e: any) => {
        e.stopPropagation();
        setOpen(true);
        setIsEditing(true)
    }

    const handleOnChange = (e: any) => {
        setIsEditing(false);
        let analista = null;
        const analistaId: number = e.target.value as number;
        if (analistaId >= 0) {
            const index = props.analistas.findIndex(a => a.id === analistaId)
            analista = props.analistas[index];
        }
        setAnalista(analista);
        props.onSelectAnalista(analista);
    }

    function renderSelector() {
        return (
            <Select
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                value={analista ? analista.id : -1}
                onChange={handleOnChange}
                onClick={(e: any) => {
                    e.stopPropagation();
                    setIsEditing(false);
                }}
            >
                <option className={classes.options} aria-label="None" value="-1" />
                {props.analistas.map((analista) => {
                    return <option className={classes.options}
                        key={analista.id} value={analista.id}
                    >
                        {analista.nome === '' ? analista.username : analista.nome}
                    </option>
                })}
            </Select>
        )
    }

    return (
        <TableCell onClick={handleClick}>
            {!isEditing ?
                props.children : renderSelector()}
        </TableCell>
    )
}

export default EditableTableCell;