import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import React, { ReactElement, useState, useEffect } from 'react';
import SectionTitle from '../../../components/SectionTitle';
import EnderecoEquipamentoCard from './EnderecoEquipamentoCard';

export interface IEnderecoEquipamento {
    id_equipamento?: string | number,
    bairro?: string,
    logradouro?: string,
    estado?: string,
    municipio?: string,
    ender_ins_bairro?: string,
    ender_ins_logradouro?: string,
    ender_ins_estado?: string,
    ender_ins_municipio?: string,
}

export interface CadastroEnderecoProps {
    equipamentosContrato: any[],
    estados: any[],
    onChange: (endereco: IEnderecoEquipamento) => void,
}

const CadastroEndereco = (props: CadastroEnderecoProps): ReactElement => {

    const [enderecoCards, setEnderecoCards] = useState<any[] | null>(null);
    const [listaEquipamentos, setListaEquipamentos] = useState<any[]>([]);
    const {
        equipamentosContrato = [],
        estados = [],
        onChange = (endereco: IEnderecoEquipamento) => {
            // do nothing
        }
    } = props;

    useEffect(() => {
        const refreshEquipamentos = equipamentosContrato.length !== (enderecoCards?.length || 0) + listaEquipamentos.length;
        if (refreshEquipamentos) {
            const enderecos = equipamentosContrato.map(item => {
                return {
                    atendimento: {
                        logradouro: item.logradouro,
                        bairro: item.bairro,
                        municipio: { nome: item.municipio },
                        estado: { uf: item.estado }
                    },
                    instalacao: {
                        logradouro: item.ender_ins_logradouro,
                        bairro: item.ender_ins_bairro,
                        municipio: { nome: item.ender_ins_municipio },
                        estado: { uf: item.ender_ins_estado }
                    },
                    equipamento: item.equipamento,
                    key: item.equipamento.id,
                }
            });
            const equipamentos: any[] = [];
            enderecos.forEach(item => {
                const found = equipamentosContrato.find(eq => eq.equipamento.id === item.equipamento.id);
                if (!found) {
                    equipamentos.push(found);
                }
            });
            setListaEquipamentos(equipamentos);
            setEnderecoCards(enderecos);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [equipamentosContrato]);

    /**
     * Adiciona um novo endereço da lista
     * @param e 0
     */
    const addEndereco = (e: any) => {
        const newEndereco = {
            atendimento: {
                municipio: '',
                estado: ''
            },
            instalacao: {
                municipio: '',
                estado: ''
            },
            key: Date.now()
        }
        if (enderecoCards) {
            setEnderecoCards([...enderecoCards, newEndereco]);
        } else {
            setEnderecoCards([newEndereco]);
        }
    }

    /**
     * Remove um endereço da lista de endereços
     * @param index 
     */
    const handleRemoveEndereco = (index: number) => {
        if (enderecoCards) {
            const eq = enderecoCards.splice(index, 1)[0];
            setEnderecoCards([...enderecoCards]);
            if (eq.equipamento) {
                setListaEquipamentos([...listaEquipamentos, eq.equipamento]);
            }
        }
    }

    /**
     * Trata a alteração de um valor do card de endereços
     */
    const handleChangeCardValue = (enderecoCard: any, index: number) => {
        if (enderecoCards) {
            enderecoCards[index] = { ...enderecoCard };
        }
        const { atendimento, instalacao, equipamento } = enderecoCard;
        const endereco: IEnderecoEquipamento = {
            id_equipamento: equipamento?.id,
            ...atendimento,
            estado: atendimento?.estado?.uf,
            municipio: atendimento?.municipio?.nome,
            ender_ins_bairro: instalacao?.bairro,
            ender_ins_logradouro: instalacao?.logradouro,
            ender_ins_estado: instalacao?.estado?.uf,
            ender_ins_municipio: instalacao?.municipio?.nome,
        }
        const eqpIndex = listaEquipamentos.findIndex(item => item.id === endereco.id_equipamento);
        if (eqpIndex > -1) {
            listaEquipamentos.splice(eqpIndex, 1);
            setListaEquipamentos([...listaEquipamentos]);
        }
        onChange(endereco);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <SectionTitle title="Endereço/Equipamento" subtitle />
            </Grid>
            <Grid item xs={12}>
                {enderecoCards?.map((item, index) => <EnderecoEquipamentoCard
                    key={item.key} value={item}
                    equipamentosContrato={listaEquipamentos}
                    onRemoveItem={() => handleRemoveEndereco(index)}
                    onChange={(value: any) => handleChangeCardValue(value, index)}
                    estados={estados || []} />)}
            </Grid>
            {enderecoCards?.length === 0 && (<Typography color="error">
                É necessário cadastrar pelo menos um endereço ao equipamento
            </Typography>)}
            <Grid item xs={2}>
                <Box display="flex" alignItems="center" height="100%">
                    <IconButton onClick={addEndereco} disabled={listaEquipamentos.length === 0}>
                        <AddCircleOutline />
                    </IconButton>
                </Box>
            </Grid>
        </Grid>
    )
}

export default CadastroEndereco;