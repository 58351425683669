import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, { FC, useState } from 'react';
import { useEffect } from 'react';
import { createGrupoEmpresarial } from '../../../services/BaseService';

const AddGroupDlg: FC<{
    onAdd: (group: any) => void,
    open: boolean,
    onClose: () => void,
}> = ({ onAdd, open, onClose }) => {

    const [data, setData] = useState<null | any>(null);
    const [requestResult, setRequestResult] = useState<any>(null);
    const [form, setForm] = useState<any>({})

    useEffect(() => {
        setForm({
            nome: {
                required: true,
                error: data?.nome === '',
                helperText: data?.nome ? '' : 'Campo obrigatório'
            }
        });
    }, [data])

    function saveGroup() {
        createGrupoEmpresarial(data,
            (data: any) => {
                setRequestResult({ result: true, msg: 'Grupo cadastrado com sucesso!' });
                onAdd(data);
            },
            (error: any) => {
                setRequestResult({ result: false, msg: 'Não foi possível cadastrar o grupo.' });
                const validation = { ...error.response.data };
                Object.entries(error.response.data).forEach(([k, value]) => {
                    if (Array.isArray(value)) {
                        validation[k] = { error: true, helperText: value.join(', ') };
                    }
                })
                setForm({ ...form, ...validation });
                console.error(error);
            });
    }

    const handleCloseSnackbar = () => {
        setRequestResult(null);
        if (requestResult?.result) {
            handleCloseDlg();
        }
    }

    const handleCloseDlg = () => {
        setData(null);
        onClose();
    }


    return (
        <Dialog open={open} onClose={handleCloseDlg}>
            <DialogTitle>Novo Grupo Empresarial</DialogTitle>
            <DialogContent>
                <TextField label="Nome do Grupo" variant="outlined" fullWidth
                    value={data?.nome}
                    onChange={(e) => setData({ ...data, nome: e.target.value })}
                    {...form.nome} />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="primary" style={{ textTransform: 'none' }}
                    disabled={requestResult} onClick={handleCloseDlg}>
                    Cancelar
                </Button>
                <Button variant="contained" color="primary" style={{ textTransform: 'none' }}
                    disabled={requestResult} onClick={saveGroup}>
                    Cadastrar
                </Button>
            </DialogActions>
            <Snackbar open={requestResult} autoHideDuration={3000} onClose={handleCloseSnackbar}>
                <Alert severity={requestResult?.result ? 'success' : 'error'} onClose={handleCloseSnackbar}>
                    {requestResult?.msg}
                </Alert>
            </Snackbar>
        </Dialog>
    )
}

export default AddGroupDlg;