import React, { useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import { FilterListProps } from './FilterListProps'
import useStyles from './style'
import FilterChip from '../FilterChip'

/**
 * Barra de filtros usados para filtrar uma lista
 * @param props
 */
const FilterList: React.FC<FilterListProps> = (props) => {

    const [selectedFilters, setSelectedFilters] = useState(props.selectedFilters);
    const [allFilters] = useState(props.allFilters ? props.allFilters : new Map());
    // const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const classes = useStyles()

    useEffect(() => {
        setSelectedFilters(props.selectedFilters)
    }, [props.selectedFilters])

    /**
     * Retorna um FilterChp da lista de selectedFilters
     * @param chipLabel label do FilterChip
     */
    function getFilterFromSelectedFilters(chipLabel: string) {
        return selectedFilters.filter((item) => item.label === chipLabel)[0];
    }

    /**
     * Trata o evento de remoção do filtro e dispara callback
     *  de alteração nos filtros
     * @param chipLabel
     */
    const handleDelete = (chipLabel: string) => {
        const chip = getFilterFromSelectedFilters(chipLabel);
        if (chip.onDelete) {
            const filteredList = selectedFilters.filter(item => item !== chip);
            setSelectedFilters(filteredList);
            allFilters.get(chip.category).push(chip);
            chip.onDelete(chip.label);
        }
    }

    /**
     * Trata o evento de mudança de visibilidade do filtro e
     * dispara callback de alteração nos filtros
     * @param chipLabel
     * @param value valor atual de visibilidade
     */
    const handleVisibility = (chipLabel: string, value: boolean) => {
        const chip = getFilterFromSelectedFilters(chipLabel);
        chip.visibility = value;
        if (chip.onChangeVisibility) {
            chip.onChangeVisibility(chipLabel, value);
        }
        if (props.onChangeSelectedFilters) {
            props.onChangeSelectedFilters(selectedFilters);
        }
    }

    /**
     * Trata o evento de mudança de valor do filtro e
     * dispara callback de alteração nos filtros
     * @param chipLabel label do filtro
     * @param value valor atual do filtro
     */
    const handleChangeValue = (chipLabel: string, value: any) => {
        const chip = getFilterFromSelectedFilters(chipLabel);
        chip.value = value;
        if (chip.onChangeValue) {
            chip.onChangeValue(chipLabel, value);
        }
        if (props.onChangeSelectedFilters) {
            props.onChangeSelectedFilters(selectedFilters);
        }
    }

    /**
     * Renderiza os chips na lista de chips
     */
    const renderSelectFilters = selectedFilters.map((chip) => {
        return <li key={chip.label} className={classes.chip}>
            <FilterChip
                visibility={chip.visibility}
                label={chip.label}
                value={chip.value}
                type={chip.type}
                options={chip.options}
                onChangeValue={handleChangeValue}
                onChangeVisibility={handleVisibility}
                onDelete={chip.onDelete ? handleDelete : undefined}
            />
        </li>
    })

    /**
     * Seleciona um filtro para a lista de filtros selecionados e remove da
     * lista de todos os filtros
     * @param filter - filtro selecionado
     * @param category - categoria do filtro
     */
    // function selectFilter(filter: FilterChipProps, category: string) {
    //     const filters: Array<FilterChipProps> = allFilters.get(category);
    //     filters.splice(filters.indexOf(filter), 1);
    //     selectedFilters.push(filter);
    //     handleClose();
    // }

    /**
     * Renderiza as categorias e seus respectivos filtros
     */
    // function renderFilterCategory(): ReactNode {
    //     const categories: Array<string> = Array.from(allFilters.keys());
    //     const list = categories.map((category) => {
    //         return (
    //             <Box m={1} key={category}
    //                 display="flex" flexDirection="column"
    //                 justifyContent="start">
    //                 <Typography align="center" variant="h6">
    //                     {category}
    //                 </Typography>
    //                 <Box component="ul" className={classes.filterMenu}>
    //                     {allFilters.get(category).map((chip: FilterChipProps) => {
    //                         return (
    //                             <li key={chip.label} className={classes.chip}>
    //                                 <Chip label={chip.label} onClick={
    //                                     () => selectFilter(chip, category)
    //                                 } />
    //                             </li>
    //                         )
    //                     })}
    //                 </Box>
    //             </Box>
    //         )
    //     })
    //     return list;
    // }

    // const open = Boolean(anchorEl);
    // const id = open ? 'simple-popover' : undefined;

    // const handleClose = () => {
    //     setAnchorEl(null);
    // };

    // const handleAddFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    //     setAnchorEl(event.currentTarget);
    // };

    return (
        <Box display="flex" justifyContent="flex-start">
            <Box component="ul" className={classes.root}>
                {renderSelectFilters}
            </Box>

            {/*
            <Box flexGrow="1">
                <IconButton size="medium" aria-label="Adicionar Filtro"
                    onClick={handleAddFilter}>
                    <AddCircle style={{ color: "black" }} />
                </IconButton>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Box p={2}>
                        <Typography variant="body1">
                            Selecione o filtro conforme a categoria disponível:
                        </Typography>
                        <Box component="ul" className={classes.filterCategory}>
                            {renderFilterCategory()}
                        </Box>
                    </Box>
                </Popover>
            </Box>
            */}

        </Box>
    )
}

export default FilterList
