import { Box, IconButton, makeStyles, Paper, TextField } from '@material-ui/core';
import { RemoveCircleOutline } from '@material-ui/icons';
import React, { FC, useEffect, useState } from 'react';
import CustomSelect from '../../../components/CustomSelect';
import { Contato } from '../models';
import MaskedInput from 'react-text-mask';
import * as mask from '../../../components/Masks';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginBottom: theme.spacing(1),
        }
    },
}));


const ContatoCard: FC<{
    contato: Contato
    removeItem: (e: any) => void,
    onChange: (data: any) => void,
}> = ({ contato, removeItem, onChange }) => {
    const classes = useStyles();
    const { tipo = 'Comercial', nome, telefone, email, id } = contato;
    const [data, setData] = useState<Contato>({
        id: id, nome: nome, tipo: tipo, telefone: telefone, email: email
    });
    const tiposContato = new Map([
        ['COMERCIAL', 'Comercial'],
        ['OPERACIONAL', 'Operacional']
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { onChange(data) }, [data]);

    return (
        <Paper>
            <Box textAlign="right">
                <IconButton onClick={removeItem}>
                    <RemoveCircleOutline />
                </IconButton>
            </Box>
            <Box p={4} mt={-5} className={classes.root}>
                <CustomSelect label="Tipo de Contato" 
                    value={tiposContato.get(data.tipo.toUpperCase())}
                    options={Array.from(tiposContato.values())}
                    onChange={(value) => { setData({ ...data, tipo: value }) }} />
                <TextField label="Nome"
                    fullWidth value={data.nome}
                    onChange={(e) => { setData({ ...data, nome: e.target.value }) }} />
                <MaskedInput
                    mask={mask.phone} value={data.telefone}
                    onChange={(e) => { setData({ ...data, telefone: e.target.value.replace(/\D+/g, '') }) }}
                    render={(ref, props) => <TextField inputRef={ref} {...props} label="Telefone" fullWidth />}/>
                <TextField label="E-mail"
                    fullWidth value={data.email}
                    onChange={(e) => { setData({ ...data, email: e.target.value }) }} InputProps={{ type: 'email' }}/>
            </Box>
        </Paper>
    )
}

export default ContatoCard;