import React, { useState } from 'react'
import { Box, Input, Typography } from '@material-ui/core';
import { FilterInputTextProps } from './FilterInputTextProps';
import ClickAwayListener from 'material-ui/internal/ClickAwayListener';


const FilterInputText: React.FC<FilterInputTextProps> = (props) => {

    const [value, setValue] = useState(props.value);

    function handleChange(e: any) {
        setValue(e.target.value);
    }

    function endEdit() {
        if (value !== undefined) {
            props.onChangeValue(props.label, value);
        }
    }

    function handleEnterKey(e:any) {
        if (e.key === 'Enter') {
            endEdit();
        }
    }

    const handleClickAway = () => {
        endEdit();
    }

    return (
        <Box>
            <Typography variant="caption" color="primary">
                {`${props.label}: `}
            </Typography>
            <ClickAwayListener onClickAway={handleClickAway}>
                <Input id={props.label + 'Input'} value={value} type={props.type} color="primary"
                    style={{width: 70, fontSize: 'inherit'}}
                    onBlur={endEdit} onChange={handleChange}
                    onKeyPress={handleEnterKey}
                    autoFocus/>
            </ClickAwayListener>
        </Box>
    );

}

export default FilterInputText;