/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, CircularProgress, Dialog, DialogTitle, Grid, IconButton, makeStyles, Paper, TextField } from '@material-ui/core';
import { RemoveCircleOutline } from '@material-ui/icons';
import React, { FC, useEffect, useState } from 'react';
import CustomRadioGroup from '../../../components/CustomRadioGroup';
import CustomSelect from '../../../components/CustomSelect';
import { fetchCidades } from '../../../services/BaseService';
import { fetchClients } from '../../Client/services';
import MaskedInput from 'react-text-mask';
import * as mask from '../../../components/Masks';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginBottom: theme.spacing(1),
        }
    },
}));

const ClientCard: FC<{
    onChange: (client: any) => void,
    onRemoveItem?: (e: any) => void,
    client?: any,
    estados: any[]
}> = ({ onChange, onRemoveItem, client, estados }) => {
    const classes = useStyles();
    const [data, setData] = useState<any>(client);
    const [municipios, setMunicipios] = useState<any[] | null>(null);
    const [isSearching, setIsSearching] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const contextosEmpresarial = new Map([
        ['MATR', 'Matriz'],
        ['FILI', 'Filial']
    ]);


    useEffect(() => { onChange(data) }, [data, onChange]);

    useEffect(() => {
        if (client) {
            setData({ ...client });
        }
    }, [client])

    /** Carregas as cidades */
    useEffect(() => {
        if (data?.estado) {
            fetchCidades(data?.estado.uf, (result: any[]) => {
                setMunicipios(result);
            }, (error: any) => {
                setMunicipios([]);
                console.error(error);
            })
        }
    }, [data?.estado]);

    function getSelectedItem(list: any[] | null, value: string) {
        let item = null;
        if (list) {
            const index = list?.findIndex(item => item.nome === value);
            if (index > -1) {
                item = list[index];
            }
        }
        return item;
    }

    function handleChangeEstado(estado: string) {
        setData({ ...data, municipio: null });
        const item = getSelectedItem(estados, estado)
        if (item) {
            setData({ ...data, estado: item });
        }
    }

    function handleChangeCidade(municipio: string) {
        const item = getSelectedItem(municipios, municipio)
        if (item) {
            setData({ ...data, municipio: item });
        }
    }

    function findEstado(uf: string) {
        let estado = undefined;
        const index = estados.findIndex(item => item.uf === uf);
        if (index > -1) {
            estado = estados[index];
        }
        return estado;
    }

    function searchClient(e: any) {
        setIsSearching(true);
        setNotFound(false);

        fetchClients({ cnpj_cpf: e.target.value.replace(/\D+/g, '') }, 1,
            (data: any) => {
                setTimeout(() => {
                    setIsSearching(false);
                }, 1000);
                if (data.count === 1) {
                    const cliente = data.results[0];

                    setData({
                        ...cliente,
                        estado: findEstado(cliente.estado),
                        municipio: { nome: cliente.municipio }
                    });
                } else {
                    setData({
                        id: null,
                        cnpj_cpf: e.target.value,
                        tipo_razao_social: '',
                        nome: '',
                        logradouro: '',
                        bairro: '',
                        estado: null,
                        municipio: null,
                    });
                    setNotFound(true);
                }
            },
            (error: any) => {
                console.error(error);
                setTimeout(() => {
                    setIsSearching(false);
                }, 1000);
            });
    }

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            searchClient(e);
        }
    }



    return (
        <Paper>
            <Dialog onClose={() => setIsSearching(false)} open={isSearching}>
                <Box textAlign="center" m={4}>
                    <Box mb={2}>
                        <b>Localizando dados do Cliente</b>
                        <br /><br />
                        Por favor aguarde ...
                    </Box>
                    <CircularProgress />
                </Box>
            </Dialog>

            <Box textAlign="right">
                <IconButton onClick={onRemoveItem}>
                    <RemoveCircleOutline />
                </IconButton>
            </Box>
            <Box p={4} mt={-5} className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item xs={5}>
                        <MaskedInput
                            mask={mask.cnpj_cpf} value={data?.cnpj_cpf || ''}
                            onChange={(e) => setData({ ...data, cnpj_cpf: e.target.value.replace(/\D+/g, '') })}
                            guide={false}
                            render={(ref, props) => ( <TextField label="CPF/CNPJ" inputRef={ref} {...props} fullWidth
                                onBlur={searchClient} onKeyDown={handleKeyDown} InputLabelProps={{ shrink: data?.cnpj_cpf !== undefined }}
                                helperText={notFound ? 'Cliente não encontrado' : ''} error={notFound}/>)}/>
                    </Grid>
                    <Grid item xs={7}>
                        <CustomRadioGroup label="Contexto Empresarial"
                            disabled
                            value={
                                data?.tipo_razao_social ?
                                    contextosEmpresarial.get(data?.tipo_razao_social) :
                                    contextosEmpresarial.get('MATR')
                            }
                            options={Array.from(contextosEmpresarial.values())}
                            onChange={(value) => { setData({ ...data, tipo_razao_social: value }) }} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Razão Social" value={data?.nome || ''} fullWidth
                            disabled
                            onChange={(e) => setData({ ...data, nome: e.target.value })}
                            InputLabelProps={{ shrink: data?.nome !== undefined }} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Logradouro" value={data?.logradouro || ''} fullWidth
                            disabled
                            onChange={(e) => setData({ ...data, logradouro: e.target.value })}
                            InputLabelProps={{ shrink: data?.logradouro !== undefined }} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Bairro" value={data?.bairro || ''} fullWidth
                            disabled
                            onChange={(e) => setData({ ...data, bairro: e.target.value })}
                            InputLabelProps={{ shrink: data?.bairro !== undefined }} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CustomSelect label="Estado" value={data?.estado?.nome || ''}
                            disabled
                            options={estados?.map(item => item.nome) || []}
                            onChange={handleChangeEstado} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CustomSelect label="Cidade" value={data?.municipio?.nome || ''}
                            options={municipios?.map(item => item.nome) || []}
                            disabled
                            onChange={handleChangeCidade} />
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    )
}

export default ClientCard;
