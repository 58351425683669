import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';

const CustomSelect: FC<{
    label?: string,
    value?: string,
    options: string[],
    required?: boolean,
    error?: boolean,
    helperText?: string,
    disabled?: boolean,
    margin?: boolean,
    onChange: (value: string) => void
}> = ({ label, options, required, error, helperText, onChange, disabled, margin, ...props }) => {

    const [value, setValue] = useState(props.value || '');

    const handleChange = (e: any) => {
        setValue(e.target.value);
        onChange(e.target.value);
    }

    const [onFocus, setOnFocus] = useState(props.value && props.value !== '');

    useEffect(() => {
      setValue(props.value || '');
    }, [props.value])

    return (
        <FormControl fullWidth variant="outlined" required={required} error={error} style={{marginTop: margin ? '0' : '12px'}}>
            <InputLabel id="custom-select-label" style={onFocus ? {top: '0'} : {top: '-6px'}}>{label}</InputLabel>
            <Select                
                labelId="custom-select-label"
                value={value}
                onChange={handleChange}
                label={label}
                margin={margin ? undefined : 'dense'}
                disabled={disabled}
                onFocus={() => setOnFocus(!onFocus && value !== '')}
            >
                {options.map(option => <MenuItem key={option} value={option}>{option}</MenuItem>)}
            </Select>
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    )
}

export default CustomSelect;