import React, { FC, useEffect, useState, useCallback } from 'react';
import {
  Container,
  Box,
  Button,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import SDHeader from '../../components/SDHeader';
import { DataGrid, GridColDef } from '@material-ui/data-grid';
import { Cliente } from './models';
import { fetchClients } from './services';
import { useHistory } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';

const ClientList: FC = (props) => {
  const [clients, setClients] = useState<Cliente[] | null>(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState<any>({});
  const history = useHistory();

  const columns: GridColDef[] = [
    {
      field: 'cod_sap',
      headerName: 'SAP',
    },
    {
      field: 'nome',
      headerName: 'Nome',
      width: 400,
    },
    {
      field: 'cnpj_cpf',
      headerName: 'CPF/CNPJ',
      width: 200,
    },
    {
      field: 'tipo_cliente',
      headerName: 'Tipo do Cliente',
      width: 200,
    },
  ];

  const searchCliente = useCallback(() => {
    let params = {};
    if (filter?.cnpj_cpf) {
      params = {
        cnpj_cpf: filter?.cnpj_cpf,
      };
    } else if (filter?.cod_sap) {
      params = {
        cod_sap: filter?.cod_sap,
      };
    }
    console.log(params);
    fetchClients(
      params,
      page,
      (data: any) => {
        setClients(data.results);
        setTotal(data.count);
      },
      (err: any) => {
        console.error(err);
      }
    );
  }, [filter, page]);

  useEffect(() => {
    searchCliente();
  }, [page, searchCliente]);

  return (
    <Container>
      <Box display="flex">
        <SDHeader subtitle="Relatórios" title="Clientes Cadastrados" />
        <Box flexGrow={1} display="flex" justifyContent="flex-end" mt={3}>
          <Box width="60%">
            <TextField
              variant="outlined"
              placeholder="Buscar por CNPJ ou SAP"
              margin="dense"
              fullWidth
              onChange={(e) => {
                if (e.target.value.length >= 11) {
                  setFilter({ ...filter, cnpj_cpf: e.target.value });
                } else {
                  setFilter({
                    ...filter,
                    cod_sap: e.target.value,
                    cnpj_cpf: null,
                  });
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon color="disabled" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      </Box>

      <Box textAlign="left" my={3}>
        <Button
          onClick={() => history.push('/cliente/novo')}
          variant="contained"
          color="primary"
          style={{ textTransform: 'none' }}
        >
          Novo Cliente
        </Button>
      </Box>
      <Box height="80vh" marginTop={3}>
        <DataGrid
          rows={clients || []}
          columns={columns}
          pageSize={15}
          onPageChange={(param) => setPage(param.page + 1)} //API retorna iniciando da pagina 1, porém o Grid inicia na pagina 0, por isso page + 1
          rowCount={total}
          paginationMode="server"
          disableColumnMenu
          onRowClick={(params) => history.push(`/cliente/${params.id}`)}
        />
      </Box>
    </Container>
  );
};

export default ClientList;
