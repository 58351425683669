import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { ptBR } from '@material-ui/core/locale';
import { ptBR as gridPtBR } from '@material-ui/data-grid';
import './App.css';
import Main from '../../domain/Main';

import { SnackbarProvider } from 'notistack';

const theme = createTheme(
  {
    palette: {
      primary: {
        light: '#5472d3',
        main: '#0d47a1',
        dark: '#002171',
        contrastText: '#ffffff',
      },
      secondary: {
        light: '#e6ffff',
        main: '#f15a23',
        dark: '#82b3c9',
        contrastText: '#000000',
      },
    },
    overrides: {
      MuiTypography: {
        h1: {
          fontSize: '2rem',
        },
        h2: {
          fontSize: '1.5rem',
        },
      },
    },
  },
  ptBR,
  gridPtBR
);

theme.typography.h3 = {
  fontSize: '0.5rem',
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <SnackbarProvider maxSnack={5}>
          <Router>
            <Main />
          </Router>
        </SnackbarProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;
