import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        padding: '0 32px',
        height: '90vh',
        width: '40vw',
        margin: '5vh auto'
    },
    avatar: {
        backgroundColor: '#2b81c3'
    },
    formField: {
        marginBottom: 20,
    },
    loginBtn: {
        margin: '8px 0',
    }
}))

export default useStyles