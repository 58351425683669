import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import React, { FC } from 'react';
import FormVeiculo from '../../Veiculo/components/FormVeiculo';

const AddVeiculoDlg: FC<{
    onAdd: () => void,
    open: boolean,
    onClose: () => void,
}> = ({ onAdd, open, onClose }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    Cadastrar Veículo
                    {onClose ? (
                        <IconButton onClick={onClose}>
                            <CloseRounded />
                        </IconButton>
                    ) : null}
                </Box>
            </DialogTitle>
            <DialogContent>
                <FormVeiculo onSubmit={onAdd} />
            </DialogContent>
        </Dialog>
    )
}

export default AddVeiculoDlg;