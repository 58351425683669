/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Container, Dialog, DialogContent, Divider, Grid, IconButton, InputAdornment, InputBase, makeStyles, Snackbar, TextField, Typography } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import { validate } from '@material-ui/pickers';
import { format, setDate } from 'date-fns';
import React, { FC, ReactElement, useCallback, useDebugValue, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import BoxTitle from '../../components/BoxTitle';
import CustomDatePicker from '../../components/CustomDatePicker';
import CustomRadioGroup from '../../components/CustomRadioGroup';
import CustomSelect from '../../components/CustomSelect';
import EquipmentSelector from '../../components/EquipmentSelector';
import SDHeader from '../../components/SDHeader';
import SectionTitle from '../../components/SectionTitle';
import TimeInput from '../../components/TimeInput';
import NumberFormatCustom from '../../components/NumberFormatCustom';
import { fetchEstados, fetchModoContabilizacao, fetchStatusContratual, fetchTipoContrato } from '../../services/BaseService';
import ClientCard from './components/ClientCard';
import EnderecoEquipamentoCard from './components/EnderecoEquipamentoCard';
import EquipmentTable from './components/EquipmentTable';
import { addEquipamento, createContrato, getContrato, getEquipamentosContrato, saveEquipmentDetails, updateContrato } from './services';
import CadastroEndereco, { IEnderecoEquipamento } from './components/CadastroEndereco';
import NumberFormat from 'react-number-format';

//Styles para remoção das arrows nos numbers inputs
const useStyles = makeStyles({
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
});

const Contract: FC = (props) => {

    const [data, setData] = useState<any | null>({ tipo_atendimento: 'Avulso' });
    const [editEquipments, setEditEquipments] = useState(false);
    const [equipmentosContrato, setEquipmentosContrato] = useState<any[] | null>(null);
    const [clientes, setClientes] = useState<any[]>([{}]);
    const [requestLog, setRequestLog] = useState<any[]>([]);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [form, setForm] = useState<any>({});
    const { id } = useParams<{ id?: string }>();
    const history = useHistory();
    const classes = useStyles();

    useEffect(() => {
        if (id) {
            getContrato(Number(id),
                (data: any) => {
                    const { clientes } = data;
                    setClientes(clientes);
                    setData(data);
                },
                (error: any) => { console.error(error); })
        }
    }, [id]);


    useEffect(() => {
        setForm({
            numero: {
                required: data.tipo_atendimento.toUpperCase() === 'CONTRATO',
                error: data?.numero === '',
                helperText: data?.numero === '' ? 'Campo obrigatório' : ''
            },
            parcelas: {
                disabled: id,
                required: true,
                error: data?.parcelas === '',
                helperText: data?.parcelas === '' ? 'Campo obrigatório' : ''
            },
            data_inicio: {
                required: true,
                error: data?.data_inicio === '',
                helperText: data?.data_inicio === '' ? 'Campo obrigatório' : ''
            },
            data_fim: {
                required: true,
                error: data?.data_fim === '',
                helperText: data?.data_fim === '' ? 'Campo obrigatório' : ''
            },
            valor: {
                required: data.tipo_atendimento.toUpperCase() === 'CONTRATO',
                error: data?.valor === '',
                helperText: data?.valor === '' ? 'Campo obrigatório' : ''
            },
            tipo_atendimento: {
                required: true,
                error: data?.tipo_atendimento === '',
                helperText: data?.tipo_atendimento === '' ? 'Campo obrigatório' : ''
            },
            tipo_contrato: {
                required: true,
                error: data?.tipo_contrato === '',
                helperText: data?.tipo_contrato === '' ? 'Campo obrigatório' : ''
            },
        });
    }, [data]);



    function DadosContrato(): ReactElement {
        const [tiposContrato, setTiposContrato] = useState<any[] | null>(null);
        const [statusContratualList, setStatusContratualList] = useState<any[] | null>(null);

        /** Carrega os tipos de contratos */
        useEffect(() => {
            if (tiposContrato === null) {
                fetchTipoContrato(
                    (data: any) => {
                        setTiposContrato(data);
                    },
                    (error: any) => {
                        setTiposContrato([]);
                        console.error(error);
                    }
                )
            }
        }, [tiposContrato]);

        /** Carrega a lista de status contratual */
        useEffect(() => {
            if (statusContratualList === null) {
                fetchStatusContratual(
                    (data: any) => {
                        setStatusContratualList(data);
                    },
                    (error: any) => {
                        setStatusContratualList([]);
                        console.error(error);
                    }
                )
            }
        }, [statusContratualList]);

        const getTipoContrato = useCallback((key: string) => {
            let tipo = undefined;
            if (tiposContrato) {
                const index = tiposContrato.findIndex(item => item.key === key);
                if (index > -1) {
                    tipo = tiposContrato[index];
                }
            }
            return tipo;
        }, [tiposContrato]);

        const getStatusContratual = useCallback((key: string) => {
            let tipo = undefined;
            if (statusContratualList) {
                const index = statusContratualList.findIndex(item => item.key === key);
                if (index > -1) {
                    tipo = statusContratualList[index];
                }
            }
            return tipo;
        }, [statusContratualList]);


        const handleTipoContrato = (value: string) => {
            if (tiposContrato !== null) {
                const index = tiposContrato.findIndex(item => item.value === value);
                if (index > -1) {
                    setData({ ...data, tipo_contrato: tiposContrato[index] });
                }
            }
        }

        const handleStatusContratual = (value: string) => {
            if (statusContratualList !== null) {
                const index = statusContratualList.findIndex(item => item.value === value);
                if (index > -1) {
                    setData({ ...data, status_contratual: statusContratualList[index] });
                }
            }
        }

        /** Altera a data final do contrato conforme parcelas */

        const handleParcelas = (installments: string) => {
            const parcelas = Number(installments);
            let data_fim = data?.data_inicio ? new Date(data?.data_inicio) : null;

            if (data_fim) {
                data_fim.setMonth(data_fim.getMonth() + parcelas);
                setData({ ...data, parcelas: installments, data_fim });
            } else {
                setData({ ...data, parcelas: installments });
            }

        }

        const handleDataContrato = (field: string, date: Date | null) => {
            if (date) {
                let isValid = false;
                let msg = undefined;

                switch (field) {
                    case 'data_inicio':
                        isValid = data?.data_fim ? (date < data.data_fim) : true;
                        msg = !isValid ? 'A data de Início não pode ser maior que a data de término.' : undefined;
                        break;
                    case 'data_fim':
                        if (data?.parcelas && data?.data_inicio) {
                            let endDate = new Date(data.data_inicio);
                            let predictedEnd = new Date(endDate.setMonth(endDate.getMonth() + Number(data.parcelas)));
                            isValid = (date >= predictedEnd);
                            msg = !isValid ? 'A data de Término não pode ser menor que o tempo total de contrato.' : undefined
                        } else if (data?.parcelas) {
                            data.data_inicio = new Date(date.setMonth(date.getMonth() - data.parcelas));
                            isValid = true;
                        } else {
                            isValid = date > data?.data_inicio;
                            msg = !isValid ? 'A data de Término não pode ser menor que a data de início.' : undefined;
                        }
                        break;
                    default:
                        break;
                }

                if (isValid) {
                    data[field] = date;
                    let endDate = data?.data_inicio ? new Date(data.data_inicio) : date;
                    data.data_fim = new Date(endDate.setMonth(endDate.getMonth() + Number(data.parcelas)));
                    setData({ ...data });
                } else {
                    const newForm = { ...form };
                    newForm[field] = {
                        error: true,
                        helperText: msg,
                        value: date
                    }
                    setForm(newForm);
                }
            }
        }

        const tiposAtendimentos = new Map([
            ['AVULSO', 'Avulso'],
            ['CONTRATO', 'Contrato']
        ])

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SectionTitle title="Dados do Contrato" subtitle />
                </Grid>
                <Grid item sm={12} md={4}>
                    <CustomRadioGroup label="Tipo de Atendimento"
                        options={Array.from(tiposAtendimentos.values())}
                        value={tiposAtendimentos.get(data?.tipo_atendimento?.toUpperCase())}
                        onChange={(value) => setData({ ...data, tipo_atendimento: value })}
                        {...form.tipo_atendimento} />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <CustomSelect label="Tipo de Contrato"
                        value={typeof (data?.tipo_contrato) === 'string' ?
                            getTipoContrato(data?.tipo_contrato)?.value :
                            data?.tipo_contrato?.value}
                        options={tiposContrato?.map(item => item.value) || []}
                        onChange={handleTipoContrato} required
                        {...form.tipo_contrato} />
                </Grid>
                <Grid item xs={4}>
                    <CustomSelect label="Status Contratual"
                        value={typeof (data?.status_contratual) === 'string' ?
                            getStatusContratual(data?.status_contratual)?.value
                            : data?.status_contratual?.value}
                        options={statusContratualList?.map(item => item.value) || []}
                        onChange={handleStatusContratual}
                        {...form.status_contratual} />
                </Grid>
                <Grid item xs={12}>
                    <BoxTitle title="Detalhes do Contrato">
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs hidden={data.tipo_atendimento.toUpperCase() === 'AVULSO'}>
                                <TextField InputLabelProps={{ shrink: data?.numero !== undefined }}
                                    label="Nº do Contrato" value={data?.numero || ''} fullWidth
                                    onChange={(e) => setData({ ...data, numero: e.target.value })}
                                    {...form.numero} />

                            </Grid>
                            <Grid item xs>
                                <CustomSelect label="Parcelas" value={data?.parcelas}
                                    options={['12', '24', '36', '48', '60']}
                                    onChange={(value) => handleParcelas(value)}
                                    {...form.parcelas} required />

                            </Grid>
                            <Grid item xs>
                                <CustomDatePicker label="Início Contratual"
                                    value={data?.data_inicio ? new Date(data?.data_inicio) : null}
                                    onChange={(value) => handleDataContrato('data_inicio', value)}
                                    {...form.data_inicio} />
                            </Grid>
                            <Grid item xs>
                                <CustomDatePicker label="Fim Contratual"
                                    value={data?.data_fim ? new Date(data?.data_fim) : null}
                                    onChange={(value) => handleDataContrato('data_fim', value)}
                                    {...form.data_fim} />
                            </Grid>
                            <Grid item xs hidden={data.tipo_atendimento.toUpperCase() === 'CONTRATO'}>
                                <TextField InputLabelProps={{ shrink: data?.valor !== undefined }}
                                    label="Valor Nominal" value={data?.valor || ''} fullWidth
                                    onChange={(e) => setData({ ...data, valor: e.target.value })}
                                    {...form.valor} required InputProps={{ inputComponent: NumberFormatCustom as any, }} margin='dense' />
                            </Grid>
                        </Grid>
                    </BoxTitle>
                </Grid>
                <Grid item xs={12}>
                    <TextField InputLabelProps={{ shrink: data?.notas_faturamento !== undefined }}
                        label="Instruções de Faturamento"
                        value={data?.notas_faturamento || ''} fullWidth
                        multiline rows={5} variant="outlined"
                        onChange={(e) => setData({ ...data, notas_faturamento: e.target.value })}
                        {...form.notas_faturamento} />
                </Grid>
            </Grid>
        );
    }

    const [estados, setEstados] = useState<any[] | null>(null);

    /** Carrega os estados */
    useEffect(() => {
        if (estados === null) {
            fetchEstados((result: any[]) => {
                setEstados([...result]);
            }, (error: any) => {
                setEstados([]);
                console.error(error);
            })
        }
    }, [estados]);

    const SelecaoCliente = () => {

        const addCliente = (e: any) => {
            const list = [...clientes, { tipo_razao_social: 'Matriz' }];
            setClientes(list);
            setData({ ...data, clientes: list })
        }

        const handleRemoveCliente = (index: number) => {
            clientes?.splice(index, 1);
            setClientes([...clientes]);
        }

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SectionTitle title="Seleção de Cliente" subtitle />
                </Grid>
                {clientes?.map((cliente: any, index: number) =>
                    <Grid item xs={12} sm={6} md={4} key={cliente.nome + index}>
                        <ClientCard client={cliente}
                            onRemoveItem={() => handleRemoveCliente(index)}
                            onChange={(data) => clientes[index] = data}
                            estados={estados || []} />
                    </Grid>
                )}
                {clientes.length === 0 && (<Typography color="error">
                    É necessário cadastrar pelo menos um cliente
                </Typography>)}
                <Grid item xs={2}>
                    <Box display="flex" alignItems="center" height="100%">
                        <IconButton onClick={addCliente}>
                            <AddCircleOutline />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
        );
    }

    const SelecaoEquipamentos = () => {

        /** Carrega os equipamentos de um contrato */
        useEffect(() => {
            if (equipmentosContrato === null && data?.id) {
                getEquipamentosContrato(data.id,
                    (data: any) => {
                        setEquipmentosContrato(data)
                    },
                    (error: any) => console.error(error)
                );
            }
        }, [equipmentosContrato, data?.id])

        const handleClose = (equipamentos: any[]) => {
            const fakeIndex = 999; // Para poder rendereizar novos equipamentos na datagrid
            const newEquips = equipamentos.map((item, index) => {
                return {
                    id: index + fakeIndex,
                    equipamento: { id: item.id, serie: item.serie, modelo: item.modelo },
                    contrato: data?.id
                }
            });
            if (equipmentosContrato) {
                const newItems = newEquips.filter(item => equipmentosContrato?.findIndex(
                    ec => ec.equipamento.id === item.equipamento.id
                ) === -1);
                if (newItems.length > 0) {
                    setEquipmentosContrato([...equipmentosContrato, ...newItems]);
                }
            } else {
                setEquipmentosContrato([...newEquips]);
            }
            setEditEquipments(false);
        }

        const handleValueChange = (value: any) => {
            if (equipmentosContrato) {
                const { equipamento, ...newValue } = value;
                const index = equipmentosContrato.findIndex(item => item.equipamento.id === equipamento.id);
                if (index > -1) {
                    const ec = equipmentosContrato[index];
                    equipmentosContrato[index] = { ...ec, ...newValue };
                    setEquipmentosContrato([...equipmentosContrato]);
                }
            }
        }

        return (
            <Grid container spacing={2} id="selecaoEquipamento">
                <Grid item xs={12}>
                    <SectionTitle title="Seleção de Equipamento(s)" subtitle />
                </Grid>
                <Grid item xs={4}>
                    <Button variant="outlined" color="primary"
                        style={{ textTransform: 'none' }} fullWidth
                        onClick={() => setEditEquipments(true)}>
                        Editar Seleção
                    </Button>
                    <EquipmentSelector open={editEquipments}
                        onClose={handleClose}
                        selectedEquipments={equipmentosContrato?.map(item => {
                            return { id: item.equipamento?.id, serie: item.equipamento?.serie, modelo: item.equipamento?.modelo }
                        }) || []} />
                </Grid>
                <Grid item xs={12}>
                    <BoxTitle title="Equipamentos Selecionados">
                        {
                            equipmentosContrato ? <EquipmentTable clientes={clientes}
                                tipo={data?.tipo_atendimento.toUpperCase() || 'AVULSO'}
                                equipments={equipmentosContrato}
                                onChangeValue={handleValueChange}
                            /> :
                                <Typography color="error" align="center">
                                    Não existe equipamento selecionado
                                </Typography>}
                    </BoxTitle>
                </Grid>
            </Grid>
        );
    }

    const DadosContabilizacao = (hide: boolean = false) => {
        const [modosContabilizacao, setModosContabilizacao] = useState<any[] | null>(null);

        /** Carrega os modos de contabilização */
        useEffect(() => {
            if (modosContabilizacao === null) {
                fetchModoContabilizacao(
                    (data: any) => {
                        setModosContabilizacao(data);
                    },
                    (error: any) => {
                        setModosContabilizacao([]);
                        console.error(error);
                    }
                )
            }
        }, [modosContabilizacao]);

        const getModo = useCallback((key: string) => {
            let tipo = undefined;
            if (modosContabilizacao) {
                const index = modosContabilizacao.findIndex(item => item.key === key);
                if (index > -1) {
                    tipo = modosContabilizacao[index];
                }
            }
            return tipo;
        }, [modosContabilizacao]);

        const handleModoContabilizacao = (value: string) => {
            if (modosContabilizacao !== null) {
                const index = modosContabilizacao.findIndex(item => item.value === value);
                if (index > -1) {
                    setData({ ...data, modo_contabilizacao: modosContabilizacao[index] });
                }
            }
        }

        const options = Array.from({ length: 31 }, (_, i) => i + 1)

        return !hide && (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SectionTitle title="Dados de Contabilização" subtitle />
                </Grid>
                <Grid item xs={4}>
                    <CustomSelect label="Módulo de contabilização"
                        value={typeof (data?.modo_contabilizacao) === 'string' ?
                            getModo(data?.modo_contabilizacao).value
                            : data?.modo_contabilizacao?.value}
                        options={modosContabilizacao?.map(item => item.value) || []}
                        onChange={handleModoContabilizacao}
                        {...form.modo_contabilizacao} />
                </Grid>
                <Grid item xs={4}>
                    <CustomSelect label="Data de coleta" value={data?.dia_coleta_contador}
                        options={options}
                        onChange={(value) => setData({ ...data, dia_coleta_contador: value })}
                        {...form.dia_coleta_contador} />
                </Grid>
                <Grid item xs={12}>
                    <TextField InputLabelProps={{ shrink: data?.notas_contabilizacao !== undefined }}
                        label="Instruções de Contabilização"
                        value={data?.notas_contabilizacao || ''} fullWidth
                        multiline rows={5} variant="outlined"
                        onChange={(e) => setData({ ...data, notas_contabilizacao: e.target.value })}
                        {...form.notas_contabilizacao} />
                </Grid>
            </Grid>
        )
    }

    const SLA = (hide: boolean = false) => {

        return !hide && (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SectionTitle title="SLA" subtitle />
                </Grid>
                <Grid item xs={4}>
                    <TextField label="Horário de Atendimento" value={data?.horario_atendimento}
                        onChange={(e) => setData({ ...data, horario_atendimento: e.target.value })}
                        {...form.horario_atendimento} placeholder="Seg/Sex - 8:00 ..."
                        variant="outlined" InputLabelProps={{ shrink: data?.horario_atendimento !== undefined }} 
                        fullWidth multiline rows={5}/>
                </Grid>
                <Grid item xs={8}>
                    <TextField InputLabelProps={{ shrink: data?.notas_atendimento !== undefined }}
                        label="Instruções de Atendimento"
                        value={data?.notas_atendimento || ''} fullWidth
                        multiline rows={5} variant="outlined"
                        onChange={(e) => setData({ ...data, notas_atendimento: e.target.value })}
                        {...form.notas_atendimento} />
                </Grid>
                <Grid item xs={3}>
                    <TextField label="Tempo de Resposta / horas"
                    InputLabelProps={{ shrink: data?.tempo_resposta !== undefined }}
                    fullWidth value={data?.tempo_resposta}
                    onChange={(e) => setData({ ...data, tempo_resposta: e.target.value })}
                    InputProps={{ type: 'number' }}
                    className={classes.input}
                    variant="outlined"
                    {...form.tempo_resposta} />
                </Grid>
                <Grid item xs={3}>
                    <TextField label="Tempo de Solução / horas"
                    InputLabelProps={{ shrink: data?.tempo_solucao !== undefined }}
                    fullWidth value={data?.tempo_solucao}
                    onChange={(e) => setData({ ...data, tempo_solucao: e.target.value })}
                    // InputProps={{ type: 'number' }}
                    className={classes.input}
                    variant="outlined"
                    {...form.tempo_solucao} />
                </Grid>
                <Grid item xs={3}>
                    <TextField InputLabelProps={{ shrink: data?.quantidade_chamados_mes !== undefined }}
                        label="QTD Chamados/Mês"
                        value={data?.quantidade_chamados_mes} fullWidth
                        variant="outlined"
                        onChange={(e) => setData({ ...data, quantidade_chamados_mes: e.target.value })}
                        {...form.quantidade_chamados_mes} />
                </Grid>
                <Grid item xs={3}>
                    <CustomSelect label="Multa contratual"
                        value={data?.has_multa_contratual ? 'Sim' : 'Não'}
                        options={['Sim', 'Não']}
                        onChange={(value) => setData({
                            ...data,
                            has_multa_contratual: value === 'Sim' ? true : false
                        })}
                        {...form.has_multa_contratual} margin />
                </Grid>
                <Grid item xs={6}>
                    <TextField InputLabelProps={{ shrink: data?.notas_multa_contratual !== undefined }}
                        label="Observações" disabled={data?.has_multa_contratual !== true}
                        value={data?.notas_multa_contratual || ''} fullWidth
                        multiline rows={5} variant="outlined"
                        onChange={(e) => setData({ ...data, notas_multa_contratual: e.target.value })}
                        {...form.notas_multa_contratual} />
                </Grid>
                <Grid item xs={6}>
                    <TextField InputLabelProps={{ shrink: data?.notas_sla !== undefined }}
                        label="Observações do SLA"
                        value={data?.notas_sla || ''} fullWidth
                        multiline rows={5} variant="outlined"
                        onChange={(e) => setData({ ...data, notas_sla: e.target.value })}
                        {...form.notas_sla} />
                </Grid>
            </Grid>)
    }

    const EquipamentoEndereco = (hide: boolean = false) => {

        function onChangeEndereco(endereco: IEnderecoEquipamento): void {
            if (equipmentosContrato) {
                const { id_equipamento, ...newEndereco } = endereco;
                const index = equipmentosContrato?.findIndex(item => item.equipamento?.id === id_equipamento);
                if (index > -1) {
                    const ec = equipmentosContrato[index];
                    equipmentosContrato[index] = { ...ec, ...newEndereco };
                    setEquipmentosContrato([...equipmentosContrato]);
                }

            }
        }

        return !hide && (
            <Grid container spacing={2}>
                <CadastroEndereco
                    equipamentosContrato={equipmentosContrato || []}
                    estados={estados || []}
                    onChange={onChangeEndereco} />
            </Grid>
        )
    }

    const disabledBtn = (): boolean => {
        return Object.values(form).some((item: any) => item.error === true);
    }

    const saveContract = (e: any) => {
        setRequestLog([]);
        setShowSnackbar(true);

        const date2string = (value: undefined | string | Date) => {
            let dt = undefined;
            if (value && value instanceof Date) {
                dt = format(value, 'yyyy-MM-dd');
            } else {
                dt = value;
            }
            return dt;
        }

        const results: any[] = [];

        const params = {
            id: id,
            ...data,
            clientes: clientes.map(item => item.id),
            tipo_atendimento: data?.tipo_atendimento?.toUpperCase(),
            tipo_contrato: typeof (data?.tipo_contrato) === 'string' ?
                data?.tipo_contrato : data?.tipo_contrato?.key,
            status_contratual: typeof (data?.status_contratual) === 'string' ?
                data?.status_contratual : data?.status_contratual?.key,
            modo_contabilizacao: typeof (data?.modo_contabilizacao) === 'string' ?
                data?.modo_contabilizacao : data?.modo_contabilizacao?.key,
            data_inicio: date2string(data?.data_inicio),
            data_fim: date2string(data?.data_fim)
        }

        /**
         * Adiciona os equipamentos selecionandos ao contrato
         * @param idContrato 
         * @param equipamentos 
         */
        const addEquipments = (idContrato: number, equipamentos: any[]) => {

            equipamentos.forEach(equip => {
                if (equip.contrato_cliente === undefined) {
                    addEquipamento(idContrato, equip.equipamento.id, equip.cliente_id,
                        (responseData: any) => {
                            equip.id = responseData.id;
                            const msg = `O Equipamento ${equip.equipamento.serie} foi adicionado ao contrato ${idContrato} com sucesso`;
                            console.log(msg);
                            results.push({ result: true, msg: msg })
                            saveEquipamentData(idContrato, equip);
                        },
                        (error: any) => {
                            if (typeof (error?.response?.data) === 'object' && 'non_field_errors' in error?.response?.data) {
                                saveEquipamentData(idContrato, equip);
                            } else {
                                const msg = `Não foi possível adicionar o equipamento ${equip.equipamento.serie} no contrato ${idContrato}`;
                                results.push({ result: false, msg: msg });
                                setRequestLog(results);
                                console.error(error)
                            }
                        })
                } else {
                    saveEquipamentData(idContrato, equip);
                }
            });
        }

        /**
         * Salva os dados referentes ao contrato e equipamento
         * @param idContrato 
         * @param equipment 
         */
        const saveEquipamentData = (idContrato: number, equipment: any) => {
            saveEquipmentDetails(idContrato, equipment,
                (responseData: any) => {
                    const msg = `Os dados do equipamento ${equipment.equipamento.serie} foram atualizados no contrato ${idContrato} com sucesso`;
                    console.log(msg);
                    results.push({ result: true, msg: msg });
                    setRequestLog(results);
                    setShowSnackbar(true);
                },
                (error: any) => {
                    const msg = `Não foi possível atualizar os dados do equipamento ${equipment.equipamento.serie} no contrato ${idContrato}`;
                    results.push({ result: false, msg: msg });
                    setRequestLog(results);
                    setShowSnackbar(true);
                    console.error(error)
                });
        }

        const handleSuccess = (responseData: any) => {
            const msg = id ? 'Contrato atualizado com Sucesso' : 'Contrato criado com Sucesso';
            results.push({ result: true, msg: msg });
            setData({ ...data, id: responseData.id });
            if (equipmentosContrato && equipmentosContrato?.length > 0) {
                addEquipments(Number(responseData.id), equipmentosContrato)
            } else {
                setRequestLog(results);
                history.push(`contrato/${responseData.id}`);
            }
        };

        const handleFail = (error: any) => {
            const msg = id ? 'Não foi possível atualizar o Contrato' : 'Não foi possível criar o Contrato';
            results.push({ result: false, msg: msg });
            const validation = { ...error.response.data };
            Object.entries(error.response.data).forEach(([k, value]) => {
                if (Array.isArray(value)) {
                    validation[k] = { error: true, helperText: value.join(', ') };
                }
            })
            setForm({ ...form, ...validation });
            setRequestLog(results);
            setShowSnackbar(true);
            console.error(error);
        }

        if (equipmentosContrato && equipmentosContrato.length > 0) {
            if (!equipmentosContrato.some(item => item.cliente_id === undefined)) {
                // Um novo contrato pode ser salvo parcialmente por isso id pode ser undefined, e data.id
                // pode ter um valor que foi atribuido ao savar os dados do contrato
                if (id || data.id) {
                    updateContrato(params, handleSuccess, handleFail);
                } else {
                    createContrato(params, handleSuccess, handleFail);
                }
            } else {
                const place = document.getElementById('selecaoEquipamento');
                place?.scrollIntoView({ behavior: 'smooth' });
                results.push({ msg: 'É necessário associar o equipamento a um cliente', result: false });
                setRequestLog(results);
            }
        } else {
            const place = document.getElementById('selecaoEquipamento');
            place?.scrollIntoView({ behavior: 'smooth' });
            results.push({ msg: 'É necessário adicionar pelo menos um equipamento ao contrato', result: false });
            setRequestLog(results);
        }
    }

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    }

    return (
        <Container style={{ marginBottom: '96px' }}>
            <SDHeader title="Cadastro de Contrato" subtitle="Cadastros" />
            <Divider />
            {DadosContrato()}
            {SelecaoCliente()}
            {SelecaoEquipamentos()}
            {EquipamentoEndereco(data?.tipo_atendimento.toUpperCase() === 'AVULSO')}
            {DadosContabilizacao(data?.tipo_atendimento.toUpperCase() === 'AVULSO')}
            {SLA(data?.tipo_atendimento.toUpperCase() === 'AVULSO')}

            <Box display="flex" justifyContent="space-between"
                py={4} alignItems="center">
                <Button variant="outlined" style={{ textTransform: 'none' }}
                    color="primary">Histórico de Atualizações</Button>
                <Box display="flex" justifyContent="right" color="red"
                    alignItems="center">
                    <Box visibility={disabledBtn() ? '' : 'hidden'}>
                        As seções com itens em vermelho contem campos que
                        precisam ser preenchidos.
                        &nbsp;&nbsp;&nbsp;
                    </Box>
                    <Button variant="contained"
                        onClick={saveContract} style={{ textTransform: 'none' }}
                        disabled={disabledBtn()}>
                        {id ? 'Salvar' : 'Cadastrar'}
                    </Button>

                    <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                        <Box visibility={showSnackbar ? '' : 'hidden'}>
                            {requestLog.map(item =>
                                <Box m={2} key={item.msg}>
                                    <Alert severity={item.result ? 'success' : 'error'}
                                        onClose={handleCloseSnackbar}>
                                        {item.msg}
                                    </Alert>
                                </Box>
                            )}
                        </Box>
                    </Snackbar>
                </Box>
            </Box>
        </Container>
    );
}

export default React.memo(Contract);