import { Button, Container, Grid, Typography, Box, Divider, TextField, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, { FC, useEffect, useState } from 'react';
import CustomRadioGroup from '../../components/CustomRadioGroup';
import CustomSelect from '../../components/CustomSelect';
import SDHeader from '../../components/SDHeader';
import SectionTitle from '../../components/SectionTitle';
import { fetchFiliais } from '../../services/BaseService';
import AddVeiculoDlg from './components/AddVeiculoDlg';
import { User as IUser } from './models'
import { createColaboradores as createColaborador } from './services/UserService';
import MaskedInput from 'react-text-mask';
import * as mask from '../../components/Masks';

const USER_STATUS = [
    { key: 'ATIVO', value: 'Ativo' },
    { key: 'INATIVO', value: 'Inativo' },
    { key: 'FERIAS', value: 'Féria' },
    { key: 'LICENCA', value: 'Licença' }
];


const User: FC = (props) => {

    const [data, setData] = useState<null | any>(null);
    const [form, setForm] = useState<null | IUser | any>(null);
    const [filiais, setFiliais] = useState<any[] | null>(null);
    const [departamentos] = useState<any[] | null>(null);
    const [equipes] = useState<any[] | null>(null);
    const [permissoes] = useState<any[] | null>(null);
    const [statusList] = useState<any[] | null>(USER_STATUS);
    const [veiculos] = useState<any[] | null>(null);
    const [requestResult, setRequestResult] = useState<any | null>(null);
    const [openAddVeiculo, setOpenAddVeiculo] = useState(false);

    useEffect(() => {
        setForm({
            username: {
                required: true,
                error: data?.email === undefined || data?.email === '',
                helperText: data?.email ? '' : 'Campo obrigatório'
            },
            codigo: {
                required: false,
                error: false,
                helperText: undefined
            }
        });
    }, [data])

    /** Lista filiais da Konica */
    useEffect(() => {
        if (filiais === null) {
            fetchFiliais(
                (filiais: any) => {
                    setFiliais([...filiais]);
                },
                (err: any) => {
                    console.error(err);
                });
        }
    }, [filiais]);

    const handleChangeValue = (e: any) => {
        let newData = null;
        switch (e.target.name) {
            case 'nome':
                newData = { ...data, nome: e.target.value };
                break;
            case 'email':
                newData = { ...data, email: e.target.value };
                break;
            case 'telefone':
                newData = { ...data, telefone: e.target.value };
                break;
            case 'celular':
                newData = { ...data, celular: e.target.value };
                break;

            default:
                break;
        }
        setData(newData);
    }

    function getSelectedItem(list: any[] | null, value: string) {
        let item = null;
        if (list) {
            const index = list?.findIndex(item => item.nome === value);
            if (index > -1) {
                item = list[index];
            }
        }
        return item;
    }

    const handleChangeFilial = (value: string) => {
        const item = getSelectedItem(filiais, value)
        if (item) {
            setData({ ...data, filial: item })
        }
    }

    const handleChangeDepartamento = (value: string) => {
        const item = getSelectedItem(departamentos, value)
        if (item) {
            setData({ ...data, departamento: item })
        }
    }

    const handleChangeEquipes = (value: string) => {
        const item = getSelectedItem(equipes, value)
        if (item) {
            setData({ ...data, equipe: item })
        }
    }

    const handleChangePermissao = (value: string) => {
        const item = getSelectedItem(permissoes, value)
        if (item) {
            setData({ ...data, permissao: item })
        }
    }

    const handleChangeStatus = (value: string) => {
        if (statusList) {
            const index = statusList?.findIndex(item => item.value === value);
            if (index > -1) {
                const item = statusList[index];
                setData({ ...data, status: item })
            }
        }
    }

    const handleChangeVeiculo = (value: string) => {
        const item = getSelectedItem(veiculos, value)
        if (item) {
            setData({ ...data, veiculo: item })
        }
    }

    const saveUser = (e: any) => {
        const params = {
            ...data,
            username: data?.email,
            status: data?.status?.key
        };

        createColaborador(params,
            (data: any) => {
                setRequestResult({ result: true, msg: 'Usuário cadastado com sucesso!' })
            },
            (error: any) => {
                setRequestResult({ result: false, msg: 'Ocorreu um erro ao cadastar um novo usuário.' })
                const validation = { ...error.response.data };
                Object.entries(error.response.data).forEach(([k, value]) => {
                    if (Array.isArray(value)) {
                        validation[k] = { error: true, helperText: value.join(', ') };
                    }
                })
                setForm({ ...form, ...validation });
                console.error(error);
            })
    }

    const handleCloseSnackbar = () => {
        setRequestResult(null);
    }

    return (
        <Container>
            <SDHeader subtitle="Cadastros" title="Cadastro de Usuário" />
            <Divider />
            <SectionTitle title="Dados do Usuário" />
            <Grid container spacing={2}>
                <Grid container item md={6} spacing={1}>
                    <Grid item xs={12}>
                        <TextField label="Nome" value={data?.nome} name="nome" fullWidth
                            {...form?.nome}
                            onChange={handleChangeValue} placeholder="Nome completo do usuário" />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="E-mail" value={data?.email} name="email" fullWidth
                            {...form?.username}
                            onChange={handleChangeValue} placeholder="E-mail do usuário" />
                    </Grid>
                    <Grid item xs={6}>
                        <MaskedInput 
                            mask={mask.phone} 
                            value={data?.telefone_privado}
                            onChange={handleChangeValue}
                            guide={false}
                            name="telefone"
                            placeholder="(99) 9999-9999"
                            render={(ref, props) =>
                                <TextField label="Telefone"
                                fullWidth inputRef={ref} {...props} {...form?.telefone_privado} />}/>
                    </Grid>
                    <Grid item xs={6}>
                        <MaskedInput 
                            mask={mask.phone} 
                            value={data?.celular}
                            onChange={handleChangeValue}
                            guide={false}
                            name="celular"
                            placeholder="(99) 99999-9999"
                            render={(ref, props) =>
                                <TextField label="Celular"
                                fullWidth inputRef={ref} {...props} {...form?.telefone_celular} />}/>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomSelect label="Filial" value={data?.filial?.municipio}
                            options={filiais?.map(item => item.municipio) || []}
                            onChange={handleChangeFilial}
                            {...form?.filial} />
                    </Grid>
                    <Grid item xs={6}>
                        <CustomSelect label="Departamento" value={data?.departamento?.nome}
                            options={departamentos?.map(item => item.nome) || []}
                            onChange={handleChangeDepartamento} />
                    </Grid>
                    <Grid item xs={6}>
                        <CustomSelect label="Equipes" value={data?.equipe?.nome}
                            options={equipes?.map(item => item.nome) || []}
                            onChange={handleChangeEquipes} />
                    </Grid>
                    <Grid item xs={6}>
                        <CustomSelect label="Nível de Permissão" value={data?.permissao?.value}
                            options={permissoes?.map(item => item.value) || []}
                            onChange={handleChangePermissao} />
                    </Grid>
                </Grid>
                <Grid container item md={6} spacing={1}>
                    <Grid item xs={12}>
                        <CustomRadioGroup label="Vinculo"
                            value={data?.vinculo || 'CLT'}
                            options={['CLT', 'Terceiros']}
                            onChange={(value) => setData({ ...data, vinculo: value })} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="RG" value={data?.rg} name="rg" fullWidth
                            onChange={handleChangeValue} placeholder="Número do RG" />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField label="SAP" value={data?.cod_sap} name="sap" fullWidth
                            onChange={handleChangeValue} placeholder="Código do SAP" />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField label="Razão Social da Revenda" value={data?.razao_social_revenda}
                            name="razao-social" fullWidth
                            onChange={handleChangeValue} placeholder="Razão social da revenda" />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomSelect label="Status" value={data?.status?.value}
                            options={statusList?.map(item => item.value) || []}
                            onChange={handleChangeStatus} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="Início" value={data?.inicio}
                            name="inicio" fullWidth
                            onChange={handleChangeValue} placeholder="dd/mm/aaaa" />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="Término" value={data?.termino}
                            name="termino" fullWidth
                            onChange={handleChangeValue} placeholder="dd/mm/aaaa" />
                    </Grid>
                    <Grid item xs={6}>
                        <CustomSelect label="Veículo Associado" value={data?.status?.value}
                            options={statusList?.map(item => item.value) || []}
                            onChange={handleChangeVeiculo} />
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="outlined" color="primary"
                            style={{ textTransform: 'none' }}
                            onClick={() => setOpenAddVeiculo(true)}>
                            Cadastrar Veículos
                        </Button>
                        <AddVeiculoDlg open={openAddVeiculo} onClose={() => setOpenAddVeiculo(false)}
                            onAdd={() => setOpenAddVeiculo(false)} />
                    </Grid>
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="right" color="darkorange"
                py={4} alignItems="center">
                {requestResult?.status === 0 && (<Typography color="inherit">
                    As seções com itens em laranja contem campos que
                    precisam ser preenchidos.&nbsp;&nbsp;&nbsp;
                </Typography>)}
                {requestResult?.status === 1 && (<Typography color="primary">
                    {requestResult?.msg}&nbsp;&nbsp;&nbsp;
                </Typography>)}
                <Button variant="contained" style={{ textTransform: 'none' }}
                    onClick={saveUser}>
                    Cadastrar
                </Button>
                <Snackbar open={requestResult} autoHideDuration={3000} onClose={handleCloseSnackbar}>
                    <Alert severity={requestResult?.result ? 'success' : 'error'} onClose={handleCloseSnackbar}>
                        {requestResult?.msg}
                    </Alert>
                </Snackbar>
            </Box>
        </Container>
    )
}

export default User;