import DateFnsUtils from '@date-io/date-fns';
import { FormControl } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import brLocale from "date-fns/locale/pt-BR";
import React, { FC } from 'react';
import { useEffect } from 'react';

const CustomDatePicker: FC<{
    label: string,
    value: Date | null,
    required?: boolean,
    error?: boolean,
    helperText?: string,
    onChange?: (value: Date | null) => void,
    disabled?: boolean
}> = ({ label, value, required, error, helperText, disabled, onChange = (date) => { } }) => {

    const [selectedDate, setSelectedDate] = React.useState<Date | null>(value);

    useEffect(() => {
        if (value !== selectedDate) {
            setSelectedDate(value);
        }
    }, [value, selectedDate])

    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
        onChange(date)
    };

    return (
        <FormControl style={{width: '100%'}}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
                <KeyboardDatePicker
                    disableToolbar
                    autoOk
                    error={error}
                    helperText={helperText}
                    required={required}
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="dense"
                    label={label}
                    invalidDateMessage="Data inválida"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    disabled={disabled}
                />
            </MuiPickersUtilsProvider>
        </FormControl>
    )
}

export default CustomDatePicker;