/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemText,
  Popover,
  LinearProgress,
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { NavbarProps } from '.';
import { NavbarAction } from './NavbarProps';
import useStyles from './style';
import { FaUserCircle } from 'react-icons/fa';

import { LoadingContext } from '../../contexts/loading';
import Pusher from 'pusher-js';

const Navbar: React.FC<NavbarProps> = (props) => {
  const {
    logoPath = '',
    title = '',
    menuItems = [],
    shortcuts = [],
    userProfile,
    onClickSettings,
  } = props || {};

  const classes = useStyles({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [anchorShortcuts, setAnchorShortcuts] =
    React.useState<HTMLButtonElement | null>(null);

  const { loading } = useContext(LoadingContext);

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Renderiza os itens do menu da barra de navegação
   */
  const renderMenuItems = menuItems.map((item) => {
    return (
      <React.Fragment key={item.label}>
        <Button color="inherit" component={Link} to={item.url}>
          {item.label}
        </Button>
        {item.submenu && (
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
          ></Menu>
        )}
      </React.Fragment>
    );
  });

  /**
   * Renderiza os atalhos da barra de navegação
   */
  function renderShortcuts() {
    const handleClick = (action: NavbarAction) => {
      setAnchorShortcuts(null);
      action.action();
    };
    return (
      <Popover
        open={anchorShortcuts !== null}
        anchorEl={anchorShortcuts}
        onClose={() => setAnchorShortcuts(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List>
          {shortcuts.map((shortcut) => {
            return (
              <ListItem
                key={shortcut.label}
                button
                onClick={() => handleClick(shortcut)}
              >
                <ListItemText primary={shortcut.label} />
              </ListItem>
            );
          })}
        </List>
      </Popover>
    );
  }

  /**
   * Renderiza os itens de ação do profile do usuário
   */
  const renderUserActions = userProfile.userActions.map((action) => {
    return (
      <MenuItem key={action.label} onClick={action.action}>
        {action.label}
      </MenuItem>
    );
  });

  return (
    <React.Fragment>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className="nav-container">
          <Box className={classes.logoBox}>
            <img src={logoPath} alt="logo" className={classes.logo} />
            <Typography variant="h6" className={classes.barlogo}>
              {title}
            </Typography>
          </Box>
          <Box className={classes.mainMenu}>{renderMenuItems}</Box>
          <Box flexGrow={1} textAlign="right">
            <IconButton
              aria-label="Usuário logado"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              component="button"
              onClick={handleMenu}
              color="inherit"
            >
              {userProfile?.avatar && userProfile?.avatar?.length > 0 ? (
                <Avatar
                  alt={userProfile.username[0]}
                  src={userProfile.avatar}
                />
              ) : (
                <FaUserCircle size={32} />
              )}
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              {renderUserActions}
            </Menu>
            <IconButton
              aria-label="Configurações"
              aria-controls="menu-config"
              aria-haspopup="true"
              component="button"
              onClick={onClickSettings}
              color="inherit"
            >
              <SettingsIcon fontSize="large" />
            </IconButton>
          </Box>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
      <div className={classes.offset} />
    </React.Fragment>
  );
};

export default Navbar;
