import { Box, Button, Divider, Typography } from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import CustomSnackbar from "../../../components/CustomSnackbar";
import SDHeader from "../../../components/SDHeader";
import { fetchColaboradores } from "../../User/services/UserService";
import { TonersService } from "../services/TonerService";
import { UserProps } from "../SolicitacaoList/SolicitacaoListProps";
import OrdemVendaDlg from "./OrdemVendaDlg";
import ItensSolicitados from "./TabPanels/ItensSolicitados";
import OrdensDeVenda from "./TabPanels/OrdensDeVenda";
import EquipmentAddressCard from "../../../components/EquipmentAddressCard";
import { useSnackbar } from "notistack";

import { LoadingContext } from "../../../contexts/loading";
import { NotificationContext } from "../../../contexts/notifications";
import { createSalesOrders } from '../services/TonerService';


interface ParamTypes {
  id: string;
}

type PusherEvent = {
  status: number; //Status da solicitação de materiais
  ordens: {
    id: number;
    status: number;
    numero: string;
    fornecimento: string;
    nf: string;
  }[];
};

interface Ordem {
  centro_fornecimento: string;
  cfop: string;
  dados_adicionais_nf: string;
  fornecimento: string;
  id: number;
  items: {
    centro_fornecimento: string;
    descricao: string;
    id: number;
    label: string;
    material: string;
    ordem: number;
    order_item_delivery_itm: string;
    quantidade: number;
    store_location: string;
  }[];
  motivo_ordem: string;
  nota_fiscal: string;
  sap_code: string;
  setor_atividade: string;
  solic_material: number;
  status: number;
  status_picking: boolean;
  tipo_ordem: string;
  transportador: string;
  volume: string;
}

const SolicitacaoDetail: React.FC = () => {
  const { id } = useParams<ParamTypes>();
  const [solicitacao, setSolicitacao] = useState<any>();
  const [requestResults, setRequestResults] = useState<any[]>([]);
  const { setLoading } = useContext(LoadingContext);
  const { pusher } = useContext(NotificationContext);
  const [analistas, setAnalistas] = useState<UserProps[] | null>(null);
  const [abrirOrdem, setAbrirOrdem] = useState(false);
  const [ordens, setOrdens] = useState<Ordem[]>([]);
  const [newStatus, setNewStatus] = useState<number | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    let innerOrdens: Ordem[] = [];
    TonersService.getTonerOrder(
      id,
      (data: any) => {
        setSolicitacao(data);
      },
      (error: any) => {
        console.error(error);
      }
    );
    TonersService.getOrders(
      id,
      (data: any) => {
        setOrdens(data);
        innerOrdens = data;
      },
      (error: any) => {
        console.error(error);
      }
    );

    const channel = pusher.subscribe(`pedido-${id}`);

    channel.bind("data_event", function (data: PusherEvent) {
      console.log(ordens);
      const newOrdens: Ordem[] = innerOrdens?.map((ordem) => {
        const receivedOrdem = data.ordens.filter((d) => {
          return d.id === ordem.id;
        });
        return {
          ...ordem,
          nota_fiscal: receivedOrdem?.[0]?.nf || ordem.nota_fiscal,
          fornecimento: receivedOrdem?.[0]?.fornecimento || ordem.fornecimento,
          status: receivedOrdem?.[0]?.status || ordem.status,
        };
      });
      setOrdens(newOrdens);
      setNewStatus(data.status);
      enqueueSnackbar("O status da solicitação foi atualizado.", {
        variant: "info",
      });
    });

    return () => {
      pusher.unsubscribe(`pedido-${id}`);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, pusher]);

  const updateOrders = () => {
    setLoading(true);
    TonersService.getOrders(
      id,
      (data: any) => {
        console.log('Ordens updated', data)
        setOrdens(data);
        setLoading(false);
      },
      (error: any) => {
        console.error(error);
      }
    );
  };

  const handleCriarOrdem = () => {
    const params = {}

    setLoading(true);
    createSalesOrders(
      id,
      params,
      (values: any) => {
        setLoading(false);
        updateOrders();
      },
      (error: any) => {
        setLoading(false);
        let errors = error.response.data['non_field_errors'].map((m: any) => {return {"result": false, "msg": m}})
        console.log(errors)
        // 
        setRequestResults(errors)

        // setRequestResults([
        //   { result: false, msg: reason },
        // ]);
      }
    );
  };

  /** Carrega analistas responsaveis */
  useEffect(() => {
    if (analistas === null) {
      setLoading(true);
      fetchColaboradores(
        (data: any) => {
          setLoading(false);
          setAnalistas(data);
        },
        (error: any) => {
          console.error(error);
          setLoading(false);
        }
      );
    }
  }, [analistas, setLoading]);

  const enderecoFormater = (endereco: any) => {
    const logradouro = endereco?.logradouro || "Logradouro não informado";
    const numero = endereco?.numero || "S/N";
    const bairro = endereco?.bairro || "Bairro não informado";
    const cidade = endereco?.municipio || "Cidade não informada";
    const estado = endereco?.estaod || "Estado não informado";
    const cep = endereco?.cep || "CEP não informado";
    return `${logradouro},${numero}, ${bairro}, ${cidade}-${estado}, ${cep}`;
  };

  const handleProcessSalesOrders = () => {
    setLoading(true);
    TonersService.processSalesOrders(
      id,
      (data: any) => {
        setLoading(false);
        requestResults.push({
          result: true,
          msg: "Solicitação de liberação enviada com sucesso.",
        });
        setRequestResults([...requestResults]);
      },
      (error: any) => {
        setLoading(false);
        requestResults.push({
          result: false,
          msg: "Não foi possível enviar sua solicitação. Por favor consulte os logs para mais detalhes.",
        });
        setRequestResults([...requestResults]);
      }
    );
  };

  const handlePrintAllOrders = () => {
    setLoading(true);
    TonersService.printAllOrders(
      id,
      (data: any) => {
        console.log("Imprime todas as ordens");
        setLoading(false);
      },
      (error: any) => {
        console.log("Erro na impressão de todas as ordens");
        setLoading(false);
      }
    );
  };

  const forceUpdate = () => {
    console.log('Update order item list')
    updateOrders()
  }

  return (
    <Box mb={3}>
      <SDHeader title={`Pedido: ${id}`} subtitle="Solicitação de Materiais" />
      <Box my={1}>
        <Divider />
      </Box>
      <Box
        my={2}
        border={1}
        borderRadius={5}
        borderColor="grey.300"
        px={3}
        py={2}
        display="flex"
        gridColumnGap={56}
      >
        <Box>
          <span
            style={{
              fontWeight: 500,
              fontSize: "14px",
              color: "#757575",
              display: "block",
              marginBottom: "8px",
            }}
          >
            Analista Responsável
          </span>
          <span
            style={{
              fontWeight: 400,
              fontSize: "14px",
              color: "#757575",
              display: "block",
            }}
          >
            {solicitacao?.responsavel?.nome || "Sem nome"}
          </span>
        </Box>
        <Box>
          <span
            style={{
              fontWeight: 500,
              fontSize: "14px",
              color: "#757575",
              display: "block",
              marginBottom: "8px",
            }}
            className="title"
          >
            Situação Solicitação
          </span>
          <span
            style={{
              fontWeight: 400,
              fontSize: "14px",
              color: "#757575",
              display: "block",
            }}
          >
            {TonersService.getOrderStatus(newStatus || solicitacao?.status)}
          </span>
        </Box>
      </Box>

      <Box my={3} gridColumnGap={32} display="flex">
        <Box border={1} borderRadius={5} borderColor="grey.300" px={3} py={2}>
          <p
            style={{
              textTransform: "uppercase",
              fontSize: "14px",
              color: "#757575",
              margin: 0,
              marginBottom: 8,
            }}
          >
            Dados do cliente
          </p>
          <Typography variant="h6">
            {solicitacao?.cliente?.nome.toUpperCase()}
          </Typography>
          <p>{enderecoFormater(solicitacao?.cliente)}</p>
          <Box display="inline-flex">
            <Box mr={3}>
              <b>CPF/CNPJ:</b>
              <br />
              {solicitacao?.cliente?.cnpj_cpf || "Não informado"}
            </Box>
            <Box>
              <b>Contato:</b>
              <br />
              {solicitacao?.solicitante?.nome || "Não informado"}
            </Box>
          </Box>
        </Box>
        <Box border={1} borderRadius={5} borderColor="grey.300" px={3} py={2}>
          <p
            style={{
              textTransform: "uppercase",
              fontSize: "14px",
              color: "#757575",
              margin: 0,
              marginBottom: 8,
            }}
          >
            Dados do equipamento
          </p>
          <Typography variant="h6">
            {solicitacao?.equipamento?.modelo.nome}
          </Typography>
          <Box component="p">{solicitacao?.equipamento?.serie}</Box>
          <EquipmentAddressCard data={solicitacao?.dados_contrato[0]} />
        </Box>
      </Box>
      <Box>
        <h4
          style={{
            fontSize: "24px",
            fontWeight: 400,
            color: "#757575",
            marginBottom: 0,
          }}
        >
          Itens Solicitados
        </h4>
        {solicitacao?.materiais_solicitados && (
          <ItensSolicitados
            itens={solicitacao?.materiais_solicitados}
            id={id}
          />
        )}
      </Box>
      <Box display="flex" justifyContent="right" py={3}>
        <Button
          variant="contained"
          color="inherit"
          onClick={() => handleCriarOrdem()}
          size="small"
          disabled={ordens.length > 0}
        >
          Configurar ordens de venda
        </Button>
        {/* <OrdemVendaDlg
          open={abrirOrdem}
          idSolicitacao={id}
          onClose={() => {
            setAbrirOrdem(false);
            updateOrders();
          }}
        /> */}
      </Box>
      <Box>
        <h4
          style={{
            fontSize: "24px",
            fontWeight: 400,
            color: "#757575",
            marginBottom: 0,
          }}
        >
          Ordens de Venda
        </h4>
        <OrdensDeVenda itens={ordens} forceUpdate={forceUpdate} />
      </Box>
      {/* <SolicitacaoTabs
        solicitacao={solicitacao}
        onChangeValues={handleTabChangeValues}
      /> */}
      <Box
        display="flex"
        padding={2}
        mt={4}
        gridColumnGap={16}
        justifyContent="center"
      >
        <Box display="none" color="red" mr={2}>
          Verifique as pendências de cada seção para poder liberar esta
          solicitação.
        </Box>
        <Button
          size="small"
          color="inherit"
          variant="contained"
          onClick={handleProcessSalesOrders}
        >
          Enviar para Liberação
        </Button>
        <Button
          size="small"
          color="inherit"
          variant="contained"
          onClick={handlePrintAllOrders}
        >
          Imprimir Todas as Etiquetas
        </Button>
        {/* <Button
            size="small"
            color="inherit"
            variant="contained"
            onClick={handleLiberar}
          >
            Dividir Ordem
          </Button> */}
      </Box>
      <CustomSnackbar
        results={requestResults}
        onClose={() => setRequestResults([])}
      />
    </Box>
  );
};

export default SolicitacaoDetail;
