import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(0.5),
      margin: 0,
    },
    filterCategory: {
      display: 'flex',
      flexDirection: 'row',
      listStyle: 'none',
      margin: 0,
      justifyContent: 'center',
      padding: 0,
    },
    filterMenu: {
      display: 'flex',
      flexDirection: 'column',
      listStyle: 'none',
      margin: 0,
      justifyContent: 'left',
      padding: 0,
    },
    chip: {
      margin: theme.spacing(0.5),
    },
  }),
);

export default useStyles;