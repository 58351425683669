import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { DataGrid, GridColDef, GridRowId } from "@material-ui/data-grid";
import CloseIcon from "@material-ui/icons/Close";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import React, { FC, useEffect, useState } from "react";
import BoxTitle from "../../../components/BoxTitle";
import CustomDatePicker from "../../../components/CustomDatePicker";
import CustomRadioGroup from "../../../components/CustomRadioGroup";
import CustomSelect from "../../../components/CustomSelect";
import CustomSnackbar from "../../../components/CustomSnackbar";
import { getEquipamentosContrato } from "../../Contract/services";
import {
  fetchContratos,
  getContador,
  ModoColeta,
  StatusColeta,
  saveContadores,
  addAnexos,
} from "../services/ContadorService";
import { NumberInputCustom } from "../../../components/NumberFormatCustom";
import NumberFormat from "react-number-format";

function format(date: string) {
  let day,
    month,
    year = "";

  day = date.slice(-2, date.length);
  year = date.slice(0, 4);
  month = date.slice(5, 7);

  return `${day}/${month}/${year}`;
}
/**
 * Área de Seleção do contrato
 * @param props
 * @returns
 */
function SelecaoContrato(props: any) {
  const { contratos = [], onContratoSelected = (idContrato: number) => {} } =
    props;
  const columns: GridColDef[] = [
    {
      field: "index",
      width: 10,
      headerName: " ",
      sortable: false,
      disableColumnMenu: true,
    },
    { field: "id", hide: true },
    { field: "numero", headerName: "Nº do Contrato", width: 180 },
    { field: "vencimento_fatura", headerName: "Venc. da Fatura", width: 180 },
    { field: "dia_coleta_contador", headerName: "Data da Coleta", width: 180 },
    { field: "credito", headerName: "Crédito", width: 180 },
  ];

  const handleSelectRow = (rowsId: GridRowId[]) => {
    onContratoSelected(rowsId[0]);
  };

  return (
    <BoxTitle title="Seleção do Contrato" boxProps={{ height: 400 }}>
      <DataGrid
        rows={contratos.map((item: any, index: number) => {
          let date = new Date();
          let vencDate = new Date();
          vencDate.setDate(item.dia_coleta_contador);
          if (item.dia_coleta_contador < date.getDate()) {
            vencDate.setMonth(vencDate.getMonth() + 1);
          }
          return {
            ...item,
            index: index + 1,
            vencimento_fatura: new Intl.DateTimeFormat("pt-BR").format(
              vencDate
            ),
          };
        })}
        hideFooter
        density="compact"
        columns={columns}
        onSelectionModelChange={handleSelectRow}
      />
    </BoxTitle>
  );
}

function EquipamentosContrato(props: any) {
  const [showContadorDlg, setShowContadorDlg] = useState(false);
  const [equipamentosContrato, setEquipamentosContrato] = useState<any[]>([]);
  const [equipamentoSelecionando, setEquipamentoSelecionado] =
    useState<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [contadores, setContadores] = useState<any[]>([]);
  const { onEditContador = (contadores: any[]) => {} } = props;

  useEffect(() => {
    setEquipamentosContrato(props.equipamentosContrato);
  }, [props.equipamentosContrato]);

  const handleEditContador = (data: any) => {
    setEquipamentoSelecionado(data);
    setShowContadorDlg(true);
  };

  const handleCloseContadorDlg = (contador: any) => {
    setShowContadorDlg(false);
    if (contador) {
      const index = contadores.findIndex(
        (item) =>
          item?.equipamento === equipamentoSelecionando.equipamento.id ||
          item?.equipamento?.id === equipamentoSelecionando.equipamento.id
      );
      if (index > -1) {
        contadores.splice(index, 1);
      }
      contadores.push(contador);
      equipamentoSelecionando.contador = contador;
      onEditContador(contadores);
    }
  };

  return (
    <BoxTitle title="Equipamentos do Contrato">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell title=""></TableCell>
            <TableCell title="">Série</TableCell>
            <TableCell title="">Modelo</TableCell>
            <TableCell title=""></TableCell>
            <TableCell title="">Status</TableCell>
            <TableCell title="">Modo de Coleta</TableCell>
            <TableCell title="">Última Produção (CL/PB)</TableCell>
            <TableCell title="">Coleta</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {equipamentosContrato.map((item: any, index: number) => {
            return (
              <TableRow key={item.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{item.equipamento.serie}</TableCell>
                <TableCell>{item.equipamento.modelo.nome}</TableCell>
                <TableCell>
                  {!item.contador ? (
                    <Button
                      color="primary"
                      variant="outlined"
                      style={{ textTransform: "none" }}
                      onClick={(e) => handleEditContador(item)}
                    >
                      Lançar Contador
                    </Button>
                  ) : (
                    <Button
                      color="secondary"
                      variant="outlined"
                      style={{ textTransform: "none" }}
                      onClick={(e) => handleEditContador(item)}
                    >
                      Editar Contador
                    </Button>
                  )}
                </TableCell>
                <TableCell align="center">
                  {item.contador?.status_coleta}
                </TableCell>
                <TableCell align="center">
                  {item.contador?.modo_coleta}
                </TableCell>
                <TableCell align="center">
                  {item.contador &&
                    `${item.contador?.ultima_contagem_cor || ""}/${
                      item.contador?.ultima_contagem_pb || ""
                    }`}

                </TableCell>
                <TableCell align="center">
                  {item?.contador?.data_coleta
                    ? format(item.contador?.data_coleta)
                    : "N/D"}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <ContadorDlg
        open={showContadorDlg}
        onClose={handleCloseContadorDlg}
        contador={equipamentoSelecionando?.contador}
        cliente={equipamentoSelecionando?.cliente}
        contrato={props.contrato}
        equipamento={equipamentoSelecionando?.equipamento}
      />
    </BoxTitle>
  );
}

const ContadorDlg = (props: any) => {
  const [contador, setContador] = useState<any>();
  const [anexo, setAnexo] = useState<any>();
  useEffect(() => {
    if (props.contador?.id) {
      getContador(
        props.contador.id,
        (data: any) => {
          setContador(data);
          setAnexo(data.attachment);
        },
        (reason: any) => console.error(reason)
      );
    } else {
      const init = {
        ...props.contador,
        equipamento: props.equipamento?.id,
        modo_coleta: props.contador?.modo_coleta || "CSRC",
        status_coleta: props.contador?.status_coleta || "PENDENTE",
        pb: 0,
        cor: 0,
      };
      setAnexo(null);
      setContador(init);
    }
  }, [props.contador, props.equipamento]);

  const handleClose = () => {
    props.onClose(props.contador);
    setAnexo(null);
  };

  const handleAnexo = (e: any) => {
    const anexo = e.target.files[0];
    setContador({ ...contador, attachment: anexo });
  };

  const anexoName = () => {
    let name = "";
    if (anexo) {
      const index = anexo.lastIndexOf("/") + 1;
      name = anexo.substr(index);
    }
    return name;
  };

  return (
    <Dialog open={props.open} onClose={handleClose} maxWidth="xl">
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Editar Contador</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <BoxTitle title="Equipamento">
          <Box display="flex">
            <Box mr={2}>
              <b>Série</b>
              <br />
              {props.equipamento?.serie || "-"}
            </Box>
            <Box mr={2}>
              <b>Modelo</b>
              <br />
              {props.equipamento?.modelo?.nome || "-"}
            </Box>
          </Box>
        </BoxTitle>
        <BoxTitle title="Cliente">
          <Box display="flex" flexWrap="wrap" justifyContent="space-between">
            <Box mr={2} mb={1}>
              <b>SAP</b>
              <br />
              {props.cliente?.cod_sap || "-"}
            </Box>
            <Box mr={2} mb={1} minWidth={200}>
              <b>Razao Social</b>
              <br />
              {props.cliente?.razao_social || "-"}
            </Box>
            <Box mr={2} mb={1}>
              <b>Contexto Empresarial</b>
              <br />
              {props.cliente?.tipo_razao_social || "-"}
            </Box>
            <Box mr={2} mb={1}>
              <b>Logradouro</b>
              <br />
              {props.cliente?.logradouro || "-"}
            </Box>
            <Box mr={2} mb={1}>
              <b>Bairro</b>
              <br />
              {props.cliente?.bairro || "-"}
            </Box>
            <Box mr={2} mb={1}>
              <b>Municipio/UF</b>
              <br />
              {props.cliente?.municipio || "-"}/{props.cliente?.estado || "-"}
            </Box>
          </Box>
        </BoxTitle>
        <BoxTitle title="Dados do Contador">
          <Grid container spacing={2}>
            <Grid item md={4}>
              <CustomRadioGroup
                options={["Inativo", "Ativo"]}
                value={contador?.status ? "Ativo" : "Inativo"}
                label="Status"
                onChange={(value) =>
                  setContador({
                    ...contador,
                    status: value === "Ativo" ? true : false,
                  })
                }
              />
            </Grid>
            <Grid item md={4}>
              <CustomSelect
                options={Array.from(ModoColeta.values())}
                value={
                  contador?.modo_coleta
                    ? ModoColeta.get(contador?.modo_coleta)
                    : ""
                }
                label="Modo de Coleta"
                onChange={(value) =>
                  setContador({ ...contador, modo_coleta: value.toUpperCase() })
                }
              />
            </Grid>
            <Grid item md={4}>
              <Box mr={2} mb={1}>
                <b>Parcela do Contrato</b>
                <br />
                {contador?.parcela_contrato || "1"} / {props.contrato?.parcelas}
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={4}>
              <CustomDatePicker
                disabled
                label="Inicio contratual"
                value={props.contrato?.data_inicio}
              />
            </Grid>
            <Grid item md={4}>
              <CustomDatePicker
                disabled
                label="Fim contratual"
                value={props.contrato?.data_fim}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <Box mr={2} mb={1} textAlign="center">
                <b>Mês Anterior</b>
                <br />
                <Box display="flex" justifyContent="center">
                  <Box mr={2} textAlign="center">
                    CL
                    <br />
                    <NumberFormat
                      displayType="text"
                      value={
                        contador?.ultima_contagem_cor
                          ? contador.ultima_contagem_cor
                          : 0
                      }
                      fixedDecimalScale
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      isNumericString
                    />
                  </Box>
                  <Box mr={2} textAlign="center">
                    PB
                    <br />
                    <NumberFormat
                      displayType="text"
                      value={
                        contador?.ultima_contagem_pb
                          ? contador.ultima_contagem_pb
                          : 0
                      }
                      fixedDecimalScale
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      isNumericString
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box mr={2} mb={1} textAlign="center">
                <b>Mês Atual</b>
                <br />
                <Box display="flex" justifyContent="center">
                  <Box mr={2} textAlign="center">
                    CL
                    <br />
                    <TextField
                      value={contador?.cor}
                      onChange={(e) =>
                        setContador({
                          ...contador,
                          cor: Number(e.target.value),
                        })
                      }
                      InputProps={{
                        inputComponent: NumberInputCustom as any,
                        inputProps: { style: { textAlign: "center" } },
                      }}
                    />
                  </Box>
                  <Box mr={2} textAlign="center">
                    PB
                    <br />
                    <TextField
                      value={contador?.pb}
                      onChange={(e) =>
                        setContador({ ...contador, pb: Number(e.target.value) })
                      }
                      InputProps={{
                        inputComponent: NumberInputCustom as any,
                        inputProps: { style: { textAlign: "center" } },
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box mr={2} mb={1} textAlign="center">
                <b>Produção Atual</b>
                <br />
                <Box display="flex" justifyContent="center">
                  <Box mr={1} textAlign="center">
                    CL
                    <br />
                    <NumberFormat
                      displayType="text"
                      value={contador?.cor || 0}
                      fixedDecimalScale
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      isNumericString
                    />
                  </Box>
                  <Box mr={1} textAlign="center">
                    PB
                    <br />
                    <NumberFormat
                      displayType="text"
                      value={contador?.pb || 0}
                      fixedDecimalScale
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      isNumericString
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={3}>
              {anexo || contador?.attachment?.name ? (
                <Box display="flex">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setContador({ ...contador, attachment: undefined });
                      setAnexo(null);
                    }}
                  >
                    <CloseRoundedIcon />
                  </IconButton>
                  {typeof contador?.attachment === "string" ? (
                    <Link
                      href={
                        typeof contador?.attachment === "string"
                          ? contador?.attachment
                          : ""
                      }
                      target="_blank"
                    >
                      {contador?.attachment?.name || anexoName()}
                    </Link>
                  ) : (
                    contador?.attachment?.name || anexoName()
                  )}
                </Box>
              ) : (
                <Box>
                  <input
                    style={{ display: "none" }}
                    accept="image/*, .txt"
                    id="anexos"
                    multiple
                    type="file"
                    onChange={handleAnexo}
                  />
                  <label htmlFor="anexos">
                    <Button
                      variant="outlined"
                      color="primary"
                      component="span"
                      style={{ textTransform: "none" }}
                    >
                      Anexar Contador
                    </Button>
                  </label>
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <CustomSelect
                options={Array.from(StatusColeta.values())}
                value={
                  contador?.status_coleta
                    ? StatusColeta.get(contador?.status_coleta)
                    : ""
                }
                label="Status da Coleta"
                onChange={(value) =>
                  setContador({
                    ...contador,
                    status_coleta:
                      value === "Pendente" ? "PENDENTE" : "CONCLUIDO",
                  })
                }
              />
            </Grid>
            <Grid item md>
              <TextField
                label="Justificativa"
                variant="outlined"
                value={contador?.justificativa}
                onChange={(e) =>
                  setContador({ ...contador, justificativa: e.target.value })
                }
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        </BoxTitle>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          style={{ textTransform: "none" }}
          onClick={() => props.onClose(contador)}
        >
          Aplicar Edição
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const FormContadores: FC<{ onSubmit?: () => void }> = (props) => {
  const [contratos, setContratos] = useState<any[]>([]);
  const [contratoSelecionando, setContratoSelecionando] = useState<any | null>(
    null
  );
  const [equipamentosContrato, setEquipamentosContrato] = useState<any[]>([]);
  const [contadores, setContadores] = useState<any[]>([]);
  const [requestLog, setRequestLog] = useState<any[]>([]);
  const [tipoBusca, setTipoBusca] = useState("Nº do Contrato");
  const [valorBusca, setValorBusca] = useState("");

  const handleBuscar = () => {
    const filter = { search: valorBusca };
    fetchContratos(
      filter,
      (data: any) => {
        setContratos(data.results);
      },
      (error: any) => {
        console.error(error);
      }
    );
  };

  const loadEquipamentosContrato = (idContrato: number) => {
    getEquipamentosContrato(
      idContrato,
      (data: any) => {
        setEquipamentosContrato(data);
      },
      (error: any) => {
        console.error(error);
      }
    );
  };

  const handleChangeContrato = (idContrato: number) => {
    if (idContrato) {
      const contrato = contratos.find((item) => item.id === idContrato);
      setContratoSelecionando(contrato);
      loadEquipamentosContrato(idContrato);
      setContadores([]);
    } else {
      setContratoSelecionando(null);
      setEquipamentosContrato([]);
    }
  };

  /**
   * Salva os contadores dos equipamentos
   * @param e
   */
  const salvarContadores = (e: any) => {
    const attachments = contadores.map((item) => {
      return {
        attachment: item.attachment,
        id: item.id,
        equipamento_id:
          typeof item.equipamento === "object"
            ? item.equipamento.id
            : item.equipamento,
        modo_coleta: item.modo_coleta,
      };
    });

    const salvarAnexos = () => {
      addAnexos(
        attachments,
        (datas: any[]) => {
          loadEquipamentosContrato(contratoSelecionando.id);
        },
        (reason: any) => {
          const results: any[] = [];
          results.push({
            result: false,
            msg: `Não foi possível salvar o anexo do contador do equipamento ${JSON.stringify(
              reason.response.data
            )}`,
          });
          setRequestLog(results);
          loadEquipamentosContrato(contratoSelecionando.id);
        }
      );
    };

    const handleSuccess = (datas: any[]) => {
      const results: any[] = [];
      datas.forEach((data) => {
        let serie = data.equipamento?.serie;
        if (serie === undefined) {
          const eqp = equipamentosContrato.find(
            (item) => item.equipamento.id === data.equipamento
          );
          serie = eqp?.equipamento?.serie;
          attachments.forEach((item) => {
            if (!item.id && item.equipamento_id === data.equipamento) {
              item.id = data.id;
            }
          });
        }
        results.push({
          result: true,
          msg: `Contador do equipamento ${serie} salvo com sucesso.`,
        });
      });
      setContadores([]);
      setRequestLog(results);

      if (attachments.some((item) => item.attachment)) {
        salvarAnexos();
      } else {
        loadEquipamentosContrato(contratoSelecionando.id);
      }
    };

    const handleFail = (error: any) => {
      const results: any[] = [];
      results.push({
        result: false,
        msg: `Não foi possível salvar o contador do equipamento ${JSON.stringify(
          error.response.data
        )}`,
      });
      setRequestLog(results);
      setContadores([]);
      loadEquipamentosContrato(contratoSelecionando.id);
    };

    saveContadores(contadores, handleSuccess, handleFail);
  };

  return (
    <Box mb={8}>
      <Grid container spacing={4}>
        <Grid item container spacing={2} alignItems="center">
          <Grid item container>
            <CustomRadioGroup
              options={["Nº do Contrato", "SAP"]}
              value={tipoBusca}
              onChange={setTipoBusca}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              label={tipoBusca}
              value={valorBusca}
              onChange={(e) => setValorBusca(e.target.value)}
              onKeyDown={(e) => {
                e.key === "Enter" && handleBuscar();
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="outlined"
              color="primary"
              style={{ textTransform: "none" }}
              fullWidth
              onClick={handleBuscar}
            >
              Buscar
            </Button>
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <SelecaoContrato
              contratos={contratos}
              onContratoSelected={handleChangeContrato}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <EquipamentosContrato
            contrato={contratoSelecionando}
            equipamentosContrato={equipamentosContrato}
            onEditContador={setContadores}
          />
        </Grid>
      </Grid>
      <Box mt={3} textAlign="right">
        <Button
          variant="outlined"
          color="primary"
          style={{ textTransform: "none" }}
          onClick={salvarContadores}
        >
          Salvar Contadores
        </Button>
      </Box>
      <CustomSnackbar results={requestLog} onClose={() => setRequestLog([])} />
    </Box>
  );
};

export default FormContadores;
