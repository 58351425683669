import axiosInstance from "../../../services/AxiosConfig";

/**
 * Cadastra um novo contrato
 * @param contrato dados do contrato
 * @param onSuccess Callback de sucesso
 * @param onFail Callback de falha
 */
export function createContrato(contrato: any, onSuccess: any, onFail: any) {
    async function create() {
        return await axiosInstance.post('/api/contratos/', contrato);
    }

    const result = create();
    result.then(response => {
        const { data } = response;
        onSuccess(data);

    }).catch((response => {
        onFail(response);
    }));
}

/**
 * Cadastra um novo contrato
 * @param contrato dados do contrato
 * @param onSuccess Callback de sucesso
 * @param onFail Callback de falha
 */
export function updateContrato(contrato: any, onSuccess: any, onFail: any) {
    async function update() {
        return await axiosInstance.put(`/api/contratos/${contrato?.id}/`, contrato);
    }

    const result = update();
    result.then(response => {
        const { data } = response;
        onSuccess(data);

    }).catch((response => {
        onFail(response);
    }));
}

/**
 * 
 * @param filter filtro
 * @param page pagina
 * @param onSuccess Callback de sucesso
 * @param onFail Callback de falha 
 * @param pageSize 
 */
export function fetchContratos(filter: any,
    page: number,
    onSuccess: any,
    onFail: any,
    pageSize: number = 15
) {
    const params = { ...filter, page: page, page_size: pageSize };
    async function fetch() {
        return await axiosInstance.get('api/contratos', { params: params });
    }

    const result = fetch();
    result.then(response => {
        const { data } = response;
        onSuccess(data);

    }).catch((response => {
        onFail(response);
    }));
}

/**
 * Carrega os dados de um contratos
 * @param id id do contrato
 * @param onSuccess Callback de sucesso
 * @param onFail Callback de falha
 */
export function getContrato(id: number, onSuccess: any, onFail: any) {
    async function get() {
        return await axiosInstance.get(`/api/contratos/${id}`);
    }

    const result = get();
    result.then(response => {
        const { data } = response;
        onSuccess(data);

    }).catch((response => {
        onFail(response);
    }));
}

/**
 * Carrega a lista de equipamentos de um contrato
 * @param id id do contrato
 * @param onSuccess Callback de sucesso
 * @param onFail Callback de falha
 */
export function getEquipamentosContrato(id: number, onSuccess: any, onFail: any) {
    async function fetch() {
        return await axiosInstance.get(`/api/contratos/${id}/equipamentos/`);
    }

    const result = fetch();
    result.then(response => {
        const { data } = response;
        onSuccess(data);

    }).catch((response => {
        onFail(response);
    }));
}

/**
 * Adiciona um equipamento a um contrato
 * @param idContrato id do contrato
 * @param idEquipamento id do equipamento
 * @param idCliente id do cliente
 * @param onSuccess callback de sucesso
 * @param onFail callback de falha
 */
export function addEquipamento(idContrato: number, idEquipamento: number, idCliente: number, onSuccess: any, onFail: any) {
    async function add() {
        return await axiosInstance.post(`/api/contratos/${idContrato}/equipamentos/`,
            { equipamento: idEquipamento, cliente: idCliente });
    }

    const result = add();
    result.then(response => {
        const { data } = response;
        onSuccess(data);

    }).catch((response => {
        onFail(response);
    }));
}

/**
 * Atualiza os dados do equipamento vincualdo a um contrato
 * @param idContrato id do contrato
 * @param details objeto com os campos a serem atualizados
 * @param onSuccess callback de sucesso
 * @param onFail callback de falha
 */
export function saveEquipmentDetails(idContrato: number, details: any, onSuccess: any, onFail: any) {
    async function save() {
        const params = {
            id: details.id,
            taxa_fixa: typeof(details.taxa_fixa) === 'string' ? details.taxa_fixa.replace(',','.') : undefined,
            click_pb: typeof(details.click_pb) === 'string' ? details.click_pb.replace(',','.') : undefined,
            click_color: typeof(details.click_color) === 'string' ? details.click_color.replace(',','.') : undefined,
            logradouro: details.logradouro,
            bairro: details.bairro,
            estado: details.estado,
            municipio: details.municipio,
            ender_ins_logradouro: details.ender_ins_logradouro,
            ender_ins_bairro: details.ender_ins_bairro,
            ender_ins_estado: details.ender_ins_estado,
            ender_ins_municipio: details.ender_ins_municipio,            
        };
        return await axiosInstance.patch(`/api/contratos/${idContrato}/equipamentos/${details.id}/`, params);
    }

    const result = save();
    result.then(response => {
        const { data } = response;
        onSuccess(data);

    }).catch((response => {
        onFail(response);
    }));
}

