import { Theme, makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme: Theme) => ({
    options: {
        padding: '0 16px',
        height: '1.4rem',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            background: '#f18458'
        }
    },
    tableCell: {
        '&:hover': {
            backgroundColor:'lightgray',
            opacity:0.5,
            cursor: 'pointer'
        }
    }
}));

export default useStyles;