import { Theme, makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        '& .MuiFormControl-root': {
            minWidth: 150,
            margin: theme.spacing(1)
        },
        '& .MuiButtonBase-root': {
            verticalAlign: 'bottom',
            marginBottom: 10
        }
    },
    assignedToMe: {

    },
    table: {
        minWidth: 650,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },

}));

export default useStyles