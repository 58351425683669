import React from 'react'
import { Container } from '@material-ui/core'

/**
 * Home page
 */
const Dashboard: React.FC = () => {
    return (
        <Container>
            <h1>Dashboard</h1>
        </Container>
    )
}

export default Dashboard