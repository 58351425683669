import { Box, MenuItem, Select, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { FilterChipOption, FilterSelectProps } from './FilterSelectProps';


const FilterSelect: React.FC<FilterSelectProps> = (props) => {

    const OPTION_ID='sd_filterOption'
    const [open, setOpen] = useState(true);
    const [selectedOption, setSelectedOption] = useState(props.value)
    const options = props.options;

    function handleChange(e: any) {
        const index = e.target.value as number;
        const option = props.options.find(x => x.value === index)

        setSelectedOption(option);
        endEdit(option as FilterChipOption);
    }

    function endEdit(value: FilterChipOption) {
        if (value) {
            props.onChangeValue(props.label, value);
        }
    }

    const renderOptions = options.map((item) => {
        return <MenuItem id={OPTION_ID} value={item.value} key={item.value}>
            {item.label}
        </MenuItem>
    });

    const handleClose = (e: any) => {
        if (e.target.id !== OPTION_ID ||
            e.target.innerText === selectedOption.label) {
            endEdit(selectedOption);
            setOpen(false);
        }
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <Box>
            <Typography variant="caption" color="primary">
                {`${props.label}: `}
            </Typography>
            <Select id={props.label + 'Select'}
                value={selectedOption.value || ''}
                color="primary"
                open={open}
                onOpen={handleOpen}
                onClose={handleClose}
                style={{fontSize: 'inherit'}}
                onChange={handleChange}>
                   {renderOptions}
            </Select>
        </Box>
    );

}

export default FilterSelect;