import { Box, Button, Grid, TextField } from '@material-ui/core';
import React, { FC, useEffect, useState, useCallback } from 'react';
import AddGroupDlg from '../domain/Client/components/AddGroupDlg';
import { Cliente } from '../domain/Client/models';
import { fetchColaboradores } from '../domain/User/services/UserService';
import {
  fetchCidades,
  fetchEstados,
  fetchGruposComerciais,
} from '../services/BaseService';
import CustomSelect from './CustomSelect';
import MaskedInput from 'react-text-mask';
import * as mask from './Masks';
import Autocomplete from '@material-ui/lab/Autocomplete';

const FormAddress: FC<{
  address: any | Cliente;
  onChange: (values: any | Cliente) => void;
}> = ({ address, onChange }) => {
  const [data, setData] = useState<any | Cliente | null>(null);
  const [estados, setEstados] = useState<any[] | null>(null);
  const [municipios, setMunicipios] = useState<any[] | null>(null);
  const [gruposComerciais, setGruposComerciais] = useState<any[] | null>(null);
  const [newGroup, setNewGroup] = useState<any>(null);
  const [colaboradores, setColaboradores] = useState<any[] | null>(null);
  const [openAddGroup, setOpenAddGroup] = useState(false);

  useEffect(() => {
    setData(address);
  }, [address]);

  /**
   * Altera um valor dos dados e dispara callback de onChange
   * @param field campo de data modificado
   */
  const handleChangeData = useCallback((field: any) => {
    const values = { ...data, ...field };
    setData(values);
    onChange(values);
  }, [data, onChange]);

  /** Carrega os estados */
  useEffect(() => {
    if (estados === null && data) {
      fetchEstados(
        (result: any[]) => {
          setEstados([...result]);
          setData({
            ...data,
            estado: result?.find((item) => item.uf === data.estado),
          });
        },
        (error: any) => {
          setEstados([]);
          console.error(error);
        }
      );
    }
  }, [estados, data]);

  /** Carregas as cidades */
  useEffect(() => {
    if (data?.estado) {
      const uf =
        typeof data?.estado === 'object' ? data?.estado.uf : data?.estado;
      fetchCidades(
        uf,
        (result: any[]) => {
          setMunicipios(result);
        },
        (error: any) => {
          setMunicipios([]);
          console.error(error);
        }
      );
    }
  }, [data?.estado]);

  /** Carrega lista de colaboradores*/
  useEffect(() => {
    if (colaboradores === null && data) {
      fetchColaboradores(
        (result: any[]) => {
          setColaboradores([...result]);
          setData({
            ...data,
            responsavel_comercial: result.find(
              (item) => item.id === data.responsavel_comercial
            ),
          });
        },
        (error: any) => {
          setColaboradores([]);
          console.error(error);
        }
      );
    }
  }, [colaboradores, data]);

  /** Carrega lista de Grupos comerciais*/
  useEffect(() => {
    if (gruposComerciais === null || newGroup !== null) {
      fetchGruposComerciais(
        (result: any[]) => {
          setGruposComerciais([...result]);
          if (newGroup) {
            handleChangeData({ ...data, grupo_comercial: newGroup });
          }
          setNewGroup(null);
        },
        (error: any) => {
          setGruposComerciais([]);
          console.error(error);
        }
      );
    }
  }, [gruposComerciais, newGroup, data, handleChangeData]);

  function getSelectedItem(list: any[] | null, value: string) {
    let item = null;
    if (list) {
      const index = list?.findIndex((item) => item.nome === value);
      if (index > -1) {
        item = list[index];
      }
    }
    return item;
  }

  function handleChangeEstado(event: any, value: string) {
    setData({ ...data, municipio: null });
    const item = getSelectedItem(estados, value);
    if (item) {
      handleChangeData({ estado: item });
    }
  }

  function handleChangeCidade(event: any, value: string) {
    const item = getSelectedItem(municipios, value);
    if (item) {
      handleChangeData({ municipio: item });
    }
  }

  function handleChangeResponsavelComercial(responsavel: string) {
    const item = getSelectedItem(colaboradores, responsavel);
    if (item) {
      handleChangeData({ responsavel_comercial: item });
    }
  }

  function handleChangeGrupoComercial(grupo: string) {
    const item = getSelectedItem(gruposComerciais, grupo);
    if (item) {
      handleChangeData({ grupo_comercial: item });
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} md={12}>
        <TextField
          label="Logradouro"
          value={data?.logradouro || ''}
          fullWidth
          onChange={(e) => handleChangeData({ logradouro: e.target.value })}
          InputLabelProps={{ shrink: data?.logradouro ? true : false }}
        />
      </Grid>
      <Grid item sm={12} md={12}>
        <TextField
          label="Bairro"
          value={data?.bairro || ''}
          fullWidth
          onChange={(e) => handleChangeData({ bairro: e.target.value })}
          InputLabelProps={{ shrink: data?.bairro ? true : false }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MaskedInput
          mask={mask.cep}
          value={data?.cep || ''}
          onChange={(e) =>
            handleChangeData({ cep: e.target.value.replace(/\D+/g, '') })
          }
          guide={false}
          render={(ref, props) => (
            <TextField
              label="CEP"
              fullWidth
              inputRef={ref}
              {...props}
              InputLabelProps={{ shrink: data?.cep ? true : false }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MaskedInput
          mask={mask.phone}
          value={data?.telefone || ''}
          onChange={(e) =>
            handleChangeData({ telefone: e.target.value.replace(/\D+/g, '') })
          }
          guide={false}
          render={(ref, props) => (
            <TextField
              label="Telefone (Principal/Tronco)"
              fullWidth
              inputRef={ref}
              {...props}
              InputLabelProps={{ shrink: data?.telefone ? true : false }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          options={estados || []}
          getOptionLabel={(option) => option.nome}
          onInputChange={handleChangeEstado}
          renderInput={(params) => (
            <TextField {...params} label="Estado" variant="outlined" margin="dense" style={{marginTop: '12px'}} required/>
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
          <Autocomplete
          options={municipios || []}
          getOptionLabel={(option) => option.nome}
          onInputChange={handleChangeCidade}
          renderInput={(params) => (
            <TextField {...params} label="Cidade" variant="outlined" margin="dense" style={{marginTop: '12px'}} required/>
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box pt={2.5}>
          <CustomSelect
            label="Responsável Comercial"
            value={data?.responsavel_comercial?.nome || ''}
            options={
              colaboradores?.map((item) => item.nome || item.username) || []
            }
            onChange={handleChangeResponsavelComercial}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box pt={2.5} textAlign="right">
          <CustomSelect
            label="Grupo Comercial"
            value={data?.grupo_comercial?.nome || data?.grupo_comercial || ''}
            options={gruposComerciais?.map((item) => item.nome) || []}
            onChange={handleChangeGrupoComercial}
          />
          <Button
            variant="contained"
            color="primary"
            style={{ textTransform: 'none' }}
            size="small"
            onClick={() => {
              setOpenAddGroup(true);
            }}
          >
            Novo Grupo
          </Button>
          <AddGroupDlg
            onAdd={setNewGroup}
            open={openAddGroup}
            onClose={() => setOpenAddGroup(false)}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default FormAddress;
