import {useState, createContext, ReactNode} from 'react'

type ContextProps = {
  loading: boolean
  setLoading: (value: boolean) => void
}

export const LoadingContext = createContext({
  loading: false
} as ContextProps)

type ProviderProps = {
  children: ReactNode
}

export function LoadingContextProvider({children}: ProviderProps) {
  const [loading, setLoading] = useState(false)

  const providerValue = {
    loading,
    setLoading
  }

  return (
    <LoadingContext.Provider value={providerValue}>
      {children}
    </LoadingContext.Provider>
  )
}