/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemText, makeStyles, Paper, TextField } from '@material-ui/core';
import { DataGrid, GridColDef, GridRowId } from '@material-ui/data-grid';
import { RemoveCircleOutline } from '@material-ui/icons';
import React, { FC, useEffect, useState } from 'react';
import BoxTitle from '../../../components/BoxTitle';
import CustomSelect from '../../../components/CustomSelect';
import { fetchCidades } from '../../../services/BaseService';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginBottom: theme.spacing(1),
        }
    },
    cardSpace: {
        '& > *': {
            margin: theme.spacing(2),
        }
    }
}));

const EnderecoCard = (props: any) => {
    const [data, setData] = useState<any>(props.endereco);
    const [municipios, setMunicipios] = useState<any[] | null>(null);
    const classes = useStyles();

    /** Carregas as cidades */
    useEffect(() => {
        if (data?.estado?.uf) {
            fetchCidades(data?.estado.uf, (result: any[]) => {
                setMunicipios(result);
            }, (error: any) => {
                setMunicipios([]);
                console.error(error);
            })
        }
    }, [data?.estado]);

    function getSelectedItem(list: any[] | null, value: string) {
        let item = null;
        if (list) {
            const index = list?.findIndex(item => item.nome === value);
            if (index > -1) {
                item = list[index];
            }
        }
        return item;
    }

    function handleChangeEstado(estado: string) {
        setData({ ...data, municipio: null });
        const item = getSelectedItem(props.estados, estado)
        if (item) {
            setData({ ...data, estado: item });
        }
    }

    function handleChangeCidade(municipio: string) {
        const item = getSelectedItem(municipios, municipio)
        if (item) {
            setData({ ...data, municipio: item });
        }
    }

    function findEstado(uf: string) {
        let estado = undefined;
        const index = props.estados.findIndex((item: any) => item.uf === uf);
        if (index > -1) {
            estado = props.estados[index];
        }
        return estado;
    }

    /** Dispara evento de onChange a qualquer mudança dos dados */
    useEffect(() => {
        props.onChange(data);
    }, [data])

    return (
        <BoxTitle title={props.title}>
            <Box p={4} mt={-5} className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField label="Logradouro" value={data?.logradouro || ''} fullWidth
                            onChange={(e) => setData({ ...data, logradouro: e.target.value })}
                            InputLabelProps={{ shrink: data?.logradouro !== undefined }} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Bairro" value={data?.bairro || ''} fullWidth
                            onChange={(e) => setData({ ...data, bairro: e.target.value })}
                            InputLabelProps={{ shrink: data?.bairro !== undefined }} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CustomSelect label="Estado" value={findEstado(data?.estado?.uf)?.nome || ''}
                            options={props.estados?.map((item: any) => item.nome) || []}
                            onChange={handleChangeEstado} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CustomSelect label="Cidade" value={data?.municipio?.nome || ''}
                            options={municipios?.map(item => item.nome) || []}
                            onChange={handleChangeCidade} />
                    </Grid>
                </Grid>
            </Box>
        </BoxTitle>
    )
}

const SelectEquipamentoDlg = (props: any) => {

    const [selected, setSelected] = useState<any[]>(props.equipamentosSelecionados || []);
    const columns: GridColDef[] = [
        {
            field: 'id',
            hide: true
        },
        {
            field: 'serie',
            headerName: 'Série',
            width: 150,
        },
        {
            field: 'modelo_nome',
            headerName: 'Modelo',
            width: 150,
        }
    ];

    useEffect(() => {
        setSelected(props.equipamentosSelecionados);
    }, [props.equipamentosSelecionados])

    const handleSelection = (param: GridRowId[]) => {
        setSelected([...param])
    }

    const handleCancel = () => {
        props.onClose(props.equipamentosSelecionados);
        setSelected([]);
    }

    const handleSelect = () => {
        props.onClose(selected);
        setSelected([]);
    }

    return (
        <Dialog open={props.open} onClose={handleCancel}>
            <DialogTitle>
                Selecione o equipamento para o endereço
            </DialogTitle>
            <DialogContent>
                <DataGrid columns={columns} rows={props.equipamentos?.map((item: any) => {
                    return { ...item, modelo_nome: item.modelo.nome }
                })} autoHeight disableColumnMenu hideFooterSelectedRowCount
                    density="compact" onSelectionModelChange={handleSelection}
                    hideFooter selectionModel={selected} 
                    disableMultipleSelection/>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="secondary"
                    onClick={handleCancel}>Cancelar</Button>
                <Button variant="contained" color="primary"
                    onClick={handleSelect}>Selecionar</Button>
            </DialogActions>
        </Dialog>
    )
}

const EnderecoEquipamentoCard: FC<{
    onChange: (value: any) => void,
    onRemoveItem: (e: any) => void,
    equipamentosContrato: any[],
    estados: any[],
    value: any
}> = ({ onChange, onRemoveItem, equipamentosContrato, estados, value }) => {

    const [equipamentos, setEquipamentos] = useState<any[]>([...equipamentosContrato]);
    const [enderecos, setEnderecos] = useState<any>(value);
    const [openDlg, setOpenDlg] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        if (equipamentosContrato) {
            setEquipamentos([...equipamentosContrato]);
        }
    }, [equipamentosContrato])

    const handleCloseDlg = (selecteds: any[]) => {
        if (selecteds.length > 0) {
            const list = equipamentos.filter(item => selecteds.includes(item.id));
            const end = { ...enderecos, equipamento: list[0] };
            setEnderecos(end);
            onChange(end);
        }
        setOpenDlg(false);
    }

    return (
        <Paper>
            <Box textAlign="right">
                <IconButton onClick={onRemoveItem}>
                    <RemoveCircleOutline />
                </IconButton>
            </Box>
            <Box display="flex" className={classes.cardSpace}>
                <EnderecoCard estados={estados} endereco={enderecos.atendimento}
                    title="Endereço de Atendimento"
                    onChange={(value: any) => {
                        const newEnde = { ...enderecos, atendimento: value };
                        setEnderecos(newEnde);
                        onChange(newEnde);
                    }} />
                <EnderecoCard estados={estados} endereco={enderecos.instalacao}
                    title="Endereço de Instalação"
                    onChange={(value: any) => {
                        const newEnde = { ...enderecos, instalacao: value };
                        setEnderecos(newEnde);
                        onChange(newEnde);
                    }} />
                <Box m={4} minWidth="310px">
                    <Button color="primary" variant="outlined"
                        style={{ textTransform: 'none', marginBottom: 16 }}
                        onClick={() => setOpenDlg(true)}>
                        Selecionar Equipamentos deste endereço
                    </Button>
                    <BoxTitle title="Equipamentos">
                        <List>
                            <ListItem dense>
                                <ListItemText primary="Série" disableTypography style={{ fontWeight: 'bolder' }} />
                                <ListItemText primary="Modelo" disableTypography style={{ fontWeight: 'bolder' }} />
                            </ListItem>

                            <ListItem dense key={enderecos?.equipamento?.serie}>
                                <ListItemText primary={enderecos?.equipamento?.serie} />
                                <ListItemText primary={enderecos?.equipamento?.modelo.nome} />
                            </ListItem>
                            {!enderecos?.equipamento &&
                                <Box color="red">Não existem equipamentos associados a estes endereços</Box>}

                        </List>
                    </BoxTitle>
                </Box>
            </Box>

            <SelectEquipamentoDlg open={openDlg} onClose={handleCloseDlg}
                equipamentos={equipamentos || []}
                equipamentosSelecionados={enderecos?.equipamentos?.map((item: any) => item.id) || []} />
        </Paper>
    )
}

export default EnderecoEquipamentoCard;