import axiosInstance from "../../../services/AxiosConfig";

/**
 * Carrega a lista de clientes cadastrado
 * @param filter - parametros para filtrar um cliente
 * @param page - número da pagina
 * @param onSuccess Callback de sucesso
 * @param onFail Callback de falha
 * @param pageSize - numero de resultados por página
 */
export function fetchClients(
    filter: any,
    page: number,
    onSuccess: any,
    onFail: any,
    pageSize: number = 15
) {
    const params = { ...filter, page: page, page_size: pageSize };
    async function fetch() {
        return await axiosInstance.get('api/clientes', { params: params });
    }

    const result = fetch();
    result.then(response => {
        const { data } = response;
        onSuccess(data);

    }).catch((response => {
        onFail(response);
    }));
}

/**
 * Retorna os dados de um cliente
 * @param id Id do Cliente
 * @param onSuccess Callback de sucesso
 * @param onFail Callback de falha
 */
export function getClient(id: number, onSuccess: any, onFail: any) {
    async function getClient() {
        return await axiosInstance.get(`api/clientes/${id}`);
    }

    const result = getClient();
    result.then(response => {
        const { data } = response;
        onSuccess(data);

    }).catch((response => {
        onFail(response);
    }));
}

/**
 * Cadastra um novo cliente
 * @param cliente dados do cliente
 * @param onSuccess Callback de sucesso
 * @param onFail Callback de falha
 */
export function createClient(cliente:any, onSuccess: any, onFail: any) {
    async function create() {
        return await axiosInstance.post('/api/clientes/', cliente);
    }

    const result = create();
    result.then(response => {
        const { data } = response;
        onSuccess(data);

    }).catch((response => {
        onFail(response);
    }));
}

/**
 * Atualiza os dados de um cliente
 * @param id Id do Cliente
 * @param onSuccess Callback de sucesso
 * @param onFail Callback de falha
 */
 export function updateClient(id: number | string, params: any, onSuccess: any, onFail: any) {
    async function update() {
        return await axiosInstance.put(`api/clientes/${id}/`, params);
    }

    const result = update();
    result.then(response => {
        const { data } = response;
        onSuccess(data);

    }).catch((response => {
        onFail(response);
    }));
}

export const tipoClienteMap = new Map([
    ['CLIF', 'Cliente Final'],
    ['REVE', 'Revenda']
])

export const contextoEmpresarialMap = new Map([
    ['FILI', 'Filial'],
    ['MATR', 'Matriz']
])