import React, { useState } from 'react'
import { Button, TextField, Paper, Box, Snackbar, Slide } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Redirect, useHistory } from 'react-router-dom'
import useStyles from './style'
import { authService } from '../../services/Auth'


/**
 * Login page
 */
const Login: React.FC = () => {

    const classes = useStyles();
    const history = useHistory()
    const initialFormData = Object.freeze({
        username: '',
        password: ''
    })
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState('')

    const [formData, setFormData] = useState(initialFormData)

    if (localStorage.getItem('access_token')) {
        return <Redirect to="/home" />
    }

    const handleClose = () => {
        setOpen(false)
    }

    /**
     * Function to handle the onClick event of the "Entrar" button
     */
    const handleSubmit = (e: any) => {
        e.preventDefault()
        authService.obtainToken(formData,
            () => { history.push('/home') },
            (err: any) => { 
                if(err.response.status === 401) {
                    setMessage('Credenciais inválidas')    
                } else {
                    setMessage('Erro ao fazer login')
                }
                setOpen(true)
                console.log('Catched', err.message); 
            })
    }

    /**
     * Function to handle the onChange event of the text fields
     */
    const handleChange = (e: any) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        })
    }

    return (
        <form noValidate>
            <Paper elevation={10} className={classes.paper}>
                <Box textAlign="center" px={4}>
                    <img src="/logo192.png" alt='SMHUB'/>
                    <TextField id="username" name="username"
                        className={classes.formField}
                        label="Usuário"
                        variant="outlined"
                        onChange={handleChange}
                        fullWidth required />
                    <TextField id="password" name="password"
                        className={classes.formField}
                        label="Senha"
                        type="password"
                        variant="outlined"
                        onChange={handleChange}
                        fullWidth required />
                    <Button
                        fullWidth
                        onClick={handleSubmit}
                        className={classes.loginBtn}
                        type="submit"
                        color='primary'
                        variant='contained'>
                        Entrar
                    </Button>
                    <Snackbar 
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        TransitionComponent={Slide}
                    >
                        <Alert onClose={handleClose} severity="error" variant="filled">
                            {message}
                        </Alert>
                    </Snackbar>
                </Box>
            </Paper>
        </form>
    )
}

export default Login