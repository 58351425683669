import axios, { AxiosError, AxiosResponse } from 'axios';
import axiosInstance from '../../../../services/AxiosConfig';

const OrderStatusMap = new Map([
  [1, 'Não Liberado'],
  [2, 'Em processamento'],
  [3, 'Liberado'],
]);

function getOrderStatus(value: any) {
  return OrderStatusMap.get(value);
}

function getOrderStatusKey(value: any) {
  const entries = Array.from(OrderStatusMap.entries());
  const keys = entries
    .filter(([k, v]) => {
      return v === value;
    })
    .map(([k]) => k);
  return keys.pop();
}

const OrderItemStatusMap = new Map([
  [1, 'Não Liberado'],
  [2, 'Negado'],
  [3, 'Liberado'],
]);

function getOrderItemStatus(value: any) {
  return OrderItemStatusMap.get(value);
}

const OrderReleaseStatusMap = new Map([
  [1, 'Nova'],
  [2, 'Ordem e fornecimento gerado'],
  [3, 'Fornecimento autorizado'],
  [4, 'Fornecimento liberado'],
  [5, 'Nota fiscal emitida'],
  [6, 'Nota fiscal rejeitada pela SEFAZ'],
  [7, 'Ordem finalizada'],
  [8, 'Nota fiscal autorizada'],
  [9, 'Aguardando retorno de volumes da Enivix'],
  [10, 'Processando retorno de volume'],
]);

function getOrderReleaseStatus(value: any) {
  return OrderReleaseStatusMap.get(value);
}

function getOrderItemStatusKey(value: any) {
  const entries = Array.from(OrderItemStatusMap.entries());
  const keys = entries
    .filter(([k, v]) => {
      return v === value;
    })
    .map(([k]) => k);
  return keys.pop();
}

const getTonerOrders = (
  page: number = 0,
  pageSize: number = 0,
  sapID: number = 0,
  status: number = 0,
  filial: number = 0,
  analista: number = 0
) => {
  const res = axiosInstance.get(`api/solicitacoes/material/`, {
    params: {
      page: page > 0 ? page : '',
      page_size: pageSize > 0 ? pageSize : '',
      cliente: sapID > 0 ? sapID : '',
      status: status > 0 ? status : '',
      filial: filial > 0 ? filial : '',
      analista: analista > 0 ? analista : '',
    },
  });

  return res;
};

/**
 * Carrega os detalhes de uma solicitação de toner
 * @param id
 * @param onSuccess
 * @param onFail
 */
function getTonerOrder(id: any, onSuccess: any, onFail: any) {
  async function get() {
    return await axiosInstance.get(`api/material-orders/${id}`);
  }

  const result = get();
  result
    .then((response: AxiosResponse) => {
      onSuccess(response.data);
    })
    .catch((reason: any) => {
      onFail(reason);
    });
}

/**
 * Carrega as ordens de venda de um pedido
 * @param id
 * @param onSuccess
 * @param onFail
 */
function getOrders(id: any, onSuccess: any, onFail: any) {
  async function get() {
    return await axiosInstance.get(`api/material-orders/${id}/orders`);
  }

  const result = get();
  result
    .then((response: AxiosResponse) => {
      onSuccess(response.data);
    })
    .catch((reason: AxiosError) => {
      onFail(reason);
    });
}

/**
 * Atualiza uma solicitação de material
 * @param orderId
 * @param params
 * @param onSuccess
 * @param onFail
 */
function updateTonerOrder(
  orderId: any,
  params: any,
  onSuccess: any,
  onFail: any
) {
  async function save() {
    return await axiosInstance.patch(
      `api/solicitacoes/material/${orderId}/`,
      params
    );
  }

  const result = save();
  result
    .then((response: AxiosResponse) => {
      onSuccess(response.data);
    })
    .catch((reason: any) => {
      onFail(reason);
    });
}

async function saveItem(params: any) {
  return await axiosInstance.patch(
    `/api/material-order-items/${params.id}/`,
    params
  );
}

/**
 * Atualiza um item de uma solicitação de toner
 * @param params dados do toner
 * @param onSuccess callback de sucesso
 * @param onFail callback de falha
 */
function updateOrderItem(params: any, onSuccess: any, onFail: any) {
  const result = saveItem(params);
  result
    .then((response: AxiosResponse) => {
      onSuccess(response.data);
    })
    .catch((reason: any) => {
      onFail(reason);
    });
}

/**
 * Atualiza uma lista de itens de uma solicitação de material
 * @param orderItems lista de itens
 * @param onSuccess callback de sucesso
 * @param onFail callback de falha
 */
function updateAllOrderItems(orderItems: any[], onSuccess: any, onFail: any) {
  const orders = orderItems.map((item) => {
    return saveItem(item);
  });
  axios
    .all(orders)
    .then((responses) => {
      const results = responses.map((item: any) => item.data);
      onSuccess(results);
    })
    .catch((reason) => {
      onFail(reason);
    });
}

/**
 * Retorna os dados de uma requisição
 * @param url url do endpoint
 * @param onSuccess Callback de sucesso
 * @param onFail Callback de erro
 * @param filter Filtro de busca
 */
function fetch(url: string, onSuccess: any, onFail: any, filter?: any) {
  async function fetchData() {
    return await axiosInstance.get(url, { params: filter });
  }

  const result = fetchData();
  result
    .then((response) => {
      onSuccess(response.data);
    })
    .catch((response) => onFail(response));
}

/**
 * Carrega a lista de tipos de ordem
 * @param onSuccess
 * @param onFail
 */
export function fetchTipoOrdem(onSuccess: any, onFail: any) {
  fetch('api/tipo-ordem/', onSuccess, onFail);
}

/**
 * Carrega a lista de atividades do setor
 * @param onSuccess
 * @param onFail
 */
export function fetchSetorAtividade(onSuccess: any, onFail: any) {
  fetch('api/setor-atividade/', onSuccess, onFail);
}

/**
 * Carrega a lista de motivos da Ordem
 * @param onSuccess
 * @param onFail
 */
export function fetchMotivoOrdem(onSuccess: any, onFail: any) {
  fetch('api/motivo-ordem/', onSuccess, onFail);
}

/**
 * Carrega a lista de centros de fornecimento
 * @param onSuccess
 * @param onFail
 */
export function fetchCentroFornecimento(onSuccess: any, onFail: any) {
  fetch('api/centro-fornecimento/', onSuccess, onFail);
}

/**
 * Carrega a lista de CFOP
 * @param onSuccess
 * @param onFail
 */
export function fetchCFOP(onSuccess: any, onFail: any) {
  fetch('api/cfop/', onSuccess, onFail);
}

/**
 * Carrega a lista de Transportadoras
 * @param onSuccess
 * @param onFail
 */
export function fetchTransportadoras(onSuccess: any, onFail: any) {
  fetch('api/transportadoras/', onSuccess, onFail);
}

/**
 * Retorna salva os dados da ordem de transporte
 * @param idSolicitacao id da solicitaçao de material
 * @param params dados da ordem de transporte
 * @param onSuccess Callback de sucesso
 * @param onFail Callback de erro
 */
export function createSalesOrders(
  idSolicitacao: any,
  params: any,
  onSuccess: any,
  onFail: any
) {
  async function save() {
    return await axiosInstance.post(
      `api/material-orders/${idSolicitacao}/build-sales-orders/`,
      params
    );
  }

  const result = save();
  result
    .then((response) => {
      onSuccess(response.data);
    })
    .catch((error) => {
      console.log('Console do services')
      console.log(error.response.data)
      onFail(error)
    });
}

export function updatePreOrdem(
  idOrdem: any,
  params: any,
  onSuccess: any,
  onFail: any
) {
  async function save() {
    return await axiosInstance.patch(
      `api/orders/${idOrdem}/`,
      params
    );
  }

  const result = save();
  result
    .then((response) => {
      onSuccess(response.data);
    })
    .catch((response) => onFail(response));
}

/**
 * Carrega as ordens de uma solicitação
 * @param idSolicitação id da solicitação
 * @param onSuccess
 * @param onFail
 */
export function fetchOrdemTransporte(
  idSolicitacao: any,
  onSuccess: any,
  onFail: any
) {
  fetch(`api/solicitacoes/${idSolicitacao}/ordem/`, onSuccess, onFail);
}

/**
 * Carrega os dados da solicitação
 * @param idSolicitação id da solicitação
 * @param onSuccess
 * @param onFail
 */
export function getDadosSolicitacao(
  idSolicitacao: any,
  onSuccess: any,
  onFail: any
) {
  fetch(`api/solicitacoes/${idSolicitacao}/status/`, onSuccess, onFail);
}

/**
 * Carrega os dados do estoque da solicitação
 * @param idSolicitação id da solicitação
 * @param onSuccess
 * @param onFail
 */
export function consultarEstoque(
  idSolicitacao: any,
  onSuccess: any,
  onFail: any
) {
  fetch(`api/solicitacoes/${idSolicitacao}/estoque/`, onSuccess, onFail);
}

/**
 *
 * @param item Retorna um mapa dos estoques do item em todos os centros de fornecimento.
 * @param onSuccess
 * @param onFail
 */
export function getStockInfo(item: any, onSuccess: any, onFail: any) {
  async function get() {
    return await axiosInstance.get(`api/stock/${item}/`);
  }

  const result = get();
  result
    .then((response: AxiosResponse) => {
      onSuccess(response.data);
    })
    .catch((reason: any) => {
      onFail(reason);
    });
}

export function printOrderLabels(id: any, onSuccess: any, onFail: any) {
  async function post() {
    return await axiosInstance.post(`api/orders/${id}/print-labels/`);
  }

  const result = post();
  result
    .then((response) => {
      onSuccess(response);
    })
    .catch((response) => onFail(response));
}

export function printAllOrders(id: any, onSuccess: any, onFail: any) {
  async function post() {
    return await axiosInstance.post(`api/material-orders/${id}/print-labels/`);
  }

  const result = post();
  result
    .then((response) => {
      onSuccess(response);
    })
    .catch((response) => onFail(response));
}

export function processSalesOrders(id: any, onSuccess: any, onFail: any) {
  async function post() {
    return await axiosInstance.post(
      `api/material-orders/${id}/process-sales-orders/`
    );
  }

  const result = post();
  result
    .then((response) => {
      onSuccess(response);
    })
    .catch((response) => onFail(response));
}

export const TonersService = {
  getTonerOrder,
  getTonerOrders,
  OrderStatusMap,
  getOrderStatusKey,
  getOrderStatus,
  getOrderReleaseStatus,
  OrderItemStatusMap,
  getOrderItemStatusKey,
  getOrderItemStatus,
  updateTonerOrder,
  updateOrderItem,
  updateAllOrderItems,
  getStockInfo,
  getOrders,
  printOrderLabels,
  printAllOrders,
  processSalesOrders,
};
