import axiosInstance from "./AxiosConfig";

/**
 * Retorna os dados de uma requisição 
 * @param url url do endpoint
 * @param onSuccess Callback de sucesso
 * @param onFail Callback de erro
 * @param filter Filtro de busca
 */
function fetch(url: string, onSuccess: any, onFail: any, filter?: any) {
    async function fetchData() {
        return await axiosInstance.get(url, {params: filter});
    }

    const result = fetchData();
    result.then( response => {
        onSuccess(response.data)
    }).catch( response => onFail(response));
}

/**
 * Carrega a lista de estados
 * @param onSuccess 
 * @param onFail 
 */
export function fetchEstados(onSuccess: any, onFail: any) {
    fetch('api/estados/', onSuccess, onFail);
}

/**
 * Lista as cidades de um estado
 * @param estado Estado das cidades
 * @param onSuccess Callback de sucesso
 * @param onFail Callback de falha
 */
export function fetchCidades(estado:string, onSuccess: any, onFail: any) {
    fetch(`api/cidades?estado=${estado}`, onSuccess, onFail);    
}

/**
 * Lista os tipos de segmentos
 * @param canal Direto ou Indireto
 * @param onSuccess Callback de sucesso
 * @param onFail Callback de falha
 */
 export function fetchSegmentos(canal:string, onSuccess: any, onFail: any) {
    fetch(`api/segmentos?tipo=${canal}`, onSuccess, onFail);    
}

/**
 * Carrega a lista de regiões de atendimento
 * @param filial id da Filial
 * @param onSuccess Callback de sucesso
 * @param onFail Callback de falha
 */
 export function fetchRegioesAtendimento(filial: number, onSuccess: any, onFail: any) {
    fetch(`api/regiao-atendimento/?filial=${filial}`, onSuccess, onFail);
}

/**
 * Carrega as filiais da Konica
 * @param onSuccess Callback de sucesso
 * @param onFail Callback de falha
 */
 export function fetchFiliais(onSuccess: any, onFail: any) {
    fetch('api/filiais/', onSuccess, onFail);
}

/**
 * Carrega os Grupos Comerciais da Konica
 * @param onSuccess Callback de sucesso
 * @param onFail Callback de falha
 */
 export function fetchGruposComerciais(onSuccess: any, onFail: any) {
    fetch('api/grupo-comercial/', onSuccess, onFail);
}

/**
 * Cadastra um novo grupo empresarial
 * @param grupo dados do grupo
 * @param onSuccess Callback de sucesso
 * @param onFail Callback de falha
 */
 export function createGrupoEmpresarial(grupo:any, onSuccess: any, onFail: any) {
    async function create() {
        return await axiosInstance.post('/api/grupo-comercial/', grupo);
    }

    const result = create();
    result.then(response => {
        const { data } = response;
        onSuccess(data);

    }).catch((response => {
        onFail(response);
    }));
}

/**
 * Carrega os tipos de contrato
 * @param onSuccess Callback de sucesso
 * @param onFail Callback de falha
 */
 export function fetchTipoContrato(onSuccess: any, onFail: any) {
    fetch('api/tipo-contrato/', onSuccess, onFail);
}

/**
 * Carrega os Status Contratuais
 * @param onSuccess Callback de sucesso
 * @param onFail Callback de falha
 */
 export function fetchStatusContratual(onSuccess: any, onFail: any) {
    fetch('api/status-contratual/', onSuccess, onFail);
}

/**
 * Carrega os Modos de Contabilização
 * @param onSuccess Callback de sucesso
 * @param onFail Callback de falha
 */
 export function fetchModoContabilizacao(onSuccess: any, onFail: any) {
    fetch('api/modo-contabilizacao/', onSuccess, onFail);
}

/**
 * Carrega os lista de equipamentos
 * @param onSuccess Callback de sucesso
 * @param onFail Callback de falha
 * @param filter filtro de busca
 */
 export function fetchEquipamentos(onSuccess: any, onFail: any, filter?: any) {
    fetch('api/equipamentos/', onSuccess, onFail, filter);
}