import axiosInstance from "../../../services/AxiosConfig";

/**
 * Retorna os dados de uma requisição 
 * @param url url do endpoint
 * @param onSuccess Callback de sucesso
 * @param onFail Callback de erro
 */
 function fetch(url: string, onSuccess: any, onFail: any) {
    async function fetchData() {
        return await axiosInstance.get(url);
    }

    const result = fetchData();
    result.then( response => {
        onSuccess(response.data)
    }).catch( response => onFail(response));
}

/**
 * Carrega os colaboradores da Konica
 * @param onSuccess Callback de sucesso
 * @param onFail Callback de falha
 */
 export function fetchColaboradores(onSuccess: any, onFail: any) {
    fetch('api/colaboradores/', onSuccess, onFail);
}

/**
 * Criar um novo colaborador da Konica
 * @param colaborador dados do colaborador
 * @param onSuccess Callback de sucesso
 * @param onFail Callback de falha
 */
export function createColaboradores(colaborador: any, onSuccess: any, onFail: any) {
    async function create() {
        return await axiosInstance.post('api/colaboradores/', colaborador);
    }

    const result = create();
    result.then( response => {
        onSuccess(response.data)
    }).catch( response => onFail(response));
}

/**
 * Atualiza os dados de um colaborador da Konica
 * @param colaborador dados do colaborador
 * @param onSuccess Callback de sucesso
 * @param onFail Callback de falha
 */
 export function updateColaboradores(colaborador: any, onSuccess: any, onFail: any) {
    async function update() {
        return await axiosInstance.put(`api/colaboradores/${colaborador?.id}`, colaborador);
    }

    const result = update();
    result.then( response => {
        onSuccess(response.data)
    }).catch( response => onFail(response));
}