import React from 'react';
import { NumberFormatCustomProps } from '.';
import NumberFormat from 'react-number-format';

export default function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, monetary, name, ...other } = props;


  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      fixedDecimalScale
      decimalScale={2}
      decimalSeparator=","
      thousandSeparator="."
      isNumericString
      prefix={`${props.monetary ? 'R$ ' : ''}`}
    />
  );
}

export function NumberInputCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      fixedDecimalScale
      decimalScale={2}
      decimalSeparator=","
      thousandSeparator="."
      isNumericString
    />
  );
}
